import React from 'react'

import styled, { CSS, css } from 'styled-components'

import { GlobalModalTypeEnum, ModalsPlugin } from '@api/local/ModalsPlugin'
import { ResponsiveImage } from '@atoms/index'
import { StaticImage, Tooltip, TooltipContent } from '@client/components'
import { ResponsiveProperty, ResponsivePXValue } from '@components/Theme'
import { MealKitCategoryFragment, FrozenCategoryFragment, MarketProductCategoryFragment, MealKitDishListFragment, FrozenMealDishListFragment, MarketProductListFragment, WineListFragment } from '@hooks/api'
import { MealKitCostClass } from '@uctypes/api/globalTypes'

type CombinedProduct = FrozenMealDishListFragment | MealKitDishListFragment | WineListFragment | MarketProductListFragment

const Container = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  ${ResponsiveProperty('z-index', { mobile: '1', tablet: '11', desktop: '11' })}

  ${ResponsivePXValue('padding', { mobile: '12px', tablet: '14px', desktop: '16px' })}
`

const Large = css`
  ${ResponsivePXValue('height', '36px')}
  ${ResponsivePXValue('width', '36px')}
  ${ResponsivePXValue('min-width', '36px')}

  img {
    ${ResponsivePXValue('height', '24px')}
    ${ResponsivePXValue('width', '24px')}
  }
`

const Small = css`
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('width', '32px')}
  ${ResponsivePXValue('min-width', '32px')}

  img {
    ${ResponsivePXValue('height', '22px')}
    ${ResponsivePXValue('width', '22px')}
  }
`

const ImageContainer = styled.div<{ $size: string }>`
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  background-color: ${(props): string => props.theme.colors.whites.pureWhite};

 
  ${ResponsivePXValue('margin-bottom', '8px')}

  picture {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${(props): CSS => props.$size === 'large' ? Large : Small}
`

export interface ProductCategoriesProps {
  product: CombinedProduct
  className?: string
  iconSize?: 'small' | 'large'
  showCategories?: boolean
}

export function ProductCategories({ product, className, iconSize = 'small', showCategories = true }: ProductCategoriesProps): JSX.Element {

  const getTooltipContent = (title: string): JSX.Element => <TooltipContent text={title} />

  const categories = []

  const getCategories = (product: CombinedProduct) => {
    if (product?.__typename === 'MealKitDish') {
      return product?.mealKitCategories
    } else if (product?.__typename === 'FrozenMealDish') {
      return product?.frozenCategories
    } else if (product?.__typename === 'MarketProduct') {
      return product?.marketProductCategories
    }
  }

  const productCategories = getCategories(product) || []

  for (const productCategory of productCategories) {
    if (product?.__typename === 'MealKitDish') {
      if (
        (productCategory as MealKitCategoryFragment).costClass === MealKitCostClass.STANDARD ||
        (product as MealKitDishListFragment).costClass === (productCategory as MealKitCategoryFragment).costClass
      ) {
        categories.push(productCategory)
      }
    } else {
      categories.push(productCategory)
    }
  }

  let category: MealKitCategoryFragment | FrozenCategoryFragment | MarketProductCategoryFragment

  const handleContentClicked = (): void => {
    if (product?.__typename === 'MealKitDish') {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.KNOW_YOUR_DISHES)
    } else if (product?.__typename === 'FrozenMealDish') {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.KNOW_YOUR_CRAFT_MEALS)
    }
  }

  return (
    <Container className={`product-categories ${className}`}>
      <If condition={showCategories}>
        <For each='category' of={categories}>
          <Tooltip
            key={category.id}
            content={getTooltipContent(category?.title)}
            onClick={handleContentClicked}>
            <ImageContainer $size={iconSize}>
              <ResponsiveImage
                lazy={false}
                image={category?.icon}
              />
            </ImageContainer>
          </Tooltip>
        </For>
      </If>
      <If condition={(product?.__typename !== 'MarketProduct' && product?.__typename !== 'Wine') && product?.discoveryHealthyDiningItem}>
        <Tooltip
          content={getTooltipContent('HealthyDining')}>
          <ImageContainer $size={iconSize}>
            <StaticImage
              staticId='vitality-indicator-small'
              lazy={false}
              objectFit='contain'
              mobileWidth={768}
              tabletWidth={1024}
              desktopWidth={1600} />
          </ImageContainer>
        </Tooltip>
      </If>
    </Container>
  )
}
