import { CartPlugin } from '@api/local/CartPlugin'
import { CheckoutPlugin } from '@api/local/CheckoutPlugin'
import { ModalsPlugin } from '@api/local/ModalsPlugin'
import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { OrderPlugin } from '@api/local/OrderPlugin'
import { ProductPlugin } from '@api/local/ProductPlugin'
import { UserPlugin } from '@api/local/UserPlugin'
import { Config } from '@lib/Config'

import { AppPlugin } from './api/local/index'

export async function configure(config: Config): Promise<void> {

  const appPlugin = AppPlugin.shared()
  config.registerPlugin(appPlugin)

  const cartPlugin = CartPlugin.shared()
  config.registerPlugin(cartPlugin)

  const checkoutPlugin = CheckoutPlugin.shared()
  config.registerPlugin(checkoutPlugin)

  const modalsPlugin = ModalsPlugin.shared()
  config.registerPlugin(modalsPlugin)

  const navigationPlugin = NavigationPlugin.shared()
  config.registerPlugin(navigationPlugin)

  const userPlugin = UserPlugin.shared()
  config.registerPlugin(userPlugin)

  const productPlugin = new ProductPlugin()
  config.registerPlugin(productPlugin)

  const orderPlugin = new OrderPlugin()
  config.registerPlugin(orderPlugin)

  await config.configureApp()

}
