import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import { ModalsPlugin, GlobalModalTypeEnum, MODALS_DEFAULT_STATE } from '@api/local/ModalsPlugin'
import { ResponsivePXValue, SmallLoader, theme } from '@client/components'
import { Modal } from '@client/components/molecules'
import { SubscriptionCategory } from '@client/components/molecules/stores/SubscriptionCategory'
import { FrozenCategoryFragment, useGetAllFrozenCategoriesLazyQuery, useGetModalsQuery } from '@hooks/api'
import { FrozenCategoryStatusEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${ResponsivePXValue('height', '568px')}
  ${ResponsivePXValue('max-height', '568px')}
  ${ResponsivePXValue('width', { mobile: 'CALC(100vw - 16px)', tablet: 'CALC(100% - 200px)', desktop: '600px' })}

`
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('height', '382px')}
`

const LoaderBox = styled.div`
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('width', '32px')}
`

interface KnowYourCraftMealsModalState {
  categories: FrozenCategoryFragment[]
}

const DEFAULT_STATE: KnowYourCraftMealsModalState = {
  categories: [],
}

export function KnowYourCraftMealsModal(): JSX.Element {

  const [state, setState] = useState<KnowYourCraftMealsModalState>({ ...DEFAULT_STATE })
  const { data = { modals: { ...MODALS_DEFAULT_STATE } } } = useGetModalsQuery()
  const [getCategories, { data: frozenCategories, loading: frozenCategoriesLoading }] = useGetAllFrozenCategoriesLazyQuery({ variables: { filters: { status: [FrozenCategoryStatusEnum.ACTIVE] } } })

  const _handleClose = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.KNOW_YOUR_CRAFT_MEALS)
  }

  useEffect(() => {
    setTimeout(() => {
      getCategories()
    }, 300)
  }, [])

  useEffect(() => {
    if (frozenCategories) {
      setState(prevState => ({ ...prevState, categories: frozenCategories?.categories?.list as unknown as FrozenCategoryFragment[] }))
    }
  }, [frozenCategories])

  let category: FrozenCategoryFragment

  return (
    <Modal
      showCloseButton
      open={data?.modals?.knowYourCraftMeals}
      title='KNOW YOUR CRAFT MEALS'
      onClose={_handleClose}>
      <Container>
        <Choose>
          <When condition={frozenCategoriesLoading}>
            <LoadingContainer>
              <LoaderBox>
                <SmallLoader color={theme.colors.oranges.coral} />
              </LoaderBox>
            </LoadingContainer>
          </When>
          <Otherwise>
            <>
              <For each='category' of={state.categories || []}>
                <SubscriptionCategory
                  key={category.id}
                  icon={category.icon}
                  title={category.title}
                  subTitle={category.longDescription}
                  learnMoreUrl='/frozen/craft-meal/about' />
              </For>
            </>
          </Otherwise>
        </Choose>
      </Container>
    </Modal>
  )

}
