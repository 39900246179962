import React, { useEffect } from 'react'

import { Route, Routes as RouterRoutes, useLocation } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'

import { AppPlugin } from '@api/local'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import {
  AddLocationModal, CookWithinDaysModal, DeliveryDayUnavailableModal, DeliveryUnavailableModal, KnowYourCraftMealsModal,
  KnowYourDishesModal, LoginModal, MakePayment, NewsLetterModal, SignupModal, PageModal, PauseSubscriptionModal,
  PauseSubscriptionReasonModal, DefaultLayout, NotFoundPage,
} from '@client/components'
import { CheckoutController } from '@client/components/organisms/checkout'
import { CheckoutLayout } from '@client/components/templates/CheckoutLayout'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useProductStockLazyQuery, useSystemChangesSubscription, useUserCartQuery, useUserDetailsQuery } from '@hooks/api'
import { Home } from '@pages/Home'
import { SystemChangeTypeEnum, MenuStatusEnum, UserStatusEnum } from '@uctypes/api/globalTypes'

import { CheckoutAsync } from './CheckoutAsync'
import { CMSAsync } from './CMSAsync'
import { CraftDessertDetailsAsync } from './CraftDessertDetailsAsync'
import { CraftDessertStoreAsync } from './CraftDessertStoreAsync'
import { CraftKidsMealDetailsAsync } from './CraftKidsMealDetailsAsync'
import { CraftKidsStoreAsync } from './CraftKidsStoreAsync'
import { CraftMealDetailsAsync } from './CraftMealDetailsAsync'
import { CraftMealMyShopStoreAsync } from './CraftMealMyShopStoreAsync'
import { CraftMealStoreAsync } from './CraftMealStoreAsync'
import { CraftPizzaDetailsAsync } from './CraftPizzaDetailsAsync'
import { CraftPizzaStoreAsync } from './CraftPizzaStoreAsync'
import { FrozenAislesAsync } from './FrozenAislesAsync'
import { MarketAislesAsync } from './MarketAislesAsync'
import { MarketProductDetailsAsync } from './MarketProductDetailsAsync'
import { MarketProductStoreAsync } from './MarketProductStoreAsync'
import { MealKitDetailsAsync } from './MealKitDetailsAsync'
import { MealKitPlansAsync } from './MealKitPlansAsync'
import { MealKitStoreAsync } from './MealKitStoreAsync'
import { MeAsync } from './MeAsync'
import { PlaceHolder } from './Placeholder'
import { PrivacyPolicyAsync } from './PrivacyPolicyAsync'
import { ReferralPolicyAsync } from './ReferralPolicyAsync'
import { SuppliersAsync } from './SuppliersAsync'
import { SuppliersStoryAsync } from './SuppliersStoryAsync'
import { TermsAndConditionsAsync } from './TermsAndConditionsAsync'
import { VineyardStoreAsync } from './VineyardStoreAsync'
import { WineAislesAsync } from './WineAislesAsync'
import { WineDetailsAsync } from './WineDetailsAsync'
import { WineStoreAsync } from './WineStoreAsync'

export function Routes(): JSX.Element {

  const config = useConfig()
  const systemChangeOptions = {
    variables: {
      types: [SystemChangeTypeEnum.MENU, SystemChangeTypeEnum.STOCK],
    },
    skip: !config.isBrowser(),
  }

  const { data: systemChangesData } = useSystemChangesSubscription(systemChangeOptions)
  const [getProduct] = useProductStockLazyQuery({ fetchPolicy: 'network-only' })
  const { addToast } = useToasts()
  const { refetch: getCart } = useUserCartQuery({ skip: !config.isBrowser() })

  const location = useLocation()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  if (config.isBrowser()) {
    if (navigator.userAgent.indexOf('gonative') > -1) {
      const appflagGoNATIVE = true
      AppPlugin.shared().setIsNativeApplication(appflagGoNATIVE)
    }
  }

  // Change Socket handler
  useEffect(() => {
    const change = systemChangesData?.systemChanges
    if (change?.__typename === 'MenuSystemChange') {
      if (change.menuStatus === MenuStatusEnum.OPENED) {
        // addToast('A new menu is now available', { appearance: 'info', autoDismiss: true })
        getCart()
      } else if (change.menuStatus === MenuStatusEnum.CLOSING) {
        addToast('Sorry, this week\'s menu is now closed', { appearance: 'warning', autoDismiss: true })
        getCart()
      }
    } else if (change?.__typename === 'StockSystemChange') {
      getProduct({ variables: { id: change.product.id } })
    }
  }, [systemChangesData?.systemChanges])

  useEffect(() => {
    if (userDetailsData?.currentUser?.status === UserStatusEnum.GUEST) {
      ModalsPlugin.shared().toggleGlobalModal(location?.pathname?.split('/').includes('me'), GlobalModalTypeEnum.LOG_IN)
    }
  }, [location, userDetailsData])

  useEffect(() => {
    // set isAPP from GoNative here!
  }, [true])

  return (
    <>
      {/* Globals and Modals */}
      <CheckoutController />
      <LoginModal />
      <SignupModal />
      <AddLocationModal />
      <DeliveryUnavailableModal />
      <DeliveryDayUnavailableModal />
      <CookWithinDaysModal />
      <NewsLetterModal />
      <KnowYourDishesModal />
      <KnowYourCraftMealsModal />
      <PageModal />
      <PauseSubscriptionModal />
      <PauseSubscriptionReasonModal />

      <RouterRoutes>

        <Route element={<DefaultLayout />}>

          <Route element={<Home />} path='/' />

          <Route element={<PlaceHolder />} path='/food-fund' />
          <Route element={<TermsAndConditionsAsync />} path='/terms' />
          <Route element={<PlaceHolder />} path='/admarula' />
          <Route element={<PlaceHolder />} path='/how-it-works' />
          <Route element={<PrivacyPolicyAsync />} path='/privacy-policy' />
          <Route element={<ReferralPolicyAsync />} path='/referral-policy' />

          <Route path='/cms'>
            <Route path='' element={<Home />} />
            <Route path=':slug' element={<CMSAsync />} />
          </Route>

          <Route path='/meal-kit/'>
            <Route path='' element={<MealKitStoreAsync />} />
            <Route path=':slug' element={<MealKitDetailsAsync />} />
            <Route path='plans' element={<MealKitPlansAsync />} />
            <Route path='suppliers' element={<SuppliersAsync />} />
            <Route path='suppliers/:slug' element={<SuppliersStoryAsync />} />
          </Route>

          <Route path='/frozen'>
            <Route path='' element={<FrozenAislesAsync />} />
            <Route path='craft-meal'>
              <Route path='' element={<CraftMealStoreAsync />} />
              <Route path=':slug' element={<CraftMealDetailsAsync />} />
            </Route>
            <Route path='craft-pizza'>
              <Route path='' element={<CraftPizzaStoreAsync />} />
              <Route path=':slug' element={<CraftPizzaDetailsAsync />} />
            </Route>
            <Route path='craft-dessert'>
              <Route path='' element={<CraftDessertStoreAsync />} />
              <Route path=':slug' element={<CraftDessertDetailsAsync />} />
            </Route>
            <Route path='craft-kids'>
              <Route path='' element={<CraftKidsStoreAsync />} />
              <Route path=':slug' element={<CraftKidsMealDetailsAsync />} />
            </Route>
            <Route path='bought-before'>
              <Route path='' element={<CraftMealMyShopStoreAsync />} />
              <Route path=':slug' element={<CraftMealDetailsAsync />} />
            </Route>
          </Route>

          <Route path='/wine'>
            <Route path='' element={<WineAislesAsync />} />
            <Route path=':slug' element={<WineDetailsAsync />} />
            <Route path='store/:slug' element={<WineStoreAsync />} />
            <Route path='vineyards/:slug' element={<VineyardStoreAsync />} />
          </Route>

          <Route path='/market'>
            <Route path='' element={<MarketAislesAsync />} />
            <Route path=':slug' element={<MarketProductDetailsAsync />} />
            <Route path='store/:slug' element={<MarketProductStoreAsync />} />
            <Route path='/market/search/:search' element={<MarketProductStoreAsync />} />
          </Route>

          <Route element={<MeAsync />} path='/me/*' />
          <Route element={<Home />} path='/reset/:token' />
          <Route element={<MakePayment />} path='/payment-link/:token' />

          <Route element={<NotFoundPage />} path='*' />

        </Route>

        <Route element={<CheckoutLayout />}>
          <Route element={<CheckoutAsync />} path='/checkout' />
        </Route>

      </RouterRoutes>
    </>
  )

}
