import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Button, Spacer } from '@client/components/atoms'
import { Form, TextInput, useForm } from '@client/components/molecules'
import { ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { useGetAppQuery, useUserCartQuery, CartCostItemFragment, useRedeemCouponMutation, useRemoveCouponMutation } from '@hooks/api'

import { TagChip } from './TagChip'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${theme.colors.whites.alabaster};
`

const DiscountContainer = styled.div`
  ${ResponsivePXValue('padding', '16px')}
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .discount-text-input {
    ${ZeroSpace}
  }
`

const DiscountButtonContainer = styled.div`
  ${ResponsivePXValue('width', '80px')}
`

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  ${ResponsivePXValue('padding', '0px 16px')}

  .coupon-tag {
    ${ResponsivePXValue('margin-right', '8px')}
    ${ResponsivePXValue('margin-bottom', '8px')}
  }
`

interface CartDiscountFormData {
  code: string
}

interface CartDiscountState {
  loading: boolean
}

const DEFAULT_STATE: CartDiscountState = {
  loading: false,
}

export function CartDiscount(): JSX.Element {

  const config = useConfig()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [state, setState] = useState<CartDiscountState>({ ...DEFAULT_STATE })
  const [form] = useForm()
  const { data: cartData, loading: cartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [redeemCoupon] = useRedeemCouponMutation()
  const [removeCoupon] = useRemoveCouponMutation()
  const cart = cartData?.currentUser?.activeCart
  const { addToast } = useToasts()
  const discountCode = appData?.app?.urlDiscount

  let reduction: CartCostItemFragment

  const _handleAddDiscount = async (data: CartDiscountFormData) => {
    setState((prevState) => update(prevState, {
      loading: { $set: true },
    }))

    try {
      await redeemCoupon({
        variables: {
          coupon: data.code as string,
        },
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })
      setState((prevState) => update(prevState, {
        loading: { $set: false },
      }))
      form.setFieldsValue({ code: '' })
      addToast('Coupon code successfully applied!', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      setState((prevState) => update(prevState, {
        loading: { $set: false },
      }))
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }
  const _handleURLDiscountInjection = async (discountCode: string) => {
    setState((prevState) => update(prevState, {
      loading: { $set: true },
    }))

    try {
      await redeemCoupon({
        variables: {
          coupon: discountCode as string,
        },
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })
      setState((prevState) => update(prevState, {
        loading: { $set: false },
      }))
      form.setFieldsValue({ code: '' })
      addToast('Coupon code successfully applied!', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      setState((prevState) => update(prevState, {
        loading: { $set: false },
      }))
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const _handleRemoveDiscount = async (id: string) => {
    setState((prevState) => update(prevState, {
      loading: { $set: true },
    }))
    try {
      await removeCoupon({
        variables: {
          coupon: id,
        },
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })
      setState((prevState) => update(prevState, {
        loading: { $set: false },
      }))
    } catch (e) {
      setState((prevState) => update(prevState, {
        loading: { $set: false },
      }))
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }
  useEffect(() => {
    if (discountCode && discountCode?.length) {
      _handleURLDiscountInjection(discountCode)
    }
  }, [])

  return (
    <Form form={form} onFinish={_handleAddDiscount}>
      <Container>
        <DiscountContainer>
          <TextInput
            variant='text'
            label='discount'
            showLabel={false}
            placeholder='DISCOUNT CODE'
            name='code'
            className='discount-text-input'
            disabled={cartLoading || state.loading}
            rules={[{ required: true, message: 'Please input a discount code' }]} />
          <Spacer universal='8px' variant='horizontal' />
          <DiscountButtonContainer>
            <Button
              loading={state.loading}
              color='grey'
              title='ADD'
              fluid
              disabled={cartLoading || state.loading}
              onClick={() => form.submit()} />
          </DiscountButtonContainer>
        </DiscountContainer>
        <TagContainer>
          <For each='reduction' of={cart?.reductions || []}>
            <If condition={reduction.canBeRemoved}>
              <TagChip key={reduction.id} title={reduction.title} onRemove={() => _handleRemoveDiscount(reduction.id)} className='coupon-tag' />
            </If>
          </For>
        </TagContainer>
      </Container>
    </Form>
  )

}
