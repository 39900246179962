import React, { Fragment } from 'react'

import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { Image, IconEnum, SmallLoader, Paragraph, Button } from '@client/components/atoms'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { DeviceContainer } from '@client/components/utility'
import { SiteHelper } from '@client/lib/SiteHelper'
import { CartItemFragment, useRemoveItemFromCartMutation } from '@hooks/api'

import { TableCell, TableRow } from '../tables'

const ImageContainer = styled.a`
  cursor: pointer;
  ${ResponsivePXValue('width', { mobile: '48px', tablet: '88px', desktop: '88px' })}
  ${ResponsivePXValue('height', { mobile: '48px', tablet: '88px', desktop: '88px' })}
`

const LoaderContainer = styled.div`
  ${ResponsivePXValue('height', '20px')}
  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('padding', '3px')}
`

const MobileProductNameAndRemove = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
`

const MobileRemoveIcon = styled.div`
  ${ResponsivePXValue('width', '12px')}
  ${ResponsivePXValue('height', '12px')}
`

const ServingSize = styled.div`
  ${ResponsivePXValue('width', '186px')}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const AddOnTitle = styled.div`
  ${ResponsivePXValue('width', '186px')}
`

export interface OrderAddOnItemCurrentProps {
  addOnItem: CartItemFragment
  onNavigate: () => void
}

export function OrderAddOnItemCurrent({ addOnItem, onNavigate }: OrderAddOnItemCurrentProps): JSX.Element {
  const { addToast } = useToasts()
  const [removeItemFromCart, { loading }] = useRemoveItemFromCartMutation()

  const getAddOnServingSize = (addOnItem: CartItemFragment): string => {
    if (addOnItem.product.__typename === 'Wine') {
      return addOnItem.quantity === 0 ? 'Bottle' : 'Bottles'
    } else if (addOnItem.product.__typename === 'MarketProduct') {
      return ''
    } else {
      const name = addOnItem?.product?.name?.split(' - ')
      if (name?.length > 0) {
        let servingSize = name[1]?.replace('one', '1')
        servingSize = servingSize?.replace('two', '2')
        servingSize = servingSize?.replace('four', '4')
        return servingSize
      }
    }
  }

  const _handleAddOnItemRemove = async () => {

    try {
      await removeItemFromCart({
        variables: {
          productId: addOnItem.product.id,
        },
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  return (
    <Fragment>
      <DeviceContainer $desktop>
        <TableRow margin='12px 0'>
          <TableCell padding='0 16px 0 0' justify='center'>
            <ImageContainer onClick={onNavigate}>
              <Image width={88} height={88} round={true} lazy={false} src={addOnItem?.product?.coverImage?.desktop} />
            </ImageContainer>
          </TableCell>
          <TableCell grow='1' align='center'>
            <AddOnTitle>
              <Paragraph variant='p2' className='order-item-title' bold>{addOnItem?.product?.name?.split(' - ')[0]}</Paragraph>
            </AddOnTitle>
          </TableCell>
          <TableCell align='center' padding='0 48px 0 0'>
            <Paragraph variant='p3'>
              <ServingSize>
                {getAddOnServingSize(addOnItem)}
              </ServingSize>
            </Paragraph>
          </TableCell>
          <TableCell align='center' grow='1' padding='0 48px 0 0'>
            <Paragraph variant='p3'>
              Qty {addOnItem.quantity}
            </Paragraph>
          </TableCell>
          <TableCell align='center' padding='0 48px 0 0'>
            <Paragraph variant='p3'>
              R{addOnItem?.product?.price}.00
            </Paragraph>
          </TableCell>
          <TableCell align='center'>
            <Choose>
              <When condition={loading}>
                <LoaderContainer>
                  <SmallLoader />
                </LoaderContainer>
              </When>
              <Otherwise>
                <Button
                  color='transparent'
                  icon={IconEnum.CLOSE_OUTLINE}
                  iconColor={theme.colors.greys.darkCodGrey}
                  size='small'
                  onClick={_handleAddOnItemRemove} />
              </Otherwise>
            </Choose>
          </TableCell>
        </TableRow>
      </DeviceContainer>
      <DeviceContainer $mobile $tablet>
        <TableRow margin='12px 0'>
          <TableCell padding='0 8px 0 0'>
            <ImageContainer onClick={onNavigate}>
              <Image round={true} lazy={false} src={addOnItem?.product?.coverImage?.desktop} />
            </ImageContainer>
          </TableCell>
          <TableCell direction='column' grow='1' justify='center'>
            <MobileProductNameAndRemove>
              <Paragraph variant='p2' bold className='order-item-title' onClick={onNavigate}>{addOnItem?.product?.name?.split(' - ')[0]}</Paragraph>
              <MobileRemoveIcon onClick={_handleAddOnItemRemove}>
                <Choose>
                  <When condition={loading}>
                    <SmallLoader color={theme.colors.greys.darkCodGrey} />
                  </When>
                  <Otherwise>
                    <Button
                      color='transparent'
                      icon={IconEnum.CLOSE_OUTLINE}
                      iconColor={theme.colors.greys.darkCodGrey}
                      size='small' />
                  </Otherwise>
                </Choose>
              </MobileRemoveIcon>
            </MobileProductNameAndRemove>
            <Paragraph variant='p3'>
              {getAddOnServingSize(addOnItem)}
            </Paragraph>
            <Paragraph variant='p3'>
              {addOnItem.quantity}
            </Paragraph>
            <Paragraph variant='p2'>
              R{addOnItem?.product?.price * addOnItem.quantity}.00
            </Paragraph>
          </TableCell>
        </TableRow>
      </DeviceContainer>
    </Fragment>
  )

}
