import React from 'react'

import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { Paragraph, Button } from '@atoms/index'
import { ResponsiveProperty, ResponsivePXValue, ZeroSpace } from '@components/Theme'
import { useSubsctibeToAcListMutation } from '@hooks/api'
import { Form, TextInput, useForm } from '@molecules/index'

const NewsletterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', { mobile: '8px', tablet: '0', desktop: '0' })}
`

const InputRow = styled.div`
  display: flex;
  ${ResponsivePXValue('margin-top', { mobile: '16px', tablet: '0', desktop: '0' })}
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  align-items: flex-start;
  justify-content: flex-start;

  .sign-up-button {
    ${ResponsivePXValue('width', { mobile: 'CALC(100vw - 16px)', tablet: '128px', desktop: '128px' })}
  }
`

const TextContainer = styled.div`
  ${ResponsivePXValue('height', '40px')}
`

const TextInputContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: 'CALC(100vw - 16px)', tablet: '378px', desktop: '378px' })}
  ${ResponsivePXValue('margin-right', { mobile: '0', tablet: '16px', desktop: '16px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '0', desktop: '0' })}
  ${ResponsivePXValue('margin-top', { mobile: '16px', tablet: '0', desktop: '0' })}

  .email-input {
    ${ZeroSpace} 
  }
`

interface SubscribeFormData {
  email: string
}

export function PageDataMeetWeaningSenseEmail(): JSX.Element {

  const [addLead, { loading }] = useSubsctibeToAcListMutation()
  const toasts = useToasts()
  const [form] = useForm()

  const _handleSubscribe = async (data: SubscribeFormData): Promise<void> => {
    try {
      await addLead({
        variables: {
          input: {
            emailAddress: data.email,
            listId: '159',
          },
        },
      })
      form.setFieldsValue({ email: '' })
      toasts.addToast('You have successfully subscribed to the Weaning Sense newsletter!', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      form.setFieldsValue({ email: '' })
      toasts.addToast('An error has occurred, please try again later', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  return (
    <Form form={form} onFinish={_handleSubscribe} loading={loading}>
      <NewsletterContainer>
        <TextContainer>
          <Paragraph bold variant='p2' align='center'>BE THE FIRST TO RECEIVE FRESH WEEKLY CONTENT AND PUT THE EASE INTO PARENTING</Paragraph>
        </TextContainer>
        <InputRow>
          <TextInputContainer>
            <TextInput
              variant='email'
              name='email'
              label='Email address'
              showLabel={false}
              placeholder='Enter your email'
              rules={[{ required: true, message: 'Please input your email address' }]}
              className='email-input' />
          </TextInputContainer>
          <Button
            title='Join the list'
            color='orange'
            className='sign-up-button'
            onClick={() => form.submit()} />
        </InputRow>
      </NewsletterContainer>
    </Form>
  )

}
