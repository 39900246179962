/* eslint-disable camelcase */
import { TagManager, CheckoutInterface, PaymentInfoInterface, ShippingInfoInterface, PurchaseInterface, RemoveFromCartInterface, AddToCartEventInterface, ViewItemInterface, SelectItemInterface, ItemListInterface, ItemsInterface, UserIdInterface, GenerateLeadInterface } from 'sih-gtm/build/TagManager'

import { CartItemFragment } from './api'

interface ReturnType {
  logCheckoutStep: (step: string | number, option: string, products: CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string) => void
  createCartProducts: (products: readonly CartItemFragment[], userId: string) => ItemsInterface[]
  logPurchase: (step: string | number, option: string, products: readonly CartItemFragment[], id: string, revenue: number, discountTitles: string, discountValue: number, userId: string) => void
  logRemoveFromCart: (products: ItemsInterface, userId?: string) => void
  logAddToCart: (products: ItemsInterface, userId?: string) => void
  logProductDetailImpression: (list: string, data: ItemsInterface, userId: string) => void
  logMultipleProductDetailImpression: (list: string, data: ItemsInterface[], userId: string) => void
  logProductListImpression: (products: ItemsInterface[], userId: string) => void
  logProductDetailSelection: (list: string, data: ItemsInterface, userId: string) => void
  logMultipleProductDetailSelection: (list: string, data: ItemsInterface[], userId: string) => void
  logPaymentInfoStep: (step: string | number, option: string, products: readonly CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string) => void
  logShippingInfoStep: (step: string | number, option: string, products: readonly CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string) => void
  logUserId: (userId: string) => void
  logLeadGenerate: (userId: string) => void
}

export function useAnalytics(): ReturnType {

  const logCheckoutStep = (step: string | number, option: string, products: readonly CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string): void => {

    const gaProducts = createCartProducts(products)

    const eventData: CheckoutInterface = {
      event: 'begin_checkout',
      eventParams: {
        currency: 'ZAR',
        value,
        coupon: discountTitles,
        items: gaProducts,
      },
      customEventParams: {
        option,
        totalDiscountAmount: discountValue,
        user_id: userId,
      },
    }
    TagManager.pushEvent(eventData)
  }

  const logUserId = (userId: string): void => {

    const eventData: UserIdInterface = {
      event: 'user_id_insert',
      eventParams: {
        user_id: userId,
      },
      customEventParams: {
        user_id: userId,
      },
    }
    TagManager.pushEvent(eventData)
  }

  const logPaymentInfoStep = (step: string | number, option: string, products: readonly CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string): void => {

    const gaProducts = createCartProducts(products)

    const eventData: PaymentInfoInterface = {
      event: 'add_payment_info',
      eventParams: {
        currency: 'ZAR',
        value,
        coupon: discountTitles,
        // shipping_tier: 'next day, air,ground'
        items: gaProducts,
      },
      customEventParams: {
        totalDiscountAmount: discountValue,
        user_id: userId,
      },
    }
    TagManager.pushEvent(eventData)
  }

  const logShippingInfoStep = (step: string | number, option: string, products: readonly CartItemFragment[], value: number, discountTitles: string, discountValue: number, userId: string): void => {

    const gaProducts = createCartProducts(products)

    const eventData: ShippingInfoInterface = {
      event: 'add_shipping_info',
      eventParams: {
        currency: 'ZAR',
        value,
        coupon: discountTitles,
        // shipping_tier: 'next day, air,ground'
        items: gaProducts,
      },
      customEventParams: {
        totalDiscountAmount: discountValue,
        user_id: userId,
      },
    }
    TagManager.pushEvent(eventData)

  }

  const createCartProducts = (cartItems: readonly CartItemFragment[]): ItemsInterface[] => {
    const logProducts = [] as ItemsInterface[]

    for (const i in cartItems) {
      const cartItem = cartItems[i]

      switch (cartItem.product.__typename) {

        case 'MealKit':
          logProducts.push({
            item_name: cartItem?.product?.name,
            item_id: cartItem?.product?.id,
            price: cartItem?.price,
            item_brand: 'UCOOK',
            item_category: cartItem?.product?.mealKitCategories?.map((cat: any) => cat.id).join(', '),
            item_variant: cartItem?.product?.mealKitCategories?.map((cat: any) => cat.title).join(', '),
            item_list_name: 'Meal Kit',
            quantity: cartItem?.quantity,
          })
          break

        case 'FrozenMeal':
          logProducts.push({
            item_name: cartItem?.product?.name,
            item_id: cartItem?.product?.id,
            price: cartItem?.product?.price,
            item_brand: 'UCOOK',
            item_category: cartItem?.product?.frozenCategories?.map((cat: any) => cat.id).join(', '),
            item_variant: cartItem?.product?.frozenCategories?.map((cat: any) => cat.title)?.join(', '),
            item_list_name: 'Craft Meals',
            quantity: cartItem?.quantity,
          })
          break

        case 'Wine':
          logProducts.push({
            item_name: cartItem?.product?.name,
            item_id: cartItem?.product?.id,
            price: cartItem?.product?.price,
            item_brand: cartItem?.product?.vineyard?.name,
            item_category: cartItem?.product?.wineCategory?.id,
            item_variant: cartItem?.product?.wineCategory?.title,
            item_list_name: 'Wines',
            quantity: cartItem?.quantity,
          })
          break
        case 'MarketProduct':
          logProducts.push({
            item_name: cartItem?.product?.name,
            item_id: cartItem?.product?.id,
            price: cartItem?.product?.price,
            item_brand: cartItem?.product?.brand?.name,
            item_category: cartItem?.product?.marketProductCategories[0]?.id,
            item_variant: cartItem?.product?.marketProductCategories[0]?.title,
            item_list_name: 'MarketProduct',
            quantity: cartItem?.quantity,
          })
          break
      }
    }

    return logProducts
  }
  //  soon to be killed

  const logPurchase = (step: string | number, option: string, products: readonly CartItemFragment[], id: string, revenue: number, discountTitles: string, discountValue: number, userId: string): void => {

    const gaProducts = createCartProducts(products)

    const eventData: PurchaseInterface = {
      event: 'purchase',
      eventParams: {
        currency: 'ZAR',
        transaction_id: id,
        value: revenue,
        coupon: discountTitles,
        items: gaProducts,
      },
      customEventParams: {
        option,
        totalDiscountValue: discountValue,
        user_id: userId,
      },
    }
    TagManager.pushEvent(eventData)

  }

  const logLeadGenerate = (userId: string): void => {
    const eventData: GenerateLeadInterface = {
      event: 'generate_lead',
      eventParams: {
        currency: 'ZAR',
        value: 1,
      },
      customEventParams: {
        user_id: userId,
      },
    }
    TagManager.pushEvent(eventData)
  }

  const logRemoveFromCart = (products: ItemsInterface, userId?: string): void => {
    const eventData: RemoveFromCartInterface = {
      event: 'remove_from_cart',
      eventParams: {
        currency: 'ZAR',
        value: products.price,
        items: [products],
      },
      customEventParams: {
        user_id: userId,
      },
    }
    TagManager.pushEvent(eventData)
  }
  // add userid but soon to be killed

  const logAddToCart = (products: ItemsInterface, userId?: string): void => {

    const eventData: AddToCartEventInterface = {
      event: 'add_to_cart',
      eventParams: {
        currency: 'ZAR',
        value: products.price,
        items: [products],
      },
      customEventParams: {
        user_id: userId,
      },
    }

    TagManager.pushEvent(eventData)
  }
  // add userid

  const logMultipleProductDetailImpression = (list: string, data: ItemsInterface[], userId: string): void => {
    const topThree = []
    const topThreeIds: string[] = []
    for (let i = 0; i < 3; i++) {
      if (data[i] !== undefined) {
        topThree.push(data[i])
        topThreeIds.push(data[i].item_id)
      }
    }
    const eventData: ViewItemInterface = {
      event: 'view_item',
      eventParams: {
        currency: 'ZAR',
        value: data[0].price,
        items: topThree,
      },
      customEventParams: {
        currentProductIds: topThreeIds,
        user_id: userId,
      },
    }
    TagManager.pushEvent(eventData)
  }
  // add userid

  const logMultipleProductDetailSelection = (list: string, data: ItemsInterface[], userId: string): void => {
    const topThree = []
    const topThreeIds: string[] = []
    for (let i = 0; i < 3; i++) {
      if (data[i] !== undefined) {
        topThree.push(data[i])
        topThreeIds.push(data[i].item_id)
      }
    }

    const eventData: SelectItemInterface = {
      eventParams: {
        item_list_name: list,
        items: topThree,
      },
      customEventParams: {
        currentProductIds: topThreeIds,
        user_id: userId,
      },
      event: 'select_item',
    }
    TagManager.pushEvent(eventData)
  }
  // add userid

  const logProductDetailImpression = (list: string, data: ItemsInterface, userId: string): void => {
    const eventData: ViewItemInterface = {
      event: 'view_item',
      eventParams: {
        currency: 'ZAR',
        value: data.price,
        items: [data],
      },
      customEventParams: {
        currentProductId: data.item_id,
        user_id: userId,
      },
    }
    TagManager.pushEvent(eventData)
  }
  // add userid

  const logProductDetailSelection = (list: string, data: ItemsInterface, userId: string): void => {

    const eventData: SelectItemInterface = {
      event: 'select_item',
      eventParams: {
        item_list_name: list,
        items: [data],
      },
      customEventParams: {
        currentProductId: data.item_id,
        user_id: userId,
      },
    }
    TagManager.pushEvent(eventData)
  }

  const logProductListImpression = (data: ItemsInterface[], userId: string): void => {
    const productList: ItemsInterface[] = []

    if (!data) {
      return
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i] !== undefined) {
        productList.push({
          item_name: data[i].item_name,
          item_id: data[i].item_id,
          item_category: data[i].item_category,
          price: data[i].price,
          item_brand: data[i].item_brand,
          item_variant: data[i].item_variant,
          item_list_name: data[i].item_list_name,
          index: data[i].index,
        })
      }
    }

    const eventData: ItemListInterface = {
      event: 'view_item_list',
      eventParams: {
        item_list_name: data[0]?.item_list_name,
        items: productList,
      },
      customEventParams: {
        user_id: userId,
      },
    }
    TagManager.pushEvent(eventData)
  }

  return {
    logCheckoutStep,
    createCartProducts,
    logPurchase,
    logRemoveFromCart,
    logAddToCart,
    logProductDetailImpression,
    logMultipleProductDetailImpression,
    logProductListImpression,
    logProductDetailSelection,
    logMultipleProductDetailSelection,
    logPaymentInfoStep,
    logShippingInfoStep,
    logUserId,
    logLeadGenerate,
  }

}
