import {
  ApolloClient,
  ApolloQueryResult,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloLink,
  FetchResult,
  split,
  createHttpLink,
  Observable,
  gql,
  TypePolicies,
  FieldPolicy,
  DocumentNode,
  ServerError,
  HttpLink,
} from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { RetryLink } from '@apollo/client/link/retry'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'

import { Observable as ErrorObservable } from 'apollo-link'
import { ErrorResponse, onError } from 'apollo-link-error'
import { getMainDefinition } from 'apollo-utilities'
import { sha256 } from 'crypto-hash'
import { GraphQLError } from 'graphql'
import { createClient } from 'graphql-ws'
import { ParseError } from 'libphonenumber-js'

export interface FCMConfig {
  apiKey: string
  projectId: string
  senderId: string
  appId: string
  measurementId: string
  vapidKey: string
}
export interface InterfaceTypes {
  kind: string
  name: string
  possibleTypes: {
    name: string
  }[]
}

declare const window: Window
export abstract class ConfigPlugin {

  abstract typePolicies?(): TypePolicies
  abstract fieldPolicies?(): { [k: string]: FieldPolicy }
  abstract types?(): DocumentNode
  abstract fragments?(): DocumentNode
  abstract queries?(): DocumentNode
  abstract extensions?(): DocumentNode
  abstract headers?(): { [k: string]: string }
  abstract configure?(config: Config): Promise<void>
  abstract handleError?(error: GraphQLError | null, networError: ServerError | ParseError | null, handler: ErrorResponse, config: Config): ErrorObservable<FetchResult> | void
  abstract possibleTypes?(): { [k: string]: string[] }

}

// const starFleet = theFinalFrontier.find(/NCC1701*?/gi)
export class Config {

  token: string | null = null
  serverClient: ApolloClient<NormalizedCacheObject> | null = null
  browserClient: ApolloClient<NormalizedCacheObject> | null = null
  possibleTypes?: { [k: string]: string[] }
  plugins: ConfigPlugin[] = []

  registerPlugin(plugin: ConfigPlugin): void {
    this.plugins.push(plugin)
  }

  async wait(amount: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve()
      }, amount)
    })
  }

  ssr(): boolean {
    if (this.isBrowser()) {
      return window.isSSRResponse
    }
    return false
  }

  ssrUserLoad(loading: boolean): boolean {
    if (this.isBrowser()) {
      return loading
    }
    return false
  }

  defaultImage(): string {
    return '/images/logo/logo_round.svg'
  }

  isBrowser(): boolean {
    return (typeof window !== 'undefined')
  }

  isProductionDomain(): boolean {
    return this.isBrowser() && window.location.hostname === 'www.ucook.co.za'
  }

  fetchSSRQuery(): boolean {
    return true
  }

  getPluginHeaders(): { [k: string]: string } {
    let headers = {
      Accept: 'multipart/mixed; deferSpec=20220824, application/json',
    }
    for (let p = 0; p < this.plugins.length; p++) {
      if (this.plugins[p].headers) {
        headers = {
          ...headers,
          ...this.plugins[p].headers(),
        }
      }
    }
    return headers
  }

  reset(): void {
    this.serverClient = null
    this.browserClient = null
  }

  typePolicies(): TypePolicies {
    let typePolicies: TypePolicies = {}
    for (let p = 0; p < this.plugins.length; p++) {
      if (this.plugins[p].typePolicies) {
        typePolicies = {
          ...typePolicies,
          ...this.plugins[p].typePolicies(),
        }
      }
    }
    return {
      ...typePolicies,
      Query: {
        fields: {
          ...this.fieldPolicies(),
        },
      },
    }
  }

  fieldPolicies(): { [k: string]: FieldPolicy } {
    let fieldPolicies: { [k: string]: FieldPolicy } = {}
    for (let p = 0; p < this.plugins.length; p++) {
      if (this.plugins[p].fieldPolicies) {
        fieldPolicies = {
          ...fieldPolicies,
          ...this.plugins[p].fieldPolicies(),
        }
      }
    }
    return {
      ...fieldPolicies,
    }
  }

  async getInterfaceTypes(): Promise<{ [k: string]: string[] }> {
    if (this.possibleTypes) {
      return this.possibleTypes
    }
    if (this.isBrowser() && window.possibleTypes) {
      this.possibleTypes = window.possibleTypes
      return this.possibleTypes
    }
    const qry = gql`
        query GetInterfaceConfig {
          types: getInterfaceTypes {
            kind
            name
            possibleTypes {
              name
            }
          }
        }
      `
    const possibleTypes: { [k: string]: string[] } = {}
    try {
      const client = this.getBasicClient()
      const result: ApolloQueryResult<{ types: InterfaceTypes[] }> = await client.query({
        query: qry,
      })
      result.data.types.forEach((type) => {
        possibleTypes[type.name] = type.possibleTypes.map((possibleType) => possibleType.name)
      })
      let pluginPossibleTypes: { [k: string]: string[] } = {}
      this.plugins.forEach((plugin) => {
        const pluginTypes = plugin.possibleTypes?.()
        if (pluginTypes) {
          pluginPossibleTypes = {
            ...pluginPossibleTypes,
            ...pluginTypes,
          }
        }
      })
      this.possibleTypes = { ...possibleTypes, ...pluginPossibleTypes }
    } catch (e) {
      console.log(e)
    }
    return possibleTypes
  }

  async getClient(): Promise<ApolloClient<NormalizedCacheObject>> {
    if (!this.possibleTypes) {
      if (this.isBrowser() && window.possibleTypes) {
        let pluginPossibleTypes: { [k: string]: string[] } = {}
        this.plugins.forEach((plugin) => {
          const pluginTypes = plugin.possibleTypes?.()
          if (pluginTypes) {
            pluginPossibleTypes = {
              ...pluginPossibleTypes,
              ...pluginTypes,
            }
          }
        })
        this.possibleTypes = { ...window.possibleTypes, ...pluginPossibleTypes }
      } else {
        await this.getInterfaceTypes()
      }
    }
    if (this.isBrowser()) {
      return this.getBrowserClient(this.possibleTypes)
    }
    return this.getServerClient(this.possibleTypes)
  }

  getBrowserCache(possibleTypes: { [k: string]: string[] }): InMemoryCache {
    return new InMemoryCache({
      possibleTypes,
      typePolicies: this.typePolicies(),
    })
  }

  async getBrowserLinks(): Promise<ApolloLink> {
    const errorLink = onError((handler): ErrorObservable<FetchResult> | void => {
      const { graphQLErrors, networkError } = handler
      const plugins = this.plugins.filter((plugin) => plugin.handleError)
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          for (let p = 0; p < plugins.length; p++) {
            const response = plugins[p].handleError?.(err, null, handler, this)
            if (response) {
              return response
            }
          }
        }
      }

      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          ),
        )
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
        for (let p = 0; p < plugins.length; p++) {
          const response = plugins[p].handleError?.(null, networkError, handler, this)
          if (response) {
            return response
          }
        }
      }
    })

    const middlewareLink = new ApolloLink((operation, forward): Observable<FetchResult> => {
      operation.setContext({
        headers: {
          ...operation.getContext().headers,
          ...this.getPluginHeaders(),
        },
      })
      return forward(operation)
    })

    const retryLink = new RetryLink({
      attempts: {
        max: 10,
        retryIf: (error): boolean => error.message === 'Failed to fetch',
      },
      delay: {
        initial: 500,
        max: 30000,
        jitter: true,
      },
    })

    const httpLink = new HttpLink({
      fetch,
      uri: this.getApi(),
    })

    const wsLink = new GraphQLWsLink(createClient({
      url: this.getApi(true),
      connectionParams: {
        ...this.getPluginHeaders(),
      },
    }))

    const transportLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      httpLink,
    )

    if (process.env.NODE_ENV === 'production') {
      const persistentLink = createPersistedQueryLink({ sha256 })
      const link = middlewareLink
        .concat(persistentLink)
        .concat(retryLink)
        .concat(ApolloLink.from([errorLink as unknown as ApolloLink]))
        .concat(transportLink)
      return link
    }

    const link = middlewareLink
      .concat(retryLink)
      .concat(ApolloLink.from([errorLink as unknown as ApolloLink]))
      .concat(transportLink)

    return link
  }

  async getBrowserClient(possibleTypes: { [k: string]: string[] }): Promise<ApolloClient<NormalizedCacheObject>> {
    if (!this.browserClient) {

      const cache = new InMemoryCache({
        possibleTypes,
        typePolicies: this.typePolicies(),
      })

      if (this.ssr()) {
        cache.restore(window.__APOLLO_STATE__)
      }

      const link = await this.getBrowserLinks()

      this.browserClient = new ApolloClient({
        link,
        cache,
        connectToDevTools: true,
        ssrMode: this.ssr(),
      })
    }

    return this.browserClient
  }

  async configureApp(): Promise<void> {
    await this.getInterfaceTypes()
    for (let p = 0; p < this.plugins.length; p++) {
      await this.plugins[p].configure?.(this)
    }
  }

  getServerClient(possibleTypes: { [k: string]: string[] }): ApolloClient<NormalizedCacheObject> {

    const httpLink = createHttpLink({
      // eslint-disable-next-line
      // @ts-ignore
      fetch,
      uri: this.getApi(),
    })

    const middlewareLink = new ApolloLink((operation, forward): Observable<FetchResult> => {
      operation.setContext({
        headers: {
          ...operation.getContext().headers,
          ...this.getPluginHeaders(),
        },
      })
      return forward(operation)
    })

    const retryLink = new RetryLink({
      attempts: {
        max: 10,
        retryIf: (error): boolean => error.message === 'Failed to fetch',
      },
      delay: {
        initial: 500,
        max: 30000,
        jitter: true,
      },
    })

    const link = middlewareLink.concat(retryLink).concat(httpLink)

    return new ApolloClient({
      link,
      cache: new InMemoryCache({
        possibleTypes,
        typePolicies: this.typePolicies(),
      }),
      ssrMode: this.ssr(),
    })
  }

  getBasicClient(token?: string): ApolloClient<NormalizedCacheObject> {
    console.log(`USING: ${this.getApi()} FOR SERVER SIDE REQUEST`)
    const httpLink = createHttpLink({
      // eslint-disable-next-line
      // @ts-ignore
      fetch,
      uri: this.getApi(),
    })
    let headers = {
      ...this.getPluginHeaders(),
    }
    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
      }
    }
    const middlewareLink = new ApolloLink((operation, forward): Observable<FetchResult> => {
      operation.setContext({
        headers: {
          ...operation.getContext().headers,
          ...headers,
        },
      })
      return forward(operation)
    })

    const retryLink = new RetryLink({
      attempts: {
        max: 10,
        retryIf: (error): boolean => error.message === 'Failed to fetch',
      },
      delay: {
        initial: 500,
        max: 30000,
        jitter: true,
      },
    })

    const link = middlewareLink.concat(retryLink).concat(httpLink)

    return new ApolloClient({
      link,
      cache: new InMemoryCache({
        typePolicies: this.typePolicies(),
      }),
      ssrMode: true,
    })
  }

  getENV(): string {
    if (this.isBrowser()) {
      return window.env.ENVIRONMENT
    } else {
      return process.env.NODE_ENV
    }
  }

  getApi(socket = false): string {
    if (this.isBrowser()) {
      if (!window.env.API_PORT) {
        return `${socket ? window.env.API_PROTOCOL === 'https' ? 'wss' : 'ws' : window.env.API_PROTOCOL}://${window.env.API_DOMAIN}/${window.env.API_ENDPOINT}`
      }
      return `${socket ? window.env.API_PROTOCOL === 'https' ? 'wss' : 'ws' : window.env.API_PROTOCOL}://${window.env.API_DOMAIN}:${window.env.API_PORT}/${window.env.API_ENDPOINT}`
    } else {
      if (process.env.API_SERVER_PROTOCOL && process.env.API_SERVER_DOMAIN && process.env.API_SERVER_ENDPOINT) {
        if (!process.env.API_SERVER_PORT) {
          return `${socket ? process.env.API_SERVER_PROTOCOL === 'https' ? 'wss' : 'ws' : process.env.API_SERVER_PROTOCOL}://${process.env.API_SERVER_DOMAIN}/${process.env.API_SERVER_ENDPOINT}`
        }
        return `${socket ? process.env.API_SERVER_PROTOCOL === 'https' ? 'wss' : 'ws' : process.env.API_SERVER_PROTOCOL}://${process.env.API_SERVER_DOMAIN}:${process.env.API_SERVER_PORT}/${process.env.API_SERVER_ENDPOINT}`
      }
      if (!process.env.API_PORT) {
        return `${socket ? process.env.API_PROTOCOL === 'https' ? 'wss' : 'ws' : process.env.API_PROTOCOL}://${process.env.API_DOMAIN}/${process.env.API_ENDPOINT}`
      }
      return `${socket ? process.env.API_PROTOCOL === 'https' ? 'wss' : 'ws' : process.env.API_PROTOCOL}://${process.env.API_DOMAIN}:${process.env.API_PORT}/${process.env.API_ENDPOINT}`
    }
  }

  getApiBaseUrl(socket = false): string {
    if (this.isBrowser()) {
      if (!window.env.API_PORT) {
        return `${socket ? window.env.API_PROTOCOL === 'https' ? 'wss' : 'ws' : window.env.API_PROTOCOL}://${window.env.API_DOMAIN}`
      }
      return `${socket ? window.env.API_PROTOCOL === 'https' ? 'wss' : 'ws' : window.env.API_PROTOCOL}://${window.env.API_DOMAIN}:${window.env.API_PORT}`
    } else {
      if (process.env.API_SERVER_PROTOCOL && process.env.API_SERVER_DOMAIN) {
        if (!process.env.API_SERVER_PORT) {
          return `${socket ? process.env.API_SERVER_PROTOCOL === 'https' ? 'wss' : 'ws' : process.env.API_SERVER_PROTOCOL}://${process.env.API_SERVER_DOMAIN}`
        }
        return `${socket ? process.env.API_SERVER_PROTOCOL === 'https' ? 'wss' : 'ws' : process.env.API_SERVER_PROTOCOL}://${process.env.API_SERVER_DOMAIN}:${process.env.API_SERVER_PORT}`
      }
      if (!process.env.API_PORT) {
        return `${socket ? process.env.API_PROTOCOL === 'https' ? 'wss' : 'ws' : process.env.API_PROTOCOL}://${process.env.API_DOMAIN}`
      }
      return `${socket ? process.env.API_PROTOCOL === 'https' ? 'wss' : 'ws' : process.env.API_PROTOCOL}://${process.env.API_DOMAIN}:${process.env.API_PORT}`
    }
  }

  getBaseUrl(excludePort = false): string {
    if (this.isBrowser()) {
      if (!window.env.PORT || excludePort) {
        return `${window.env.PROTOCOL}://${window.env.DOMAIN}`
      }
      return `${window.env.PROTOCOL}://${window.env.DOMAIN}:${window.env.PORT}`
    } else {
      if (!process.env.API_PORT || excludePort) {
        return `${process.env.PROTOCOL}://${process.env.DOMAIN}`
      }
      return `${process.env.PROTOCOL}://${process.env.DOMAIN}:${process.env.PORT}`
    }
  }

  getFacebook(): { appId: string } {
    if (this.isBrowser()) {
      return {
        appId: window.env.FACEBOOK_APP_ID,
      }
    }
    return {
      appId: process.env.FACEBOOK_APP_ID,
    }
  }

  getGoogle(): { clientId: string, apiKey: string } {
    if (this.isBrowser()) {
      return {
        clientId: window.env.GOOGLE_CLIENT_ID,
        apiKey: window.env.GOOGLE_API_KEY,
      }
    }
    return {
      clientId: process.env.GOOGLE_CLIENT_ID,
      apiKey: process.env.GOOGLE_API_KEY,
    }
  }

  getGM(): string {
    if (this.isBrowser()) {
      return window.env.GOOGLE_API_KEY
    }
    return process.env.GOOGLE_API_KEY
  }

  getGA(): string {
    if (this.isBrowser()) {
      return window.env.GA_KEY
    }
    return process.env.GA_KEY
  }

  getFBPixel(): string {
    if (this.isBrowser()) {
      return window.env.FB_PIXEL_KEY
    }
    return process.env.FB_PIXEL_KEY
  }

  getFCM(): FCMConfig {
    if (this.isBrowser()) {
      return {
        apiKey: window.env.FCM_API_KEY,
        projectId: window.env.FCM_PROJECT_ID,
        senderId: window.env.FCM_SENDER_ID,
        appId: window.env.FCM_APP_ID,
        measurementId: window.env.FCM_MEASUREMENT_ID,
        vapidKey: window.env.FCM_VAPID_KEY,
      }
    }
    return {
      apiKey: process.env.FCM_API_KEY,
      projectId: process.env.FCM_PROJECT_ID,
      senderId: process.env.FCM_SENDER_ID,
      appId: process.env.FCM_APP_ID,
      measurementId: process.env.FCM_MEASUREMENT_ID,
      vapidKey: process.env.FCM_VAPID_KEY,
    }
  }

  isApp(): boolean {
    let isApp = false
    if (this.isBrowser()) {
      isApp = window?.navigator?.userAgent?.includes('mobileApp')
    }
    return isApp
  }

}
