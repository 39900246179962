import React, { Fragment, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { Image, IconEnum, SmallLoader, Paragraph, Button } from '@client/components/atoms'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { DeviceContainer } from '@client/components/utility'
import { SiteHelper } from '@client/lib/SiteHelper'
import { UserMenuDishFragment, useRemoveDishFromUserMenuMutation } from '@hooks/api'
import { PortionSizeEnum } from '@uctypes/api/globalTypes'

import { TableCell, TableRow } from '../tables'

const ImageContainer = styled.a`
  cursor: pointer;
  ${ResponsivePXValue('width', { mobile: '48px', tablet: '88px', desktop: '88px' })}
  ${ResponsivePXValue('height', { mobile: '48px', tablet: '88px', desktop: '88px' })}
`

const MealKitTitle = styled.div`
  ${ResponsivePXValue('width', '284px')}
`

const Category = styled.div`
  ${ResponsivePXValue('width', '176px')}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const MobileProductNameAndRemove = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
`

const MobileRemoveIcon = styled.div`
  ${ResponsivePXValue('width', '12px')}
  ${ResponsivePXValue('height', '12px')}
`

const OverlayContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('height', '96px')}
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoaderBox = styled.div`
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
`

export interface OrderSubscriptionItemCurrentProps {
  dish: UserMenuDishFragment
  portionSize: string
  onNavigate: () => void
}

interface OrderSubscriptionItemCurrentState {
  loading: boolean
}

const DEFAULT_STATE: OrderSubscriptionItemCurrentState = {
  loading: false,
}

export function OrderSubscriptionItemCurrent({ dish, portionSize, onNavigate }: OrderSubscriptionItemCurrentProps): JSX.Element {

  const [removeItemFromCart] = useRemoveDishFromUserMenuMutation()
  const [state, setState] = useState<OrderSubscriptionItemCurrentState>({ ...DEFAULT_STATE })
  const { addToast } = useToasts()

  const getCategories = (): string => {
    const categories = dish.dish.mealKitCategories
    if (categories) {
      let categoriesText = SiteHelper.toTitleCase(categories[0].title)
      for (let i = 1; i < categories.length; i++) {
        categoriesText += `, ${SiteHelper.toTitleCase(categories[i].title)}`
      }
      return categoriesText
    }
  }

  const getPortionSize = (): number => {
    switch (portionSize) {
      case PortionSizeEnum.SERVES_1:
        return 1
      case PortionSizeEnum.SERVES_2:
        return 2
      case PortionSizeEnum.SERVES_3:
        return 3
      case PortionSizeEnum.SERVES_4:
        return 4

    }
  }

  const _handleOnRemove = async (): Promise<void> => {
    try {
      _toggleLoading(true)
      await removeItemFromCart({
        variables: {
          userMenuDishId: dish.id,
        },
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _toggleLoading(false)
  }

  const _toggleLoading = (state: boolean): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: state },
    }))
  }

  return (
    <Fragment>
      <DeviceContainer $desktop>
        <TableRow margin='12px 0'>
          <TableCell padding='0 16px 0 0'>
            <ImageContainer onClick={onNavigate}>
              <Image
                width={88}
                height={88}
                round={true}
                lazy={false}
                src={dish?.dish?.coverImage?.desktop} />
            </ImageContainer>
          </TableCell>
          <TableCell direction='column' grow='1' justify='center'>
            <MealKitTitle>
              <Paragraph variant='p2' className='order-item-title' bold onClick={onNavigate}>{dish?.dish?.name}</Paragraph>
              <Paragraph variant='p3' color='grey'>
                {`Eat within ${dish.dish.cookWithin} days`}
              </Paragraph>
            </MealKitTitle>
          </TableCell>
          <TableCell align='center' grow='1' padding='0 24px 0 0'>
            <Category>
              <Paragraph variant='p3'>
                {getCategories()}
              </Paragraph>
            </Category>
          </TableCell>
          <TableCell align='center' justify='center' padding='0 24px 0 0'>
            <Paragraph variant='p3'>
              {`Serves ${getPortionSize()}`}
            </Paragraph>
          </TableCell>
          <TableCell align='center' justify='center'>
            <Button
              color='transparent'
              icon={IconEnum.CLOSE_OUTLINE}
              iconColor={theme.colors.greys.darkCodGrey}
              size='small'
              onClick={_handleOnRemove} />
          </TableCell>
        </TableRow>
        <If condition={state.loading}>
          <OverlayContainer>
            <LoaderBox>
              <SmallLoader color={theme.colors.oranges.coral} />
            </LoaderBox>
          </OverlayContainer>
        </If>
      </DeviceContainer>
      <DeviceContainer $mobile $tablet>
        <TableRow margin='12px 0'>
          <TableCell padding='0 8px 0 0'>
            <ImageContainer onClick={onNavigate}>
              <Image round={true} lazy={false} src={dish?.dish?.coverImage?.mobile} />
            </ImageContainer>
          </TableCell>
          <TableCell direction='column' grow='1' justify='center'>
            <MobileProductNameAndRemove>
              <Paragraph variant='p2' bold className='order-item-title' onClick={onNavigate}>{dish?.dish?.name}</Paragraph>
              <MobileRemoveIcon>
                <Button
                  color='transparent'
                  icon={IconEnum.CLOSE_OUTLINE}
                  iconColor={theme.colors.greys.darkCodGrey}
                  size='small'
                  onClick={_handleOnRemove} />
              </MobileRemoveIcon>
            </MobileProductNameAndRemove>
            <Paragraph variant='p3'>
              {getCategories()}
            </Paragraph>
            <Paragraph variant='p3'>
              {`Serves ${getPortionSize()}`}
            </Paragraph>
            <Paragraph variant='p3' color='grey'>
              {`Eat within ${dish.dish.cookWithin} days`}
            </Paragraph>
          </TableCell>
        </TableRow>
        <If condition={state.loading}>
          <OverlayContainer>
            <LoaderBox>
              <SmallLoader color={theme.colors.oranges.coral} />
            </LoaderBox>
          </OverlayContainer>
        </If>
      </DeviceContainer>
    </Fragment>
  )

}
