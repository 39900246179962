import React from 'react'

import { NotFoundState } from '@molecules/index'

export function NotFoundPage(): JSX.Element {

  return (
    <NotFoundState item='PAGE' />
  )
}
