import React, { useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import { format } from 'date-fns'
import { ExecutionResult } from 'graphql'
import update from 'react-addons-update'
import styled from 'styled-components'

import { CHECKOUT_DEFAULT_STATE, CheckoutPlugin } from '@api/local'
import { Heading, Rule } from '@client/components/atoms'
import { ContentBlock } from '@client/components/molecules/stores/ContentBlock'
import { ResponsivePXValue } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { useUserCartQuery, useGetCheckoutQuery, CartItemFragment, useCheckoutCartMutation, CheckoutCartMutation } from '@hooks/api'
import { CheckoutTypeEnum } from '@uctypes/api/globalTypes'

import { CheckoutContainer } from '../CheckoutContainer'
import { CheckoutOrderSummary } from '../CheckoutOrderSummary'
import { CheckoutPromoCodes } from '../CheckoutPromoCodes'
import { OnDemandItem } from '../OnDemandItem'
import { SubscriptionItem } from '../SubscriptionItem'

const ContentContainer = styled.div`
  
  ${ResponsivePXValue('width', { mobile: 'CALC(100% - 32px)', tablet: 'CALC(100% - 32px)', desktop: 'CALC(100% - 64px)' })}
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px 16px', desktop: '24px 32px' })}

  .rule {
    ${ResponsivePXValue('margin', { mobile: '20px 0', tablet: '25px 0', desktop: '30px 0' })}
  }
`

const ItemsContainer = styled.div``

export interface CheckoutSummaryProps {
  checkoutType: CheckoutTypeEnum
}

interface CheckoutSummaryState {
  walletOpen: boolean
}

const DEFAULT_STATE: CheckoutSummaryState = {
  walletOpen: false,
}

export function CheckoutSummary({ checkoutType }: CheckoutSummaryProps): JSX.Element {

  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: checkoutData = { checkout: { ...CHECKOUT_DEFAULT_STATE } } } = useGetCheckoutQuery()
  const [checkoutCart, { loading: checkoutLoading }] = useCheckoutCartMutation()
  const [state, setState] = useState<CheckoutSummaryState>({ ...DEFAULT_STATE })

  const cartItems = [...userCartData?.currentUser?.activeCart?.cartItems || []]
    .reverse()

  const _handleContinue = async (): Promise<void> => {
    const discounts = userCartData?.currentUser?.activeCart?.reductions
    let discountTitles = ''
    let discountValue = 0
    for (let i = 0; i < discounts.length; i++) {
      discountTitles = discountTitles +discounts[i].title
      discountValue = discountValue + discounts[i].value
    }
    if (checkoutType === CheckoutTypeEnum.SUBSCRIPTION) {

      CheckoutPlugin.shared().thankYou()
    } else {
      const response: ExecutionResult<CheckoutCartMutation> = await checkoutCart({
        variables: {
          id: userCartData.currentUser.activeCart.id,
          creditCardId: checkoutData.checkout.creditCardId,
          userAddressId: checkoutData.checkout.userAddressId,
        },
        refetchQueries: SiteHelper.getCheckoutRefetchQueries(),
        awaitRefetchQueries: true,
      })

      if (response.data.checkoutCart.errors && response.data.checkoutCart.errors.length) {
        await CheckoutPlugin.shared().setCartErrors(client, [...response.data.checkoutCart.errors])
      } else {
        CheckoutPlugin.shared().thankYou({
          orderId: response.data.checkoutCart.order.shortId,
          dispatchDate: response.data.checkoutCart.order.dispatchDate,
          cartItems,
        })
      }

    }
  }

  const _handleWalletChange = (open: boolean) => {
    setState((prevState) => update(prevState, {
      walletOpen: { $set: open },
    }))
  }

  let error: string | null = null
  if (!userCartData?.currentUser?.checkoutStatus?.hasSetPaymentMethod) {
    error = 'Select a payment method to continue'
  }
  const loading = userCartLoading || checkoutLoading
  const $sidebar = (
    <>
      <CheckoutOrderSummary
        loading={loading}
        error={error}
        buttonText={checkoutType === CheckoutTypeEnum.ON_DEMAND ? 'PLACE ORDER' : 'CONTINUE'}
        type={checkoutType}
        showDetail
        onWalletChange={_handleWalletChange}
        onCheckout={_handleContinue} />
      <CheckoutPromoCodes
        loading={loading}
        disabled={state.walletOpen} />
    </>
  )
  const title = checkoutType === CheckoutTypeEnum.SUBSCRIPTION ? 'SUBSCRIPTION ACTIVE' : 'YOUR CART'
  const billingDate = format(new Date(userCartData?.currentUser?.activeMenu?.billingDate), 'cccc do MMM yyyy')
  const deliveryDate = format(new Date(userCartData?.currentUser?.activeMenu?.deliveryDate), 'cccc do MMM yyyy')
  const description = checkoutType === CheckoutTypeEnum.SUBSCRIPTION
    ? `This order will be billed on ${billingDate}, for delivery on ${deliveryDate}.  You can swap out any dishes up until billing day, after which your order will be locked in. All add-ons are applied once off.`
    : undefined

  let cartItem: CartItemFragment

  return (
    <CheckoutContainer sidebar={$sidebar}>
      <ContentBlock
        title={title}
        description={description}>
        <ContentContainer>
          <If condition={checkoutType === CheckoutTypeEnum.SUBSCRIPTION}>
            <>
              <Heading variant='h6'>Meal Kit Subscription</Heading>
              <ItemsContainer>
                <For each='cartItem' of={cartItems || []}>
                  <If condition={cartItem.product.__typename === 'MealKit'}>
                    <SubscriptionItem key={cartItem.id} cartItem={cartItem} />
                  </If>
                </For>
              </ItemsContainer>
              <If condition={userCartData?.currentUser?.activeCart?.totalNonSubscriptionItems > 0}>
                <Rule className='rule' color='slate' />
              </If>
            </>
          </If>
          <If condition={userCartData?.currentUser?.activeCart?.totalNonSubscriptionItems > 0}>
            <If condition={checkoutType === CheckoutTypeEnum.SUBSCRIPTION}>
              <Heading variant='h6'>Add Ons</Heading>
            </If>
            <ItemsContainer>
              <For each='cartItem' of={cartItems || []}>
                <If condition={!cartItem.isFromSubscription}>
                  <OnDemandItem key={cartItem.id} cartItem={cartItem} />
                </If>
              </For>
            </ItemsContainer>
          </If>
        </ContentContainer>
      </ContentBlock>
    </CheckoutContainer>
  )

}
