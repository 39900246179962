import React from 'react'

import { ApolloProvider, ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { ThemeProvider } from 'styled-components'
import { ModalProvider } from 'styled-react-modal'

import { theme } from '@components/Theme'
import { ToastProvider } from '@contexts/ToastProvider'
import { Routes } from '@routes/Routes'
import { ResizeContainer, ScrollToTop } from '@utility/index'

import { ConfigProvider } from './contexts/ConfigProvider'
import { Config } from './lib/Config'
interface AppProps {
  config: Config
  client: ApolloClient<NormalizedCacheObject>
}

export function App({ config, client }: AppProps): JSX.Element {
  return (
    <ConfigProvider config={config}>
      <ScrollToTop>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <ToastProvider>
              <ModalProvider>
                <ResizeContainer>
                  <Routes />
                </ResizeContainer>
              </ModalProvider>
            </ToastProvider>
          </ThemeProvider>
        </ApolloProvider>
      </ScrollToTop>

    </ConfigProvider>
  )

}
