import React from 'react'

import styled from 'styled-components'

import { Paragraph } from '@client/components/atoms'
import { ResponsivePXValue, theme } from '@client/components/Theme'

const Container = styled.div<{$isMiniCard: boolean, $topIndex: number, $bgColor: string}>`
  display: flex;
  position: absolute;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: ${(props): string => props?.$bgColor ? props.$bgColor : props.theme.colors.slates.bitter};
  top: ${(props): string => `${12 + (props.$topIndex * 28)}px`};
  
  ${ResponsivePXValue('border-top-right-radius', '6px')}
  ${ResponsivePXValue('border-bottom-right-radius', '6px')}

  .text {
    margin: 0;
    ${ResponsivePXValue('padding', '3px 8px')}
  }
`

export interface FloatingUpSellProps {
  text: string
  isMiniCard?: boolean
}

export function FloatingUpSell({ text, isMiniCard }: FloatingUpSellProps): JSX.Element {

  const upSellTexts = text.split(',').map(item => {
    const [text, color] = item.trim().split('|')
    return { text, color }
  })

  let upSellText: { text: string, color: string }
  let index: number

  return (
    <>
      <For each='upSellText' of={upSellTexts} index='index'>
        <Container $isMiniCard={isMiniCard} $topIndex={index} $bgColor={upSellText.color} key={index}>
          <Paragraph variant='p3' className='text' color={theme.colors.whites.pureWhite}>{upSellText.text}</Paragraph>
        </Container>
      </For>
    </>
  )

}
