import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { DeviceContainer, Paragraph } from '@client/components'
import { ContentSeperator, ContentSeperatorTypeEnum, Spacer, Button } from '@client/components/atoms'
import { Form, TextInput, useForm, RadioButton, RadioButtonOption, RadioButtonProps } from '@client/components/molecules'
import { ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { ExecutionResult } from '@graphql-tools/utils'
import { DiscoveryPartnershipVerifyMutation, UserDetailsDocument, useDiscoveryPartnershipVerifyMutation } from '@hooks/api'
import { DiscoveryPartnershipVerifyInput, PartnershipPlatformEnum, HealthyDiningFieldTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px', desktop: '32px' })}

  .subtitle-text {
    ${ResponsivePXValue('line-height', '26px')}
    ${ZeroSpace}
  }

  .note-text {
    ${ResponsivePXValue('line-height', '24px')}
    ${ZeroSpace}
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`

const MobileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  .number-text-input {
    ${ZeroSpace}
  }

  .validate-account-button {
    ${ZeroSpace}
  }
`

const TextInputContainer = styled.div`
  ${ResponsivePXValue('width', '395px')}
  .number-text-input {
    ${ZeroSpace}
  }
`

const ValidateButtonContainer = styled.div`
  ${ResponsivePXValue('width', '236px')}
  .validate-account-button {
    ${ZeroSpace}
  }
`

const ChoiceContainer = styled.div`
  .number-radio-choice {
    width: 100%;
    ${ZeroSpace}
  }
`

export interface ActivateVitalityHealthyDiningProps {
  userId: string
}
interface ActivateVitalityHealthyDiningState {
  numberType: 'id' | 'passport'
}

const DEFAULT_STATE: ActivateVitalityHealthyDiningState = {
  numberType: 'id',
}

export function ActivateVitalityHealthyDining({ userId }: ActivateVitalityHealthyDiningProps): JSX.Element {

  const [state, setState] = useState<ActivateVitalityHealthyDiningState>({ ...DEFAULT_STATE })
  const [verify, { loading }] = useDiscoveryPartnershipVerifyMutation()
  const [form] = useForm()
  const { addToast } = useToasts()

  const options = [{
    title: 'Identity number',
    value: 'id',
  }, {
    title: 'Passport number',
    value: 'passport',
  }]

  const idRadioOptions = () => {
    return options.map(option => {
      return {
        className: 'size-option',
        value: option.value,
        element: <Paragraph bold variant='p3'>{option.title}</Paragraph>,
      }
    }) as unknown as RadioButtonOption[]
  }

  const idOptionsRadioProps: RadioButtonProps = {
    className: 'radio-container',
    name: 'numberType',
    outline: false,
    options: [...idRadioOptions()],
    error: '',
    rules: [{ required: true, message: 'Please select a reason' }],
  }

  const _handleValidateAccount = async (data: { number: string }): Promise<void> => {
    const postData: DiscoveryPartnershipVerifyInput = {
      user: userId,
      inputFieldType: state.numberType === 'id' ? HealthyDiningFieldTypeEnum.ID_NUMBER : HealthyDiningFieldTypeEnum.PASSPORT,
      inputFieldValue: data.number,
      partnershipPlatform: PartnershipPlatformEnum.DISCOVERY,
    }
    try {
      const response: ExecutionResult<DiscoveryPartnershipVerifyMutation> = await verify({
        variables: {
          input: postData,
        },
        refetchQueries: [{ query: UserDetailsDocument }],
        awaitRefetchQueries: true,
      })
      if (response.data.userPartnership.success) {
        addToast('Discovery Vitality has been successfully verified!', {
          appearance: 'success',
          autoDismiss: true,
        })
      } else {
        addToast(response.data.userPartnership.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const _handleFieldsChange = ({ numberType }: { numberType: string }) => {
    if (numberType) {
      setState((prevState) => update(prevState, {
        numberType: { $set: numberType },
      }))
    }
  }

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        numberType: 'id',
      })
    }
  }, [form])

  return (
    <Form form={form} onValuesChange={_handleFieldsChange} onFinish={_handleValidateAccount}>
      <Container>
        <Paragraph variant='p1' className='subtitle-text'>{'Please type in your ID number or passport number so that we can link your Discovery Vitality HealthyDining profile with your UCOOK profile and reward you with up to 25% back on your healthier meal purchases.'}</Paragraph>
        <ContentSeperator margin='16px 0px' type={ContentSeperatorTypeEnum.THICK} />
        <ChoiceContainer>
          <RadioButton {...idOptionsRadioProps} />
        </ChoiceContainer>
        <Spacer variant='horizontal' universal='16px' />
        <DeviceContainer $mobile>
          <MobileInputContainer>
            <TextInput
              variant='text'
              label={state.numberType === 'id' ? 'ID number' : 'Passport number'}
              showLabel={false}
              placeholder={state.numberType === 'id' ? 'ENTER YOUR SA ID NUMBER' : 'ENTER YOUR PASSPORT NUMBER'}
              name='number'
              className='number-text-input'
              rules={[
                { required: true, message: state.numberType === 'id' ? 'Please input an SA ID number' : 'Please input a Passport number' },
                { min: state.numberType === 'id' ? 13 : 5, message: state.numberType === 'id' ? 'Please enter a valid SA ID number' : 'Please enter a valid Passport number' },
                { max: state.numberType === 'id' ? 13 : 15, message: state.numberType === 'id' ? 'Please enter a valid SA ID number' : 'Please enter a valid Passport number' },
              ]}
              disabled={loading} />
            <Spacer variant='horizontal' universal='16px' />
            <Button
              loading={loading}
              color='orange'
              className='validate-account-button'
              title='Validate your account'
              disabled={loading}
              onClick={() => form.submit()} />
          </MobileInputContainer>
        </DeviceContainer>
        <DeviceContainer $tablet $desktop>
          <InputContainer>
            <TextInputContainer>
              <TextInput
                variant='text'
                label={state.numberType === 'id' ? 'ID number' : 'Passport number'}
                showLabel={false}
                placeholder={state.numberType === 'id' ? 'ENTER YOUR SA ID NUMBER' : 'ENTER YOUR PASSPORT NUMBER'}
                name='number'
                className='number-text-input'
                rules={[
                  { required: true, message: state.numberType === 'id' ? 'Please input an SA ID number' : 'Please input a Passport number' },
                  { min: state.numberType === 'id' ? 13 : 5, message: state.numberType === 'id' ? 'Please enter a valid SA ID number' : 'Please enter a valid Passport number' },
                  { max: state.numberType === 'id' ? 13 : 15, message: state.numberType === 'id' ? 'Please enter a valid SA ID number' : 'Please enter a valid Passport number' },
                ]}
                disabled={loading} />
            </TextInputContainer>
            <Spacer universal='16px' />
            <ValidateButtonContainer>
              <Button
                loading={loading}
                color='orange'
                className='validate-account-button'
                title='Validate your account'
                fluid
                disabled={loading}
                onClick={() => form.submit()} />
            </ValidateButtonContainer>
          </InputContainer>
        </DeviceContainer>
        <Spacer variant='horizontal' universal='16px' />
        <Paragraph variant='p3' color={theme.colors.greys.boulder} className='note-text'>{'We need you to share your personal information with Vitality to link your HealthyDining benefit. You will not be able to use this benefit if you do not provide the information to link. You will be required to share your ID or passport number.'}</Paragraph>
      </Container>
    </Form>
  )
}
