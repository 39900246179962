import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Loader } from '@client/components'
import { Spacer } from '@client/components/atoms'
import { Aisles, MealKitCompactCard } from '@client/components/molecules'
import { AislesBaseContainer, AislesLoadingContainer } from '@client/components/Theme'
import { renderMiniProductGrids } from '@client/components/utility/RenderMiniCardGrids'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useAnalytics } from '@client/hooks/UseAnalytics'
import { useUserDetailsQuery, MealKitCategoryFragment, useActiveMenuDishesQuery, useGetAppQuery, useGetAllMealKitCategoriesQuery, ActiveMenuDishesQuery, MealKitDishListFragment } from '@hooks/api'
import { DeviceTypeEnum, MealKitCategoryOrderEnum, MealKitCategoryStatusEnum, NumberOfPortionsEnum, OrderDirectionEnum } from '@uctypes/api/globalTypes'

const AislesContainer = styled.div`
  ${AislesBaseContainer}
`
const LoadingContainer = styled.div`
  ${AislesLoadingContainer}
`

export interface MealKitAisleCarouselProps {
  onDataLoaded?: () => void
  urlSuffix?: string
  aislesMaxWidth?: string
  dataLimit?: number
  fadeThresholdLimit?: number
}

interface MealKitAisleCarouselState {
  hasLoggedImpression: boolean
}

const DEFAULT_STATE: MealKitAisleCarouselState = {
  hasLoggedImpression: false,
}

export const MealKitAislesCarousel = React.memo(({ onDataLoaded, urlSuffix = '', aislesMaxWidth, dataLimit = 20, fadeThresholdLimit }: MealKitAisleCarouselProps): JSX.Element => {

  const [state, setState] = useState<MealKitAisleCarouselState>({ ...DEFAULT_STATE })
  const config = useConfig()
  const { logProductListImpression } = useAnalytics()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: mealKitCategoriesData, loading: mealKitCategoriesLoading } = useGetAllMealKitCategoriesQuery({
    ssr: config.fetchSSRQuery(),
    variables: {
      filters: {
        canSubscribe: true,
        status: [MealKitCategoryStatusEnum.ACTIVE],
      },
      order: {
        field: MealKitCategoryOrderEnum.DISPLAY_INDEX,
        direction: OrderDirectionEnum.ASC,
      },
    },
  })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const categoryIds: string[] = []

  mealKitCategoriesData?.mealKitCategories?.list?.forEach((category: MealKitCategoryFragment) => {
    categoryIds.push(category.id)
  })

  const variables = {
    limit: dataLimit,
    filters: {
      categories: categoryIds,
    },
  }

  const { data: menuDishesData, loading: menuDishesLoading } = useActiveMenuDishesQuery({ variables })
  const defaultPortions = appData.app.defaultPortions ? appData.app.defaultPortions: NumberOfPortionsEnum.SERVES_2

  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const maxDishesToDisplay = isDesktop ? 24 : 6
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const menuDishes: ActiveMenuDishesQuery['activeMenu']['menuDishes'] = menuDishesData?.activeMenu?.menuDishes
  const dishes: ActiveMenuDishesQuery['activeMenu']['menuDishes']['list'] = menuDishes?.list?.slice(0, maxDishesToDisplay) ? [...menuDishes?.list?.slice(0, maxDishesToDisplay)] : []

  const categorySorter: { [k: string]: number } = {
    ['Adventurous Foodie'.toLowerCase()]: 0,
    ['Quick & Easy'.toLowerCase()]: 1,
    ['Carb Conscious'.toLowerCase()]: 2,
    ['Fan Faves'.toLowerCase()]: 3,
    ['Veggie'.toLowerCase()]: 4,
    ['*New Simple & Save'.toLowerCase()]: 5,
  }

  const sortedDishes = dishes.sort((a, b) => {

    const titleA = categorySorter[a.mealKitCategories[0].title.toLowerCase()]
    const titleB = categorySorter[b.mealKitCategories[0].title.toLowerCase()]

    if (titleA > titleB) {
      return 1
    }
    if (titleA < titleB) {
      return -1
    }
    return 0
  })

  const displayFade = (list: unknown[]) => fadeThresholdLimit ? list?.length > fadeThresholdLimit : false

  const loading = menuDishesLoading || mealKitCategoriesLoading || userDetailsLoading

  useEffect(() => {
    if (!loading && !state.hasLoggedImpression && !!dishes.length) {
      logProductListImpression(
        dishes.map((dish, displayIndex) => {
          return {
            item_name: dish?.name,
            item_id: dish?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === defaultPortions)?.id,
            price: dish?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === defaultPortions)?.price,
            item_brand: 'UCOOK',
            item_category: dish?.mealKitCategories?.map((cat) => cat.id)?.join(', '),
            item_variant: dish?.mealKitCategories?.map((cat) => cat.title).join(', '),
            item_list_name: 'Meal Kit',
            index: displayIndex,
          }
        }),
        userDetailsData?.currentUser?.id)
      setState((prevState) => update(prevState, {
        hasLoggedImpressions: { $set: true },
      }))
    }
  }, [userDetailsData?.currentUser?.addresses])
  useEffect(() => {
    if (!!onDataLoaded && !loading) {
      onDataLoaded()
    }
  }, [loading])
  let mealKit: MealKitDishListFragment

  return (
    <Choose>
      <When condition={loading}>
        <LoadingContainer>
          <Loader noShadow />
        </LoadingContainer>
      </When>
      <Otherwise>
        <AislesContainer>
          <Aisles
            title='Meal Kits'
            viewAllUrl={`/meal-kit${urlSuffix}`}
            className='aisles'
            slidesToScroll={1}
            isMiniCard={isMobile}
            aislesMaxWidth={aislesMaxWidth}
            displayFade={displayFade(sortedDishes)}>
            <Choose>
              <When condition={isMobile}>
                {renderMiniProductGrids(sortedDishes)}
              </When>
              <Otherwise>
                <For each='mealKit' of={sortedDishes || []}>
                  <MealKitCompactCard
                    className='product-card'
                    key={mealKit.id}
                    mealKit={mealKit} />
                </For>
              </Otherwise>
            </Choose>
          </Aisles>
          <Spacer desktop='16px' />
        </AislesContainer>
      </Otherwise>
    </Choose>
  )
})

MealKitAislesCarousel.displayName = 'MealKitAislesCarousel'
