import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import update from 'react-addons-update'
import styled from 'styled-components'

import { CheckoutPlugin } from '@api/local'
import { Button, DeviceContainer, Link, Paragraph, Spacer } from '@client/components'
import { ModalLoading } from '@client/components/molecules'
import { ResponsiveProperty } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { useActivateSubscription, useUserCartQuery } from '@hooks/api'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${ResponsiveProperty('justify-content', { mobile: 'flex-start', tablet: 'flex-start', desktop: 'center' })}
`

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .full-width {
    width: 100%;
  }
`

export interface ReactivateSubscriptionProps {
  onClose: () => void
}

interface ReactivateSubscriptionState {
  complete: boolean
}

const DEFAULT_STATE: ReactivateSubscriptionState = {
  complete: false,
}

export function ReactivateSubscription({ onClose }: ReactivateSubscriptionProps): JSX.Element {

  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [activateSubscription, { loading: activateLoading }] = useActivateSubscription()
  const [state, setState] = useState<ReactivateSubscriptionState>({ ...DEFAULT_STATE })

  const _handleReActivate = async (): Promise<void> => {
    await activateSubscription({
      variables: {
        id: userCartData?.currentUser?.activeMenu?.subscription?.id,
        reset: false,
      },
      refetchQueries: SiteHelper.getCheckoutRefetchQueries(),
      awaitRefetchQueries: true,
    })

    setState((prevState) => update(prevState, { complete: { $set: true } }))
    await CheckoutPlugin.shared().checkout(client)
  }

  const _handleClose = (): void => {
    onClose()
  }

  useEffect(() => {
    if (open) {
      setState((prevState) => update(prevState, { complete: { $set: false } }))
    }
  }, [open])

  const loading = userCartLoading || activateLoading

  return (
    <Choose>
      <When condition={loading || state.complete}>
        <ModalLoading />
      </When>
      <Otherwise>
        <Container>
          <DeviceContainer $mobile $tablet>
            <Paragraph variant='p1'>
              Your Meal kit subscription is currently paused. To add dishes to your next order, activate your subscription by selecting activate below.
            </Paragraph>
          </DeviceContainer>
          <DeviceContainer $desktop>
            <Paragraph variant='p1' align='center'>
              Your Meal kit subscription is currently paused. To add dishes to your next order, activate your subscription by selecting activate below.
            </Paragraph>
          </DeviceContainer>
          <Spacer universal='24px' />
          <ActionContainer>
            <DeviceContainer $mobile $tablet className='full-width'>
              <Button
                loading={loading}
                color='black'
                fullWidth
                title='ACTIVATE NOW'
                onClick={_handleReActivate} />
            </DeviceContainer>
            <DeviceContainer $desktop>
              <Button
                loading={loading}
                color='black'
                fullWidth
                title='ACTIVATE NOW'
                onClick={_handleReActivate} />
            </DeviceContainer>
            <Spacer mobile='8px' desktop='16px' />
            <Link variant='l2' decoration='underline' onClick={_handleClose}> No thanks </Link>
          </ActionContainer>
        </Container>
      </Otherwise>
    </Choose>
  )
}
