import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'

import { ErrorBlock, Modal } from '@client/components/molecules'
import { useAnalytics } from '@client/hooks/UseAnalytics'
import { FrozenMealDishListFragment, UserCartDocument, useAddItemToCartMutation } from '@hooks/api'
import { ModalLoading } from '@molecules/index'
import { FrozenPortionSizeEnum } from '@uctypes/api/globalTypes'

import { AddToCartProductTypeEnum, AddToCartForm } from '../forms/AddToCartForm'

enum AddToCartStepEnum {
  ADD_TO_CART = 'ADD_TO_CART',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}
export interface AddToCartModalProps {
  product: FrozenMealDishListFragment
  productType: AddToCartProductTypeEnum
  open: boolean
  price?: string
  onClose: () => void
}

interface AddToCartModalState {
  displayStep: AddToCartStepEnum
  error: string
}

const DEFAULT_STATE: AddToCartModalState = {
  displayStep: AddToCartStepEnum.ADD_TO_CART,
  error: '',
}

export function AddToCartModal({ product, productType, open, onClose, price }: AddToCartModalProps): JSX.Element {

  const [state, setState] = useState<AddToCartModalState>({ ...DEFAULT_STATE })
  const [addItemToCart] = useAddItemToCartMutation()
  const { addToast } = useToasts()
  const { logAddToCart } = useAnalytics()
  const _handleClose = (): void => {
    if (onClose) {
      onClose()
    }
  }

  const _handleAddToCart = async (selectedServingSize: FrozenPortionSizeEnum, quantity: number): Promise<void> => {
    _setLoading()
    try {
      await addItemToCart({
        variables: { productId: selectedServingSize, quantity },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })

      if (productType === AddToCartProductTypeEnum.FROZEN) {
        _handleGTMLogCraftMeal(product as FrozenMealDishListFragment, selectedServingSize as FrozenPortionSizeEnum, quantity, Number(price))
      }

      addToast(`Successfully added (${quantity}) item to cart.`, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    if (onClose) {
      onClose()
    }
  }

  const _handleGTMLogCraftMeal = (product: FrozenMealDishListFragment, selectedServingSize: FrozenPortionSizeEnum, quantity: number, price: number): void => {
    // Analytics
    logAddToCart({
      item_name: product?.name + ' - ' + selectedServingSize,
      item_id: product?.id,
      price,
      item_brand: 'UCOOK',
      item_category: product?.frozenCategories?.map((cat) => cat.id)?.join(', '),
      item_variant: product?.frozenCategories?.map((cat) => cat.title)?.join(', '),
      item_list_name: 'Craft Meals',
      quantity,
    })
  }

  const _handleError = (): void => {
    setState((prevState) => ({ ...prevState, displayStep: AddToCartStepEnum.ERROR }))
  }

  useEffect(() => {
    if (open) {
      setState((prevState) => ({ ...prevState, displayStep: AddToCartStepEnum.ADD_TO_CART }))
    }
  }, [open])

  const _setLoading = () => {
    setState((prevState) => update(prevState, {
      displayStep: { $set: AddToCartStepEnum.LOADING },
    }))
  }

  let title = 'Add To Cart'
  switch (state.displayStep) {
    case AddToCartStepEnum.ERROR:
      title = 'Oops'
      break
  }

  return (
    <Modal
      showCloseButton={state.displayStep !== AddToCartStepEnum.LOADING}
      allowBackgroundClose={false}
      open={open}
      title={title}
      onClose={_handleClose}>
      <Choose>
        <When condition={state.displayStep === AddToCartStepEnum.LOADING}>
          <ModalLoading message='Attempting to Add To Cart' />
        </When>
        <When condition={state.displayStep === AddToCartStepEnum.ERROR}>
          <ErrorBlock
            title={state.error}
            actionTitle='Back'
            onClick={() => setState((prevState) => ({ ...prevState, displayStep: AddToCartStepEnum.ADD_TO_CART }))} />
        </When>
        <Otherwise>
          <AddToCartForm
            product={product}
            productType={productType}
            onAddToCart={_handleAddToCart}
            onError={_handleError} />
        </Otherwise>
      </Choose>
    </Modal>
  )
}
