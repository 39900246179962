import React from 'react'

import styled, { CSS } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;;
`

const ItemContainer = styled.div`

  cursor: pointer;
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: auto;
  flex-shrink: 1;
  ${ResponsivePXValue('padding-right', '10px')}
`

const ButtonOuterContainer = styled.div<{ $selected: boolean, $disabled: boolean }>`

  border-style: solid;
  border-color: ${(props): string => props.theme.colors.whites.silver};
  ${ResponsivePXValue('width', '16px')}
  ${ResponsivePXValue('height', '16px')}
  ${ResponsivePXValue('padding', '2px')}
  ${ResponsivePXValue('margin', { mobile: '1px', tablet: '2px', desktop: '2px' })}
  ${ResponsivePXValue('border-radius', '14px')}
  ${ResponsivePXValue('border-width', '1px')}

  ${(props): CSS => {
    if (props.$selected) {
      return `
        border-color: ${props.theme.colors.oranges.coral};
        ${ResponsivePXValue('border-width', { mobile: '2px', tablet: '3px', desktop: '3px' })}
        ${ResponsivePXValue('margin', '0')}
      `
    }
    if (props.$disabled) {
      return `
        border-color: ${props.theme.colors.whites.silver};
      `
    }
  }}

`

const ButtonInnerContainer = styled.div<{ $disabled: boolean }>`

  border-radius: 50%;
  background-color: ${(props): string => props.theme.colors.oranges.coral};
  ${ResponsivePXValue('border-radius', '8px')}
  ${ResponsivePXValue('width', '16px')}
  ${ResponsivePXValue('height', '16px')}

  ${(props): CSS => {
    if (props.$disabled) {
      return `
        background-color: ${props.theme.colors.whites.silver};
      `
    }
  }}
`

export interface RadioButtonControlProps {
  selected: boolean
  disabled?: boolean
  onClick?: () => void
}

export function RadioButtonControl({ selected = false, disabled = false, onClick }: RadioButtonControlProps): JSX.Element {

  const _handleClicked = (): void => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <Container>
      <ItemContainer
        onClick={() => _handleClicked()}>
        <ButtonOuterContainer
          $selected={selected}
          $disabled={disabled}>
          <If condition={selected}>
            <ButtonInnerContainer $disabled={disabled} />
          </If>
        </ButtonOuterContainer>
      </ItemContainer>
    </Container>
  )

}
