import React, { useEffect, useState } from 'react'

import queryString from 'qs'
import { useParams, useNavigate, useLocation } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import { styled } from 'styled-components'

import { GlobalModalTypeEnum, ModalsPlugin } from '@api/local/ModalsPlugin'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserDetailsQuery, useValidateFriendReferenceMutation, ValidateFriendReferenceMutation } from '@hooks/api'
import { PageSectionGroup } from '@molecules/index'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { PersonalizedHome } from '../organisms'
import { PasswordResetModal } from '../organisms/modals/PasswordResetModal'
import { ReferralModal } from '../organisms/modals/ReferralModal'

enum HomeModalEnums {
  RESET = 'RESET',
  NONE = 'NONE',
}

interface HomeState {
  currentModal: HomeModalEnums
  referralModalOpen: boolean
  personalizedHomeTest: boolean
}

const DEFAULT_STATE: HomeState = {
  currentModal: HomeModalEnums.NONE,
  referralModalOpen: false,
  personalizedHomeTest: false,
}

const HomeContainer = styled.div`
  max-width: 100vw;
  overflow-x: hidden; 
`

/**
 * GA Tracking Elements
 * [ ] Banner Clicks/Navigation?
 *
 * SEO
 * [ ] Link
 * [ ] Description
 * [ ] Tags
 */

const seo = {
  name: 'UCOOK Home',
  title: 'UCOOK | Meal Kits & Meal Delivery Service South Africa',
  meta: [{
    name: 'description',
    content: 'SA\'s most popular Meal Kit 📦🥕 We deliver the freshest ethically sourced ingredients in the right quantities so you can make easy weeknight meals at home.',
  }, {
    name: 'keywords',
    content: 'Meal Kits,Meal Prep Delivery,Food Box Delivery',
  }, {
    name: 'robots',
    content: 'index,follow',
  }, {
    property: 'og:title',
    content: 'UCOOK Home',
  }, {
    property: 'og:url',
    content: 'https://ucook-co.za/',
  }, {
    property: 'og:image',
    content: 'https://ucook.co.za/images/logo.png',
  }, {
    property: 'og:description',
    content: 'SA\'s most popular Meal Kit 📦🥕 We deliver the freshest ethically sourced ingredients in the right quantities so you can make easy weeknight meals at home.',
  }],
}

export function Home(): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<HomeState>({ ...DEFAULT_STATE })
  const params = useParams<{ token: string }>()
  const navigate = useNavigate()
  const location = useLocation()
  const { addToast } = useToasts()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [validateFriendReference, { data: validateResponse }] = useValidateFriendReferenceMutation()
  const isRegisteredUser = userDetailsData && userDetailsData?.currentUser?.__typename === 'RegisteredUser'
  const personalizedHomeTest = userDetailsData && userDetailsData?.currentUser?.tests?.findIndex((item) => item?.name === 'PersonalizedHomeLaunch')

  const _handleResetClose = (): void => {
    setState(prevState => ({ ...prevState, currentModal: HomeModalEnums.NONE }))
    navigate('/')
  }

  const _handleReferralModalClose = (): void => {
    setState(prevState => ({ ...prevState, referralModalOpen: false }))
  }

  const _handleReferralModalSeeMoreClose = (): void => {
    setState(prevState => ({ ...prevState, referralModalOpen: false }))
    navigate('/meal-kit')
  }

  const _handleReferralModalGetStartedClose = (): void => {
    setState(prevState => ({ ...prevState, referralModalOpen: false }))
    if (isRegisteredUser) {
      navigate('/meal-kit/plans')
    } else {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SIGN_UP)
    }
  }

  useEffect(() => {
    if (params.token) {
      setState(prevState => ({ ...prevState, currentModal: HomeModalEnums.RESET }))
    }
    const url = location.search
    const parts: any = queryString.parse(url, { ignoreQueryPrefix: true })
    if (parts.referralKey) {
      validateFriendReference({ variables: { referralKey: parts.referralKey } })
    }
  }, [])

  useEffect(() => {
    if (validateResponse !== undefined) {
      const rsp = validateResponse as ValidateFriendReferenceMutation
      if (rsp.response.success) {
        addToast(rsp.response.message, {
          appearance: 'success',
          autoDismiss: true,
        })
        setState(prevState => ({ ...prevState, referralModalOpen: true }))
      } else {
        addToast(rsp.response.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    }
  }, [validateResponse])

  return (
    <>
      <SearchEngineOptimization seo={seo} />
      <PasswordResetModal
        resetToken={params.token}
        onClose={_handleResetClose}
        open={state.currentModal === HomeModalEnums.RESET} />
      <ReferralModal
        open={state.referralModalOpen}
        onClose={_handleReferralModalClose}
        onSeeMore={_handleReferralModalSeeMoreClose}
        onGetStarted={_handleReferralModalGetStartedClose} />
      <Choose>
        <When condition={isRegisteredUser && personalizedHomeTest !== -1}>
          <PersonalizedHome />
        </When>
        <Otherwise>
          <HomeContainer>
            <PageSectionGroup identifier='HOME_PAGE' />
          </HomeContainer>
        </Otherwise>
      </Choose>
    </>
  )
}
