import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import update from 'react-addons-update'
import styled from 'styled-components'

import { CHECKOUT_DEFAULT_STATE, CheckoutPlugin } from '@api/local'
import { Link, ResponsivePXValue } from '@client/components'
import { ResponsiveImage, Heading, Paragraph, Rule } from '@client/components/atoms'
import { ModalLoading } from '@client/components/molecules'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useGetCheckoutQuery, useUserCartQuery, CartErrorFragment, CartItemFragment, useRemoveItemFromCartMutation, UserCartDocument } from '@hooks/api'
import { CartErrorTypeEnum } from '@uctypes/api/globalTypes'

const ErrorList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`

const ErrorContainer = styled.div`
  ${ResponsivePXValue('width', 'CALC(100% - 32px)')}
`

const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('margin', '16px')}
`

const ProductImage = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('width', '100px')}
  ${ResponsivePXValue('height', '100px')}
  ${ResponsivePXValue('margin-right', '8px')}
`

const ProductOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  ${ResponsivePXValue('padding', '0 8px')}

  .heading {
    margin: 0;
    padding: 0;
  }

  .description {
    margin: 0;
    padding: 0;
  }
`

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const OptionContainer = styled.div`
  margin-right: 1vw;
`

interface CartErrorsState {
  complete: boolean
  loadingItems: string[]
}

const DEFAULT_STATE: CartErrorsState = {
  complete: false,
  loadingItems: [],
}

export function CartErrors(): JSX.Element {

  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const [state, setState] = useState<CartErrorsState>({ ...DEFAULT_STATE })
  const { data: checkoutData = { checkout: { ...CHECKOUT_DEFAULT_STATE } } } = useGetCheckoutQuery()
  const [removeItemFromCart] = useRemoveItemFromCartMutation({
    refetchQueries: [{ query: UserCartDocument }],
    awaitRefetchQueries: true,

  })
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })

  const isOnDemand = userCartLoading ? 'UNKNOWN' : userCartData?.currentUser?.isOnDemand

  const fullItems: { error: CartErrorFragment, item: CartItemFragment | null, removeCount: number, type: CartErrorTypeEnum }[] = checkoutData?.checkout.cartErrors.map((cartError: CartErrorFragment) => {
    const item = userCartData?.currentUser?.activeCart?.cartItems?.find((cartItem) => cartItem.product.id === cartError?.product?.id) || null
    let removeCount = 0
    if (item) {
      if (isOnDemand) {
        removeCount = item.quantity - item.product?.stockCount
      } else {
        removeCount = Math.abs(item.product?.stockCount)
      }
    }
    return {
      error: cartError,
      item,
      removeCount,
      type: cartError.type,
    }
  })

  let errorItem: { error: CartErrorFragment, item: CartItemFragment | null, removeCount: number, discountCode: string | null, type: CartErrorTypeEnum }
  let itemIndex: number

  const _handleRemove = async (id: string): Promise<void> => {
    const productId = userCartData.currentUser.activeCart.cartItems.find((cartItem) => cartItem.id === id).product.id
    setState(prevState => ({ ...prevState, loadingItems: [...state.loadingItems, id] }))
    await removeItemFromCart({ variables: { productId, cartId: userCartData.currentUser.activeCart.id } })
    setState(prevState => ({ ...prevState, loadingItems: [...state.loadingItems].splice(state.loadingItems.indexOf(id), 1) }))
    const cartErrors = [...checkoutData?.checkout.cartErrors]
    cartErrors.splice(cartErrors.findIndex((cartError: CartErrorFragment) => cartError.product.id === productId), 1)
    await CheckoutPlugin.shared().setCartErrors(client, cartErrors)
  }

  useEffect(() => {
    if (open) {
      setState((prevState) => update(prevState, { complete: { $set: false } }))
    }
  }, [open])

  const loading = userCartLoading

  return (
    <Choose>
      <When condition={loading || state.complete}>
        <ModalLoading />
      </When>
      <Otherwise>
        <>
          <Paragraph align='center'>
            Please fix the following issues before you can continue
          </Paragraph>
          <ErrorList>
            <For each='errorItem' index='itemIndex' of={fullItems}>
              <ErrorContainer key={errorItem?.error?.id}>
                <ProductContainer>
                  <ProductImage>
                    <ResponsiveImage image={errorItem?.item?.product?.coverImage} />
                  </ProductImage>
                  <ProductOptions>
                    <Heading variant='h6' className='heading'>
                      {errorItem?.error?.product?.name}
                    </Heading>
                    <Paragraph variant='p3' className='description'>
                      {errorItem?.error?.description}
                    </Paragraph>
                    <OptionsContainer>
                      <OptionContainer>
                        <Link
                          disabled={state.loadingItems.indexOf(errorItem?.item?.id) !== -1}
                          onClick={(): Promise<void> => _handleRemove(errorItem?.item?.id)}>
                            Remove
                        </Link>
                      </OptionContainer>
                    </OptionsContainer>
                  </ProductOptions>
                </ProductContainer>
                <If condition={itemIndex < fullItems.length - 1}>
                  <Rule color='slate' />
                </If>
              </ErrorContainer>
            </For>
          </ErrorList>
        </>
      </Otherwise>
    </Choose>
  )

}
