import React from 'react'

import styled from 'styled-components'

import { SmallLoader, Paragraph, Link, Button, Spacer } from '@client/components/atoms'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserCartQuery, useUserDetailsQuery, UserMenuDishFragment, UserCartDocument, UserDetailsDocument, CartItemFragment, useRemoveDishFromUserMenuMutation, useRemoveItemFromCartMutation, FrozenMealCartFragment, WineCartFragment } from '@hooks/api'
import { ProductRangeEnum } from '@uctypes/api/globalTypes'

import { AddOnCartItem, MealKitCartItem } from '../cart'

import { AllItemsUnavailableForm } from './AllItemsUnavailableForm'

const Container = styled.div`
  width: 100%;
  display: grid;
  flex-direction: column;
`

const ItemsContainer = styled.div`
${ResponsivePXValue('padding', '32px 0px')}
  max-width: 100%;
`

const LoaderBox = styled.div`
  width: 100%;
  ${ResponsivePXValue('height', '24px')}
`

export interface ChangedToInvalidAddressFormProps {
  onTryDifferentAddress: () => void
  onNotify: () => void
}

export function ChangedToInvalidAddressForm({ onTryDifferentAddress, onNotify }: ChangedToInvalidAddressFormProps): JSX.Element {

  const config = useConfig()
  const { data: cartData, loading: cartLoading } = useUserCartQuery({ skip: !config.isBrowser() })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [removeItemFromMenu, { loading: removeItemFromMenuLoading }] = useRemoveDishFromUserMenuMutation({
    refetchQueries: [{
      query: UserCartDocument,
    }],
    awaitRefetchQueries: true,
  })

  const [removeItemFromCart, { loading: removeItemFromCartLoading }] = useRemoveItemFromCartMutation({
    refetchQueries: [{
      query: UserCartDocument,
    }, {
      query: UserDetailsDocument,
    }],
    awaitRefetchQueries: true,
  })

  const subscriptionItems = cartData?.currentUser?.activeMenu?.dishes || []
  const onDemandItems = cartData?.currentUser?.activeCart?.cartItems || []
  const isOnDemand = cartData?.currentUser?.isOnDemand || false

  const isOnDemandDeliveryUnvailable = (): boolean => {
    const productRanges = userDetailsData?.currentUser?.addresses?.find((address) => address.isDefault)?.location?.city?.productRanges || []
    const isFrozenDeliveryAvailable = productRanges?.indexOf(ProductRangeEnum.FROZEN_MEAL) !== -1
    return !isFrozenDeliveryAvailable
  }

  const isSubscriptionDeliveryUnvailable = (): boolean => {
    const productRanges = userDetailsData?.currentUser?.addresses?.find((address) => address.isDefault)?.location?.city?.productRanges || []
    const isMealKitPlanDeliveryAvailable = (productRanges?.indexOf(ProductRangeEnum.MEAL_KIT) !== -1) || (productRanges?.indexOf(ProductRangeEnum.MEAL_KIT_PLAN) !== -1)
    return !isMealKitPlanDeliveryAvailable
  }

  const unavailableSubscriptionItems = isSubscriptionDeliveryUnvailable() ? subscriptionItems : []
  const unavailableOnDemandItems = isOnDemandDeliveryUnvailable() ? onDemandItems as CartItemFragment[] : []
  const hasItems = unavailableSubscriptionItems.length > 0 || unavailableOnDemandItems.length > 0

  const _onRemoveItemsFromCart = async () => {
    await Promise.all(unavailableSubscriptionItems.map(item => removeItemFromMenu({ variables: { userMenuDishId: item?.id } })))
    await Promise.all(unavailableOnDemandItems.map(item => removeItemFromCart({ variables: { productId: item?.product.id } })))
  }

  let dishItem: UserMenuDishFragment
  let cartItem: CartItemFragment
  const loading = userDetailsLoading || cartLoading || removeItemFromMenuLoading || removeItemFromCartLoading

  return (
    <Choose>
      <When condition={loading}>
        <LoaderBox>
          <SmallLoader color={theme.colors.oranges.coral} />
        </LoaderBox>
      </When>
      <When condition={hasItems}>
        <Container>
          <Paragraph
            variant='p1'
            align='center'>
              It looks like you have changed your delivery address. The following items in your cart are no longer available or out of stock.
          </Paragraph>
          <ItemsContainer>
            <For each='dishItem' of={subscriptionItems || []}>
              <MealKitCartItem key={dishItem.id} dish={dishItem} />
            </For>
            <For each='cartItem' of={(cartData?.currentUser?.activeCart?.cartItems || []).filter(cartItem => !cartItem.isFromSubscription)}>
              <Choose>
                <When condition={cartItem.product.__typename === 'FrozenMeal'}>
                  <AddOnCartItem
                    key={cartItem.id}
                    subtitle={`By ${(cartItem.product as FrozenMealCartFragment)?.chef?.name}`}
                    item={cartItem}
                    product={cartItem.product}
                    userIsOnDemand={isOnDemand} />
                </When>
                <When condition={cartItem.product.__typename === 'Wine'}>
                  <AddOnCartItem
                    key={cartItem.id}
                    subtitle={(cartItem.product as WineCartFragment)?.vineyard?.name}
                    item={cartItem}
                    product={cartItem.product}
                    userIsOnDemand={isOnDemand} />
                </When>
                <Otherwise>
                  <Paragraph variant='p2'>Unknown cart item</Paragraph>
                </Otherwise>
              </Choose>
            </For>
          </ItemsContainer>
          <Button
            color='black'
            fluid
            title='TRY A DIFFERENT ADDRESS'
            onClick={onTryDifferentAddress} />
          <Spacer universal='16px' />
          <Link
            color={theme.colors.oranges.coral}
            textAlignment='center'
            onClick={_onRemoveItemsFromCart}>
              REMOVE ITEMS FROM CART
          </Link>
        </Container>
      </When>
      <Otherwise>
        <AllItemsUnavailableForm
          onTryDifferentAddress={onTryDifferentAddress}
          onNotify={onNotify} />
      </Otherwise>
    </Choose>

  )

}
