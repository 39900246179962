import { FieldFunctionOptions, gql, TypePolicies } from '@apollo/client'

import { DocumentNode, ExecutionResult } from 'graphql'
import Cookies from 'js-cookie'

import { RegisterDeviceMutation, RegisterDeviceMutationDocument, UserCheckoutStatusFragment, ValidateDeviceMutation, ValidateDeviceMutationDocument } from '@hooks/api'
import { Config, ConfigPlugin } from '@lib/Config'
import { RegisteredDeviceTypeEnum } from '@uctypes/api/globalTypes'

const COOKIE_NAME = 'ucook-web'
const NOTIFICATION_TRACKING = 'ucook-web-notifications'
const cookieExpirationDate = new Date()
cookieExpirationDate.setFullYear(cookieExpirationDate.getFullYear() + 10)

const isBrowser = (): boolean => {
  return (typeof window !== 'undefined')
}
export class UserPlugin implements ConfigPlugin {

  static instance: UserPlugin

  static shared(): UserPlugin {
    if (!this.instance) {
      this.instance = new UserPlugin()
    }
    return this.instance
  }

  token: string | null = null
  dismissedNotifications: string[] = [] as string[]
  config: Config

  async configure(config: Config): Promise<void> {
    this.config = config
  }

  hasDismissedNotifications(): boolean {
    if (this.dismissedNotifications.length > 0) {
      return true
    }
    if (isBrowser() && Cookies.get(NOTIFICATION_TRACKING)) {
      this.dismissedNotifications = JSON.parse(Cookies.get(NOTIFICATION_TRACKING) || '[]')
      return true
    }
    return false
  }

  addDismissedNotification(id: string): void {
    this.dismissedNotifications.push(id)
    if (isBrowser()) {
      Cookies.set(NOTIFICATION_TRACKING, JSON.stringify(this.dismissedNotifications), { expires: 14 })
    }
  }

  notificationIsDismissed(id: string): boolean {
    if (this.hasDismissedNotifications()) {
      return !!this.dismissedNotifications.find((value) => value === id)
    }
    return false
  }

  getCookieName(): string {
    return COOKIE_NAME
  }

  hasToken(): boolean {
    if (this.token) {
      return true
    }
    if (isBrowser() && Cookies.get(COOKIE_NAME)) {
      this.token = Cookies.get(COOKIE_NAME)
      return true
    }
    return false
  }

  getToken(): string {
    if (this.hasToken()) {
      return this.token
    }
    return null
  }

  setToken(token: string): void {
    this.token = token
    if (isBrowser()) {
      Cookies.set(COOKIE_NAME, token, { expires: cookieExpirationDate })
    }
  }

  clearToken(): void {
    this.token = null
    if (isBrowser()) {
      Cookies.remove(COOKIE_NAME)
    }
  }

  async clearDevice(): Promise<boolean> {
    Cookies.remove(COOKIE_NAME)
    Cookies.remove(NOTIFICATION_TRACKING)
    this.token = null
    this.dismissedNotifications = [] as string[]
    return true
  }

  async validateDevice(): Promise<{ id: string, isBot: boolean } | null> {
    const validateDeviceInput = {
      token: this.token,
    }
    try {
      const client = await this.config.getClient()
      const response: ExecutionResult<ValidateDeviceMutation> = await client.mutate({
        mutation: ValidateDeviceMutationDocument,
        variables: validateDeviceInput,
      })
      return response.data.validateDevice
    } catch (e) {
      console.log(e)
      return null
    }
  }

  async registerDevice(): Promise<{ id: string, isBot: boolean } | null> {
    if (isBrowser() && !this.hasToken()) {
      const client = await this.config.getClient()
      const response: ExecutionResult<RegisterDeviceMutation> = await client.mutate({
        mutation: RegisterDeviceMutationDocument,
        variables: { input: { userAgent: navigator.userAgent, type: RegisteredDeviceTypeEnum.BROWSER } },
      })
      this.setToken(response.data.registerDevice.id)
      return response.data.registerDevice
    }
  }

  async registerDeviceOnServer(userAgent: string, type: RegisteredDeviceTypeEnum): Promise<{ id: string, isBot: boolean }> {
    const client = await this.config.getClient()
    const response: ExecutionResult<RegisterDeviceMutation> = await client.mutate({
      mutation: RegisterDeviceMutationDocument,
      variables: { input: { userAgent, type } },
    })
    return response.data.registerDevice
  }

  headers(): { [k: string]: string } {
    return {
      Authorization: `Bearer ${this.getToken()}`,
    }
  }

  typePolicies = (): TypePolicies => ({
    RegisteredUser: {
      fields: {
        isOnDemand: {
          read(_: boolean, options: FieldFunctionOptions): boolean {
            const checkoutStatus = options.readField('checkoutStatus') as UserCheckoutStatusFragment
            if (!checkoutStatus?.hasAccount) {
              return true
            }
            if (checkoutStatus?.hasActiveSubscription || checkoutStatus?.hasConfiguredBox) {
              return false
            }
            return true
          },
        },
      },
    },
    GuestUser: {
      fields: {
        isOnDemand: {
          read(_: boolean, options: FieldFunctionOptions): boolean {
            const checkoutStatus = options.readField('checkoutStatus') as UserCheckoutStatusFragment
            if (!checkoutStatus?.hasAccount) {
              return true
            }
            if (checkoutStatus?.hasActiveSubscription || checkoutStatus?.hasConfiguredBox) {
              return false
            }
            return true
          },
        },
      },
    },
  })

  extensions = (): DocumentNode => gql`
    extend interface User {
      isOnDemand: Boolean!
    }

    extend type RegisteredUser {
      isOnDemand: Boolean!
    }

    extend type GuestUser {
      isOnDemand: Boolean!
    }
  `

}
