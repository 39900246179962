import 'react-datepicker/dist/react-datepicker.css'
import React from 'react'

import { loadableReady } from '@loadable/component'

import { createRoot, hydrateRoot } from 'react-dom/client'
import OneSignal from 'react-onesignal'
import { BrowserRouter } from 'react-router-dom'
import { TagManager, TagInterface } from 'sih-gtm/build/TagManager'

import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { Config } from '@lib/Config'

import { App } from './App'
import { configure } from './configure'
import { defaultNavigation } from './Navigation'

(async (): Promise<void> => {
  const config = new Config()

  // TODO: clean this up
  const tagManagerArgs: TagInterface = {
    id: 'GTM-KQBXJTG',
    auth: 'wq_Vs41rXxSAk53yLfgWlQ',
    preview: 'env-1',
    domain: 'https://www.googletagmanager.com',
    eventData: undefined,
  }

  const oneSignalArgs = {
    appId: '1be016ae-e363-493f-9b41-d736184fd2ba',
    serviceWorkerParam: { scope: '/workers/one-signal/' },
    serviceWorkerPath: 'workers/one-signal/worker.js',
    safari_web_id: 'web.onesignal.auto.560021c2-877c-448b-9811-f001e7b5ec58',
    promptOptions: {
      slidedown: {
        // enabled: true,
        // autoPrompt: true,
        prompts: [
          {
            type: 'push', // current types are "push" & "category"
            autoPrompt: false,
            text: {
              /* limited to 90 characters */
              actionMessage: "We'd like to send you notifications. This may include alerts, reminders and important info.",
              /* acceptButton limited to 15 characters */
              acceptButton: 'Allow',
              /* cancelButton limited to 15 characters */
              cancelButton: 'Cancel',
            },
            delay: {
              pageViews: 0,
              timeDelay: 5,
            },
          },
        ],
      },
    },
  }

  await configure(config)

  if (config.isBrowser() && config.isProductionDomain()) {
    TagManager.initialize(tagManagerArgs)
    // TODO: Move this to asynchronously load in App with useEffect
    OneSignal.init(oneSignalArgs)
  }

  // Just for testing purposes
  TagManager.initialize(tagManagerArgs)

  const client = await config.getClient()
  NavigationPlugin.shared().setNavigation(defaultNavigation)

  if (config.ssr()) {
    loadableReady(() => {
      hydrateRoot(
        document.getElementById('react-app'),
        <BrowserRouter>
          <App client={client} config={config} />
        </BrowserRouter>,
      )
    })
  } else {
    const root = createRoot(document.getElementById('react-app'))
    loadableReady(() => {
      root.render(
        <BrowserRouter>
          <App client={client} config={config} />
        </BrowserRouter>,
      )
    })
  }
})()
