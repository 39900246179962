import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { GlobalModalTypeEnum, ModalsPlugin } from '@api/local/ModalsPlugin'
import { Heading, Paragraph, Rule, Button, Spacer } from '@atoms/index'
import { MultipleImageContainer, ProductImageInterface } from '@client/components/atoms/images/MulitpleImageContainer'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useAnalytics } from '@client/hooks/UseAnalytics'
import { SiteHelper } from '@client/lib/SiteHelper'
import { ProductCategoriesContainer, ResponsiveProperty, ResponsivePXValue, SidePagePadding, theme, ZeroSpace } from '@components/Theme'
import { useUserDetailsQuery, useGetAppQuery, useUserCartQuery, FrozenTagFragment, UserCartDocument, useAddItemToCartMutation, useChangeCartItemQuantityMutation, useRemoveItemFromCartMutation, FrozenMealDishDetailsFragment, FrozenMealListFragment } from '@hooks/api'
import { CategoryTag, Form, useForm, SelectOption, getCraftMealPriceRange, ProductCategories, FloatingUpSell } from '@molecules/index'
import { FrozenPortionSizeEnum, DeviceTypeEnum, ProductAvailabilityEnum, ProductStockStatusEnum } from '@uctypes/api/globalTypes'

import { LowStockContainer } from '../misc'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .input {
    ${ZeroSpace}
  }
`

const DishContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column-reverse', tablet: 'column-reverse', desktop: 'row' })}
  ${ResponsiveProperty('align-items', { mobile: 'stretch', tablet: 'stretch', desktop: 'flex-start' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '22px', tablet: '24px', desktop: '32px' })}
  ${ResponsivePXValue('padding', { mobile: '0', tablet: '0', desktop: `0 ${theme.pagePadding.desktop}px` })}
`

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  ${ResponsiveProperty('padding', { mobile: '24px 16px 0 16px', tablet: '24px 16px 0 16px', desktop: '0 24px 0 0' })}

  .title-header {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '40px')}
  }

  .subtitle {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .text-description {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .price-header {
    font-weight: 700;
    ${ZeroSpace}
    ${ResponsivePXValue('font-size', { mobile: '16px', tablet: '16px', desktop: '20px' })}
    ${ResponsivePXValue('line-height', '24px')}
  }
`

const ImageContainer = styled.div`

  ${ProductCategoriesContainer}

  .product-categories {
    ${ResponsivePXValue('max-height', { mobile: '65%' })}
  }

  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '0', desktop: '16px' })}
  ${ResponsivePXValue('height', { mobile: '100vw', tablet: '50vw', desktop: '576px' })}
  ${ResponsivePXValue('width', { mobile: '100vw', tablet: '100vw', desktop: '576px' })}
  ${ResponsivePXValue('min-width', { mobile: '100vw', tablet: '100vw', desktop: '576px' })}
`

const AllergenContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

const RuleContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '24px', tablet: '28px', desktop: '36px' })}
  ${SidePagePadding}
`

const TagsContainer = styled.div`
  display: flex;
  align-self: stretch;
  overflow: auto;

  ${SidePagePadding}
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'space-between', desktop: 'space-around' })};

  .tag {
    justify-content: flex-start;
    ${ResponsivePXValue('min-width', '116px')}

    img {
      ${ResponsivePXValue('height', '24px')}
      ${ResponsivePXValue('width', '24px')}
      ${ResponsivePXValue('min-width', '24px')}
    }
  }
`

interface CraftPizzaDishDetailsFormData {
  quantity: number
  servingSize: FrozenPortionSizeEnum | null
}

export interface CraftPizzaDishDetailsProps {
  dish: FrozenMealDishDetailsFragment
  allergens: string
  servingSizeOptions: SelectOption[]
}

interface CraftPizzaDishDetailsState {
  loading: boolean
  disabled: boolean
  priceDisplay: string
  price: number
  selectedServingSize: FrozenPortionSizeEnum | null
  currentAmountInCart: number
  servingSize: FrozenPortionSizeEnum
  notAvailable: boolean
  buttonTitle: string
}

const DEFAULT_STATE: CraftPizzaDishDetailsState = {
  loading: false,
  disabled: true,
  priceDisplay: '',
  price: 0,
  selectedServingSize: null,
  currentAmountInCart: null,
  servingSize: FrozenPortionSizeEnum.SERVES_ONE,
  notAvailable: false,
  buttonTitle: 'ADD TO CART',
}

export const CraftPizzaDishDetails = React.memo(({ dish, allergens, servingSizeOptions }: CraftPizzaDishDetailsProps): JSX.Element => {

  const config = useConfig()
  const [state, setState] = useState<CraftPizzaDishDetailsState>({ ...DEFAULT_STATE })
  const [quantity, setQuantity] = useState<number>(1)
  const [addItemToCart] = useAddItemToCartMutation()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [form] = useForm()
  const theme = useTheme()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const { logAddToCart, logRemoveFromCart } = useAnalytics()
  const { addToast } = useToasts()
  const setLoading = (loading: boolean): void => {
    setState(prevState => ({ ...prevState, loading }))
  }
  const { data: userCartData, refetch } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const cartItems = userCartData?.currentUser?.activeCart?.cartItems
  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation({
    refetchQueries: SiteHelper.getUserRefetchQueries(),
    awaitRefetchQueries: true,
  })
  const [removeItemFromCart] = useRemoveItemFromCartMutation()

  const _handleAvailabilityCheck = (): boolean => {
    let available = true
    for (let i = 0; i < dish.products.length; i++) {
      if (dish.products[i].availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY) {
        available = false
        break
      }
    }
    if (!available) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
      return false
    } else {
      return true
    }
  }

  const _handleDefaultAddressCheck = (): boolean => {
    if (!userDetailsData?.currentUser?.hasDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
      return false
    } else {
      return true
    }
  }

  const _handleAddToCart = async (data: CraftPizzaDishDetailsFormData) => {
    if (!_handleDefaultAddressCheck() || !_handleAvailabilityCheck()) return
    setLoading(true)
    const productId = dish?.products?.find((product) => { return product.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })?.id

    try {
      await addItemToCart({
        variables: { productId, quantity },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })
      // Analytics
      logAddToCart({
        item_name: dish?.name + ' - ' + data.servingSize,
        item_id: dish?.id,
        price: state.price,
        item_brand: 'UCOOK',
        item_category: dish?.frozenCategories?.map((cat) => cat.id)?.join(', '),
        item_variant: dish?.frozenCategories?.map((cat) => cat.title)?.join(', '),
        item_list_name: 'Craft Pizza',
        quantity,
      })

      setQuantity(1)
      addToast(`Successfully added (${quantity}) item to cart.`, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setLoading(false)
  }

  const _handleOnAdd = async (): Promise<void> => {

    if (!_handleDefaultAddressCheck() || !_handleAvailabilityCheck()) return
    setLoading(true)
    const productId = dish?.products?.find((product) => { return product.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })?.id

    try {
      await addItemToCart({
        variables: { productId, quantity },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })
      // Analytics
      logAddToCart({
        item_name: dish?.name,
        item_id: dish?.id,
        price: state.price,
        item_brand: 'UCOOK',
        item_category: dish?.frozenCategories?.map((cat) => cat.id)?.join(', '),
        item_variant: dish?.frozenCategories?.map((cat) => cat.title)?.join(', '),
        item_list_name: 'Craft Pizza',
        quantity,
      })

      setQuantity(1)
      addToast(`Successfully added (${quantity}) item to cart.`, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setLoading(false)

  }

  const _handleOnRemove = async (): Promise<void> => {

    setLoading(true)
    const product = dish?.products?.find((product) => { return product.frozenPortionSize === state.servingSize })

    try {
      if (state.currentAmountInCart === 1) {
        await removeItemFromCart({
          variables: {
            productId: product.id,
          },
          refetchQueries: SiteHelper.getUserRefetchQueries(),
          awaitRefetchQueries: true,
        })
        setState((prevState) => ({ ...prevState, currentAmountInCart: 0 }))

      } else {

        await changeCartItemQuantity({
          variables: {
            productId: product.id,
            quantity: state.currentAmountInCart - 1,
          },
        })
      }

      // Analytics
      logRemoveFromCart({
        item_name: dish?.name,
        item_id: product?.id,
        price: product?.price,
        item_brand: 'UCOOK',
        item_category: dish?.frozenCategories?.map((cat) => cat.id)?.join(', '),
        item_variant: dish?.frozenCategories?.map((cat) => cat.title)?.join(', '),
        item_list_name: 'Craft Meals',
        quantity: 1,
      })

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setLoading(false)

  }

  const _handleOnCenterClick = async (): Promise<void> => {
    if (!(state.currentAmountInCart > 0)) {
      _handleOnAdd()
    }
  }

  useEffect(() => {
    const priceRange = getCraftMealPriceRange(dish)
    setState(prevState => ({
      ...prevState,
      priceDisplay: `R${priceRange.lower}`,
      disabled: false,
      selectedServingSize: FrozenPortionSizeEnum.SERVES_ONE,
    }))
    if (dish) {
      isCardItemInBasket()
      setButtonTitle()
    }
  }, [dish])

  let tag: FrozenTagFragment

  const setButtonTitle = () => {
    let notAvailable = false
    let buttonTitle = 'ADD TO CART'
    let craftPizzaProduct: FrozenMealListFragment

    for (let i = 0; i < dish?.products.length; i++) {
      if (dish?.products[i].frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE) {
        craftPizzaProduct = dish?.products[i]
      }
    }

    if (craftPizzaProduct?.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK) {
      notAvailable = true
      buttonTitle = 'OUT OF STOCK'
    } else if (craftPizzaProduct?.availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE) {
      buttonTitle = 'GET STARTED'
    } else if (craftPizzaProduct?.availability !== ProductAvailabilityEnum.AVAILABLE) {
      notAvailable = true
      buttonTitle = 'NOT AVAILABLE'
    }
    setState((prevState) => update(prevState, {
      notAvailable: { $set: notAvailable },
      buttonTitle: { $set: buttonTitle },
    }))
  }

  const productImagesArray = dish?.productImages as unknown as ProductImageInterface[]

  const isCardItemInBasket = () => {

    const productId = dish?.products?.find((product) => { return product.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })?.id

    const quantity = cartItems?.find((item) => item?.product?.id === productId)?.quantity || 0

    setState((prevState) => update(prevState, {
      currentAmountInCart: { $set: quantity },
    }))

  }

  useEffect(() => {
    refetch()
    isCardItemInBasket()
    setButtonTitle()
  }, [userCartData])

  const product = dish?.products?.find((product) => { return product.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })

  return (
    <Form
      form={form}
      disabled={state.loading || servingSizeOptions?.length === 0}
      onFinish={_handleAddToCart}>
      <Container>
        <DishContainer>
          <ContentContainer>
            <Heading variant='h3' color={theme.colors.greys.liteCodGrey} className='title-header'>
              {dish?.name}
            </Heading>
            <Spacer universal='8px' />
            <Paragraph variant='p1' color={theme.colors.greys.darkCodGrey} className='text-description'>
              {dish?.description}
            </Paragraph>
            <Spacer universal='16px' />
            <LowStockContainer
              displayRule
              product={product}
              flexDirection={isMobile ? 'row' : 'column'}
              alignItems={isMobile ? 'center' : 'flex-start'}>
              <Heading variant='h4' color={theme.colors.greys.darkCodGrey} className='price-header'>
                {state.priceDisplay}
              </Heading>
            </LowStockContainer>
            <Spacer universal='24px' />
            <Button
              className='add-button'
              title={state.buttonTitle}
              color='black'
              fullWidth
              loading={state.loading}
              disabled={state.loading || state.notAvailable}
              amount={state.currentAmountInCart}
              onClick={_handleOnCenterClick}
              onLeftIconClick={_handleOnRemove}
              onRightIconClick={_handleOnAdd} />
            <Spacer universal='16px' />
            <AllergenContainer>
              <Paragraph variant='p2' color={theme.colors.slates.bitter}>Allergens: {allergens}</Paragraph>
            </AllergenContainer>
          </ContentContainer>
          <ImageContainer id='craftMealDetailsImageContainer'>
            <If condition={dish?.upSellText?.length > 0}>
              <FloatingUpSell text={dish.upSellText} />
            </If>
            <ProductCategories
              product={dish}
              iconSize='large' />
            <MultipleImageContainer images={productImagesArray} />
          </ImageContainer>
        </DishContainer>
        <RuleContainer>
          <Rule color='slate' />
        </RuleContainer>
        <TagsContainer>
          <For each='tag' of={dish?.frozenTags || []}>
            <CategoryTag
              image={tag.icon.desktop}
              title={tag?.title}
              key={tag.id}
              showBackground={false}
              className='tag' />
          </For>
        </TagsContainer>
      </Container >
    </Form>
  )
})

CraftPizzaDishDetails.displayName = 'CraftPizzaDishDetails'
