import React, { useEffect, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import { ExecutionResult } from 'graphql'
import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { CheckoutPlugin } from '@api/local'
import { Link, Spacer } from '@atoms/index'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { scrollToSelector } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { UserAddressFragment, useUserDetailsQuery, useUserCartQuery, UserDetailsDocument, UserCartDocument, SaveUserMenuMutation, GetUnavailableProductsQuery, useRemoveItemFromCartMutation, useRemoveUserAddressMutation, useSaveUserMenuMutation, useUpdateUserAddressMutation, RegisteredUserDetailsFragment, UserDetailsFragment, useGetUnavailableProductsLazyQuery, useClearCacheKeysMutation } from '@hooks/api'
import { SectionLoadingOverlay, UserAddressListItem, Notification } from '@molecules/index'
import { ConfirmDeleteModal, AddressModal, AddressChangeProductWarningModal } from '@organisms/modals'

const DeliveryContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  .confirm-notification {
    flex: 1;
    text-align: center;
  }
`
const Container = styled.div<{$opacity:string}>`
  display: flex;
  flex-direction: column;
  position: relative;
  opacity:${props => props.$opacity === '1' ? '1' : '0.45'};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${ResponsivePXValue('width', '100%')}

  .button {
    ${ResponsivePXValue('padding', '28px')}
  }
`

interface UserAddressListState {
  loading: boolean
  userAddresses: UserAddressFragment[]
  addressToBeDeleted: UserAddressFragment | null
  addressToBeEdited: UserAddressFragment | null
  addressModalOpen: boolean
  confirmDeleteModalOpen: boolean
  isGuestUser: boolean
  showWarningModal: boolean
  newAddressId: string
  conflictingItemsIds: string[]
  conflictingItems: GetUnavailableProductsQuery
}

const DEFAULT_STATE: UserAddressListState = {
  loading: false,
  userAddresses: [],
  addressToBeDeleted: null,
  addressToBeEdited: null,
  addressModalOpen: false,
  confirmDeleteModalOpen: false,
  isGuestUser: false,
  showWarningModal: false,
  newAddressId: null,
  conflictingItemsIds: [],
  conflictingItems: null,
}

interface UserAddressListProps {
  displayConfirmation?: boolean
}

export function UserAddressList({ displayConfirmation = false }: UserAddressListProps): JSX.Element {

  const config = useConfig()
  const { data, loading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const [saveMenu] = useSaveUserMenuMutation()
  const { data: cartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })

  const [state, setState] = useState<UserAddressListState>({ ...DEFAULT_STATE })
  const [removeUserAddress] = useRemoveUserAddressMutation()
  const [updateUserAddress] = useUpdateUserAddressMutation()
  const { addToast } = useToasts()
  const [removeItemFromCart] = useRemoveItemFromCartMutation()
  const [getUnavailableProducts, { data: conflictingItems }] = useGetUnavailableProductsLazyQuery()
  const [clearCacheKey, { loading: clearCacheKeyLoading }] = useClearCacheKeysMutation()

  const menuId = cartData?.currentUser?.activeMenu?.id
  // Function to move the selected address to the top
  const sortAddresses = (a: UserAddressFragment, b: UserAddressFragment) => {
    if (a?.isDefault && !b?.isDefault) return -1
    if (!a?.isDefault && b?.isDefault) return 1
    return 0
  }

  const processNewAddress = async (): Promise<void> => {
    try {
      const { data: conflictingItemsList } = await getUnavailableProducts({
        variables: {
          userAddressId: state.newAddressId,
        },
      })

      const hasUnavailableItems = conflictingItemsList?.unavailableProducts?.length > 0

      const conflictingItems = conflictingItemsList
      const conflictingItemsIds: string[] = []
      for (let i = 0; i < conflictingItems?.unavailableProducts.length; i++) {
        conflictingItemsIds.push(conflictingItems.unavailableProducts[i].id)
      }

      if (hasUnavailableItems) {
        setState((prevState) => update(prevState, {
          showWarningModal: { $set: true },
          conflictingItemsIds: { $set: conflictingItemsIds },
          conflictingItems: { $set: conflictingItemsList },
        }))
      } else {
        await _handleChangeDefaultAddress(state.newAddressId)
      }

    } catch (e) {
      // console.log(e)
    } finally {
      setState((prevState) => ({ ...prevState, loading: false }))
    }
  }

  const _handlePopup = (): void => {
    setState((prevState) => update(prevState, {
      addressToBeEdited: { $set: data?.currentUser?.addresses.find((item) => { return item.isDefault }) },
      addressModalOpen: { $set: true },
    }))
  }
  const _handleAddNew = (): void => {
    setState((prevState) => update(prevState, {
      addressModalOpen: { $set: true },
    }))
  }

  const _handleAddressModalClose = (): void => {
    setState((prevState) => update(prevState, {
      addressToBeEdited: { $set: null },
      addressModalOpen: { $set: false },
    }))
  }

  const _handleDeleteModalClose = (): void => {
    setState((prevState) => update(prevState, {
      confirmDeleteModalOpen: { $set: false },
    }))
  }

  const _handleDeleteAddress = (address: UserAddressFragment): void => {
    setState((prevState) => update(prevState, {
      confirmDeleteModalOpen: { $set: true },
      addressToBeDeleted: { $set: address },
    }))
  }

  const _deleteAddress = (): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: true },
      confirmDeleteModalOpen: { $set: false },
    }))
    if (state.addressToBeDeleted) {
      const id = state.addressToBeDeleted.id
      try {
        removeUserAddress({
          variables: {
            id,
          },
          refetchQueries: [{ query: UserDetailsDocument }, { query: UserCartDocument }],
          awaitRefetchQueries: true,
        })
        addToast('Address successfully deleted!', {
          appearance: 'success',
          autoDismiss: true,
        })
      } catch (e) {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
    } else {
      addToast('Address not found!', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setState((prevState) => update(prevState, {
      loading: { $set: false },
      addressToBeDeleted: { $set: null },
    }))
  }

  const getUpdateAddressRefetchQueries = () => state.isGuestUser ? SiteHelper.getCheckoutRefetchQueries() : SiteHelper.getUserRefetchQueries()

  const _handleMakeDefault = async (id: string): Promise<void> => {
    const address = data?.currentUser?.addresses?.find((address) => address.id === id)
    if (address.isIncomplete) {
      addToast('You cannot set an incomplete address as your default address. Please edit and complete the address before setting as default address', {
        appearance: 'warning',
        autoDismiss: true,
      })
      return
    }
    setState((prevState) => update(prevState, {
      loading: { $set: true },
      newAddressId: { $set: id },
    }))

  }

  const _handleChangeDefaultAddress = async (id: string): Promise<void> => {

    await clearCacheKey({
      variables: {
        cacheKeys: [{ type: 'User', id: cartData?.currentUser?.id }],
      },
    })

    try {
      await updateUserAddress({
        variables: {
          id,
          input: {
            isDefault: true,
          },
        },
        refetchQueries: getUpdateAddressRefetchQueries(),
        awaitRefetchQueries: true,
      })

      addToast('Default address successfully updated!', {
        appearance: 'success',
        autoDismiss: true,
      })

    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const _onCancel = () => {
    setState((prevState) => update(prevState, {
      loading: { $set: false },
      showWarningModal: { $set: false },
    }))
  }

  const _handleWarningModalClose = () => {
    setState((prevState) => update(prevState, {
      loading: { $set: true },
    }))
    _handleRemoveConflicingItemsFromCart(state.conflictingItemsIds)
    _handleChangeDefaultAddress(state.newAddressId)
    _saveUserMenu(menuId)
    setState((prevState) => update(prevState, {
      loading: { $set: false },
      showWarningModal: { $set: false },
    }))
  }

  const _saveUserMenu = async (id: string): Promise<void> => {

    setState((prevState) => update(prevState, {
      loading: { $set: true },
    }))

    try {
      const result: ExecutionResult<SaveUserMenuMutation> = await saveMenu({
        variables: {
          id,
        },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })

      CheckoutPlugin.shared().setCartErrors(client, [...result.data.userMenuSave.errors])

      addToast('Your cart has been saved!', {
        appearance: 'success',
        autoDismiss: true,
      })

    } catch (e) {
      addToast(e.message, {
        appearance: 'warning',
        autoDismiss: true,
      })
    }

    setState((prevState) => update(prevState, {
      loading: { $set: false },
    }))

  }
  const _handleRemoveConflicingItemsFromCart = (conflictingItemsIds: string[]) => {

    for (let i = 0; i < conflictingItemsIds.length; i++) {
      try {
        removeItemFromCart({
          variables: {
            productId: conflictingItemsIds[i],
          },
          refetchQueries: SiteHelper.getUserRefetchQueries(),
          awaitRefetchQueries: true,
        })
      } catch (err) {
        // console.log(err)
      }
    }

  }

  useEffect(() => {
    if (data?.currentUser?.addresses[0]?.isIncomplete) {
      _handlePopup()
    }

    if (data?.currentUser) {
      const registeredUser = data?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
      const isGuestUser = data?.currentUser?.__typename === 'GuestUser'
      const userAddresses: UserAddressFragment[] = registeredUser?.addresses as UserAddressFragment[] || []

      // Sort the addresses array based on the selected object
      const sortedAddresses = [...userAddresses].sort(sortAddresses)

      setState((prevState) => update(prevState, {
        userAddresses: { $set: sortedAddresses },
        isGuestUser: { $set: isGuestUser },
        conflictingItems: { $set: conflictingItems },
      }))
    }
  }, [data?.currentUser?.addresses])

  useEffect(() => {
    if (displayConfirmation) {
      setTimeout(() => {
        scrollToSelector('#streetAddressContainer')
      }, 1000)
    }
  }, [displayConfirmation])

  useEffect(() => {
    if (state.newAddressId) {
      processNewAddress()
    }
  }, [state.newAddressId])

  let userAddress: UserAddressFragment

  const _shouldExpandAddress = (userAddress: UserAddressFragment): boolean => displayConfirmation && userAddress.isDefault

  return (
    <DeliveryContainer>
      <ConfirmDeleteModal
        open={state.confirmDeleteModalOpen}
        title='Delete address'
        action='ADDRESS'
        content='You are about to delete your address! Are your sure you want to proceed?'
        onDelete={_deleteAddress}
        onClose={_handleDeleteModalClose} />
      <AddressChangeProductWarningModal
        open={state.showWarningModal}
        title='You are about to change your address'
        content={state.conflictingItems}
        onCancel={_onCancel}
        onConfirm={_handleWarningModalClose} />
      <If condition={displayConfirmation}>
        <Spacer universal='16px' />
        <Notification
          textClassName='confirm-notification'
          text='Please confirm your address'
          backgroundColor={theme.colors.yellows.sunglow} />
        <Spacer universal='16px' />
      </If>
      <Container $opacity={(state.loading || loading) ? '0.45' : '1'}>
        <ButtonContainer>
          <Link
            color={theme.colors.oranges.coral}
            className='button'
            onClick={_handleAddNew}>
            ADD A NEW ADDRESS
          </Link>
        </ButtonContainer>
        <If condition={state.addressModalOpen}>
          <AddressModal onClose={_handleAddressModalClose} />
        </If>
        <For each='userAddress' of={state.userAddresses}>
          <UserAddressListItem
            key={userAddress.id}
            userAddress={userAddress}
            loading={state.loading}
            expanded={_shouldExpandAddress(userAddress)}
            onDelete={() => _handleDeleteAddress(userAddress)}
            onMakeDefault={() => _handleMakeDefault(userAddress.id)} />
        </For>
      </Container>
      <If condition={state.loading || loading} >
        <SectionLoadingOverlay/>
      </If>
    </DeliveryContainer>
  )
}
