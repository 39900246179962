import React, { useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { CartPlugin } from '@api/local/CartPlugin'
import { IconEnum, ResponsiveImage, SmallLoader, Paragraph, Spacer, Button } from '@client/components/atoms'
import { Ellipsis, ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { useAnalytics } from '@client/hooks/UseAnalytics'
import { SiteHelper } from '@client/lib/SiteHelper'
import { CartItemFragment, useRemoveItemFromCartMutation, useChangeCartItemQuantityMutation } from '@hooks/api'
import { Counter, CounterVariantEnum, StickyHealthyDining, StickyHealthyDiningEnum } from '@molecules/index'
import { ProductAvailabilityEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  max-width: 100%;
  ${ResponsivePXValue('padding', '8px 16px')}

  .text {
    ${ResponsivePXValue('width', { mobile: 'CALC(100vw - 96px)', tablet: '260px', desktop: '260px' })}
    ${Ellipsis()}
    line-height: 1.4;
  }

  .dark {
    font-weight: 500;
  }

  .error-text {
    ${ResponsivePXValue('width', { mobile: 'CALC(100vw - 140px)', tablet: '260px', desktop: '260px' })}
    ${Ellipsis()}
    line-height: 1.2;
    font-weight: 700;
  }
`

const ImageContainer = styled.div`
  position: relative;
  ${ResponsivePXValue('width', { mobile: '48px', tablet: '48px', desktop: '80px' })}
  ${ResponsivePXValue('height', { mobile: '48px', tablet: '48px', desktop: '80px' })}
  ${ResponsivePXValue('border-radius', '8px')}
  overflow: hidden;
  background-color: ${theme.colors.whites.desertStorm};
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .price-text {
    ${ZeroSpace}
    font-weight: 500;
    line-height: 1;
  }
`

const CounterContainer = styled.div`
  .counter {
    ${ResponsivePXValue('width', '96px')}
  }
`

const IconContainer = styled.div`
  ${ResponsivePXValue('width', '12px')}
  ${ResponsivePXValue('height', '12px')}

  .remove-icon-button {
    ${ZeroSpace}
  }
`

const OverlayContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('height', '96px')}
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const LoaderBox = styled.div`
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
`
const LinkContainer = styled.a`
  cursor: pointer;
`

export interface AddOnCartItemProps {
  item: CartItemFragment
  subtitle: string
  product: CartItemFragment['product']
  userIsOnDemand: boolean
  showVitalityTag?: boolean
}

interface AddOnCartItemState {
  loading: boolean
}

const DEFAULT_STATE: AddOnCartItemState = {
  loading: false,
}

export function AddOnCartItem({ item, subtitle, product, userIsOnDemand, showVitalityTag = false }: AddOnCartItemProps): JSX.Element {

  const [state, setState] = useState<AddOnCartItemState>({ ...DEFAULT_STATE })
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const { logAddToCart } = useAnalytics()
  const [removeItemFromCart] = useRemoveItemFromCartMutation({
    refetchQueries: SiteHelper.getUserRefetchQueries(),
    awaitRefetchQueries: true,
  })
  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation({
    refetchQueries: SiteHelper.getUserRefetchQueries(),
    awaitRefetchQueries: true,
  })

  const _handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    const type = product.__typename

    let url = ''
    switch (type) {
      case 'Wine':
        url = `/wine/${product.slug}`
        break
      case 'FrozenMeal':
        url = `/frozen/craft-meal/${product.group.slug}`
        break
      case 'MealKit':
        url = `/meal-kit/${product.group.slug}`
        break
      case 'MarketProduct':
        url = `/market/${product.slug}`
        break
    }
    navigate(url)
    CartPlugin.shared().close()
  }
  const gaAddToCart = (quantity: number): void => {
    // Analytics
    let logVariant = ''
    let category = ''
    switch (product.__typename) {
      case 'Wine':
        category = 'Wines'
        logVariant = product?.wineCategory?.title
        break
      case 'FrozenMeal':
        category = 'Craft Meals'
        logVariant = product?.frozenCategories?.map((cat) => cat.title).join(', ')
        break
      case 'MarketProduct':
        category = 'Market Products'
        logVariant = product?.marketProductCategories?.map((cat) => cat.title).join(', ')
        break
    }

    logAddToCart({
      item_name: product.name,
      item_id: product.id,
      price: item?.price,
      item_brand: 'UCOOK',
      item_category: category,
      item_variant: logVariant,
      item_list_name: 'Cart',
      quantity,
    })
  }

  const _handleQuantityChange = async (quantity: number): Promise<void> => {
    try {
      _toggleLoading(true)
      await changeCartItemQuantity({
        variables: {
          productId: item.product.id,
          quantity,
        },
      })
      if (quantity > item.quantity) {
        gaAddToCart(1)
      }
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _toggleLoading(false)
  }

  const _handleRemove = async (): Promise<void> => {
    try {
      _toggleLoading(true)
      // const amount = item.quantity
      await removeItemFromCart({
        variables: {
          productId: item.product.id,
        },
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const _toggleLoading = (state: boolean): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: state },
    }))
  }

  let isLowStock = false
  let isOutOfStock = false
  let maxAmount = 0
  let availableAmount = 0

  if (userIsOnDemand) {
    maxAmount = item.product.stockCount
    availableAmount = item.product.stockCount
  } else {
    maxAmount = item.product.stockCount + item.quantity
    availableAmount = item.product.stockCount + item.quantity
  }
  if (maxAmount <= 0) {
    isOutOfStock = true
  } else if (maxAmount < item.quantity) {
    isLowStock = true
  }
  const notGeographicallyAvailable = item?.product?.availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY
  return (
    <Container>
      <LinkContainer onClick={_handleLinkClick}>
        <ImageContainer>
          <If condition={showVitalityTag}>
            <StickyHealthyDining type={StickyHealthyDiningEnum.SMALL} />
          </If>
          <ResponsiveImage image={item.product.coverImage} />
        </ImageContainer>
      </LinkContainer>

      <Spacer universal='8px' variant='horizontal' />
      <BodyContainer>
        <Spacer universal='4px' />
        <LinkContainer onClick={_handleLinkClick}>
          <Paragraph variant='p2' className='text dark'>{item.product.name.split(' - ')[0]}</Paragraph>
          <Spacer universal='12px' />
          <Choose>
            <When condition={notGeographicallyAvailable}>
              <Paragraph variant='p3' color={theme.colors.misc.error} className='error-text'>{'NOT GEOGRAPHICALLY AVAILABLE'}</Paragraph>
            </When>
            <When condition={isOutOfStock}>
              <Paragraph variant='p3' color={theme.colors.misc.error} className='error-text'>{'NO STOCK'}</Paragraph>
            </When>
            <When condition={isLowStock}>
              <Paragraph variant='p3' color={theme.colors.misc.error} className='error-text'>{`ONLY ${availableAmount} AVAILABLE`}</Paragraph>
            </When>
            <Otherwise>
              <Paragraph variant='p3' className='text'>{subtitle}</Paragraph>
            </Otherwise>
          </Choose>
        </LinkContainer>
        <Spacer universal='8px' />
        <FooterContainer>
          <CounterContainer>
            <Counter variant={CounterVariantEnum.SMALL} className='counter' minimum={1} amount={item.quantity} onChange={_handleQuantityChange} />
          </CounterContainer>
          <Paragraph variant='p2' className='price-text'>{`R${item.price}`}</Paragraph>
        </FooterContainer>
      </BodyContainer>

      <Spacer universal='8px' />
      <IconContainer>
        <Button
          color='transparent'
          icon={IconEnum.CLOSE_OUTLINE}
          iconColor={theme.colors.greys.darkCodGrey}
          size='small'
          onClick={_handleRemove} />
      </IconContainer>
      <If condition={state.loading}>
        <OverlayContainer>
          <LoaderBox>
            <SmallLoader color={theme.colors.oranges.coral} />
          </LoaderBox>
        </OverlayContainer>
      </If>
    </Container>
  )
}
