import React, { Fragment, useEffect, useRef, useState } from 'react'

import update from 'react-addons-update'
import OneSignal from 'react-onesignal'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { CartPlugin } from '@api/local/CartPlugin'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { NAVIGATION_DEFAULT_STATE, NavigationPlugin } from '@api/local/NavigationPlugin'
import { IconEnum, ResponsivePXValue, SmallLoader, Link, Button, Spacer } from '@client/components'
import { Icon } from '@client/components/atoms'
import { NavigationBar } from '@client/components/molecules'
import { NavigationList } from '@client/components/molecules/navigation/NavigationList'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { defaultNavigation, defaultUserSubNav } from '@client/Navigation'
import { SubNavItemFragment, useGetAppQuery, useGetNavigationQuery, useUserDetailsQuery, useUserCartQuery, NavItemFragment, useUserLogoutMutation } from '@hooks/api'

const ItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.small-loader {
    ${ResponsivePXValue('height', '32px')}
  }
`

const UserItemContainer = styled(ItemContainer)`
  ${ResponsivePXValue('min-width', '100px')}

  .user-name {
    font-weight: 900;
  }
`

const SubMenuContainer = styled.div`
  position: absolute;
  ${ResponsivePXValue('top', '54px')}
`

const UserButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const UserIcon = styled.div`
  ${ResponsivePXValue('height', '20px')}
  ${ResponsivePXValue('width', '20px')}
`

const ItemSeperator = styled.div`
  ${ResponsivePXValue('width', '1px')}
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('margin', '0 16px')}
  background-color: ${(props): string => props.theme.colors.whites.desertStorm};
`

const Count = styled.div<{ $count: number }>`

  background-color: ${(props): string => props.$count === 0 ? props.theme.colors.greys.mineshaft : props.theme.colors.oranges.burntSienna};
  color: ${(props): string => props.theme.colors.whites.pureWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: gordita;
  border-radius: 4px;

  ${ResponsivePXValue('font-size', '10px')}
  ${ResponsivePXValue('padding', '8px 6px 4px 6px')}
`

interface DesktopNavigationBarState {
  userMenuOpen: boolean
  userNav: SubNavItemFragment[]
}

const DEFAULT_STATE = {
  userMenuOpen: false,
  userNav: [...defaultUserSubNav],
}

export function DesktopNavigationBar(): JSX.Element {

  const config = useConfig()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: navigationData = { navigation: { ...NAVIGATION_DEFAULT_STATE } } } = useGetNavigationQuery()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: cartData, loading: cartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [logOut, { loading: logoutLoading }] = useUserLogoutMutation()
  const [state, setState] = useState<DesktopNavigationBarState>({ ...DEFAULT_STATE })
  const subNavRef: React.RefObject<HTMLDivElement> = useRef()
  const navigate = useNavigate()
  const theme = useTheme()
  const discountCode = appData?.app?.urlDiscount

  const _handleSubNavClicked = async (id: string): Promise<void> => {
    setState((prevState) => update(prevState, {
      userMenuOpen: { $set: false },
    }))
    if (id === 'LOG_OUT') {
      OneSignal.logout()
      await logOut({
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })

      if (NavigationPlugin.shared().isNavUserSubNav(id)) {
        NavigationPlugin.shared().setNavigation(defaultNavigation, false)
        navigate('/')
      }
    } else {
      if (!navigationData.navigation.testing) {
        navigate(state.userNav.find((item) => item.id === id).href)
      } else {
        NavigationPlugin.shared().setPath(state.userNav.find((item) => item.id === id).href)
      }
    }
  }

  const _handleToggleUserMenu = (): void => {
    setState((prevState) => ({ ...prevState, userMenuOpen: !prevState.userMenuOpen }))
  }

  const _handleSignUp = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SIGN_UP)
  }

  const _handleLogIn = (): void => {
    ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
  }

  const _handleToggleCart = (): void => {
    CartPlugin.shared().open()
  }

  const _handlePageClick = (e: MouseEvent): void => {
    if (subNavRef.current && !subNavRef.current.contains(e.target as Element)) {
      setState((prevState) => ({ ...prevState, userMenuOpen: false }))
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', _handlePageClick)
    return () => document.addEventListener('mousedown', _handlePageClick)
  })

  useEffect(() => {

    setState((prevState) => update(prevState, {
      userNav: {
        $apply: (navItems: SubNavItemFragment[]) => navItems.map((navItem) => update(navItem, {
          active: { $set: navItem.path === (navItem.path !== null && navigationData?.navigation?.path) },
        })),
      },
    }))
  }, [navigationData?.navigation?.path])

  useEffect(() => {
    if (discountCode && discountCode?.length) {
      _handleToggleCart()
    }
  }, [appData?.app?.urlDiscount])

  const cartItemCount = cartData?.currentUser?.activeCart?.totalItems ?? 0
  const cartIconColor = cartItemCount === 0 ? theme.colors.oranges.burntSienna : theme.colors.greys.liteCodGrey

  return (
    <NavigationBar
      items={(navigationData?.navigation?.items ?? []) as NavItemFragment[]}>
      <Choose>
        <When condition={userDetailsLoading || cartLoading || logoutLoading}>
          <ItemContainer className='small-loader'>
            <SmallLoader color={theme.colors.oranges.coral} />
          </ItemContainer>
        </When>
        <Otherwise>
          <Fragment>
            <Choose>
              <When condition={userDetailsData?.currentUser?.__typename === 'RegisteredUser'}>
                <UserItemContainer>
                  <UserButton onClick={_handleToggleUserMenu}>
                    <Link
                      color={theme.colors.greys.darkCodGrey}
                      hoverColor={theme.colors.oranges.coral}
                      className='user-name'>
                      {userDetailsData.currentUser.firstName}
                    </Link>
                    <UserIcon>
                      <Icon icon={IconEnum.CHEVRON_DOWN_OUTLINE} color={theme.colors.greys.darkCodGrey} />
                    </UserIcon>
                  </UserButton>
                  <If condition={state.userMenuOpen}>
                    <SubMenuContainer ref={subNavRef}>
                      <NavigationList
                        items={state.userNav}
                        onNavigate={(id: string) => _handleSubNavClicked(id)} />
                    </SubMenuContainer>
                  </If>
                </UserItemContainer>
              </When>
              <Otherwise>
                <ItemContainer>
                  <Link
                    onClick={_handleLogIn}
                    color={theme.colors.oranges.burntSienna}
                    bold>
                    Login
                  </Link>
                </ItemContainer>
                <Spacer universal='12px' variant='horizontal' />
                <ItemContainer>
                  <Button
                    onClick={_handleSignUp}
                    color='orange'
                    title='Sign up' />
                </ItemContainer>
              </Otherwise>
            </Choose>
            <ItemSeperator />
            <ItemContainer
              onClick={_handleToggleCart}>
              <Button
                color='transparent'
                icon={IconEnum.CART}
                iconColor={cartIconColor} />
              <Spacer universal='4px' variant='horizontal' />
              <Count $count={cartItemCount}>
                {cartItemCount}
              </Count>
            </ItemContainer>
          </Fragment>
        </Otherwise>
      </Choose>
    </NavigationBar>
  )
}
