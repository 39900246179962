import React from 'react'

import qs from 'qs'
import styled from 'styled-components'

import { Spacer } from '@client/components/atoms'
import { Aisles, WineCard } from '@client/components/molecules'
import { AislesBaseContainer } from '@client/components/Theme'
import { WineListFragment, PageDataSectionFragment } from '@hooks/api'

const AislesContainer = styled.div`
  ${AislesBaseContainer}
`

interface PageDataFilteredWineProps {
  pageSection: PageDataSectionFragment
}

export function PageDataFilteredWine({ pageSection }: PageDataFilteredWineProps): JSX.Element {

  const selectedFilters = { wineCategories: pageSection?.wineCategories?.map(wineCategory => wineCategory.id), wineTags: pageSection?.wineTags?.map(wineTag => wineTag.id) }
  const newQueryString = qs.stringify({ filters: selectedFilters })
  const viewAllUrl = `/wine/craft-meal?${newQueryString}`

  let wine: WineListFragment

  return (
    <AislesContainer>
      <Aisles title={pageSection?.title} viewAllUrl={viewAllUrl} description={pageSection?.description} className='aisles-filtered-data'>
        <For each='wine' of={pageSection?.wines || []}>
          <WineCard
            className='product-card'
            key={wine.id}
            wine={wine}/>
        </For>
      </Aisles>
      <Spacer universal='16px' />
    </AislesContainer>
  )

}
