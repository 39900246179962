import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Loader, Spacer } from '@client/components/atoms'
import { Aisles, MarketProductCard } from '@client/components/molecules'
import { AislesBaseContainer, AislesLoadingContainer } from '@client/components/Theme'
import { renderMiniProductGrids } from '@client/components/utility/RenderMiniCardGrids'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useAnalytics } from '@client/hooks/UseAnalytics'
import { MarketProductCategoryAislesFragment, useUserDetailsQuery, useGetMarketAislesQuery, useMyShopMarketQuery, MarketProductListFragment } from '@hooks/api'
import { MarketProductCategoryFilters, MarketProductCategoryOrderEnum, MarketProductCategoryStatusEnum, MarketProductFilters, OrderDirectionEnum, ProductRangeEnum } from '@uctypes/api/globalTypes'
import { Mutable } from '@uctypes/global'

const AislesContainer = styled.div`
  ${AislesBaseContainer}
`
const LoadingContainer = styled.div`
  ${AislesLoadingContainer}
`

export interface MarketAisleCarouselProps {
  onDataLoaded?: (hasMyShopItems: boolean, marketProductCategories: MarketProductCategoryAislesFragment[]) => void
  isMiniCard?: boolean
  slidesToScroll?: number
  aislesMaxWidth?: string
  urlSuffix?: string
  dataLimit?: number
  fadeThresholdLimit?: number
}

interface MarketAisleCarouselState {
  hasLoggedImpression: boolean
}

const DEFAULT_STATE: MarketAisleCarouselState = {
  hasLoggedImpression: false,
}

export const MarketAislesCarousel = React.memo(({ onDataLoaded, isMiniCard, slidesToScroll = 1, aislesMaxWidth, urlSuffix = '', dataLimit = 20, fadeThresholdLimit }: MarketAisleCarouselProps): JSX.Element => {

  const config = useConfig()
  const { logProductListImpression } = useAnalytics()
  const [state, setState] = useState<MarketAisleCarouselState>({ ...DEFAULT_STATE })

  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const cityId = userDetailsData?.currentUser?.addresses?.find((address) => address.isDefault)?.location?.city?.id
  const filters: Mutable<MarketProductCategoryFilters> = {
    status: MarketProductCategoryStatusEnum.ACTIVE,
  }

  const marketFilters: Mutable<MarketProductFilters> = {
    cities: cityId ? [cityId] : undefined,
  }

  const { data, loading: aislesLoading, refetch: refetchMarketData } = useGetMarketAislesQuery({
    variables: {
      limit: dataLimit,
      filters,
      marketFilters,
      order: [{
        field: MarketProductCategoryOrderEnum.DISPLAY_INDEX,
        direction: OrderDirectionEnum.ASC,
      }],
    },
  })
  const marketProductCategories = data?.marketProductCategories?.list || []

  const { data: myShopAisleData, loading: myShopLoading, refetch: refetchMyShopData } = useMyShopMarketQuery({
    variables: {
      limit: dataLimit,
      skip: 0,
      userId: userDetailsData?.currentUser?.id,
      input: {
        productType: ProductRangeEnum.MARKET_PRODUCT,
      },
    },
    skip: !userDetailsData?.currentUser,
  })
  const myShopMarketList = myShopAisleData?.myShopItems?.list || []
  const hasMyShopItems = myShopMarketList?.length > 0
  const loading = aislesLoading || userDetailsLoading || myShopLoading

  const displayFade = (list: unknown[]) => fadeThresholdLimit ? list?.length > fadeThresholdLimit : false

  let marketProductCategory: MarketProductCategoryAislesFragment
  let marketProduct: MarketProductListFragment

  useEffect(() => {
    refetchMarketData()
    refetchMyShopData()
  }, [userDetailsData?.currentUser?.isOnDemand])

  useEffect(() => {

    if (!loading && !!data?.marketProductCategories?.list?.length && !state.hasLoggedImpression) {

      const marketAisles = data?.marketProductCategories?.list
      for (let i = 0; i < marketAisles?.length; i++) {
        const allDisplayedMarketItems = marketAisles[i].marketProducts
        logProductListImpression(
          allDisplayedMarketItems?.map((item, displayIndex) => {
            const marketProduct = item as MarketProductListFragment
            return {
              item_name: marketProduct?.name,
              item_id: marketProduct?.id,
              price: marketProduct?.price,
              item_brand: 'UCOOK',
              item_category:  marketProduct?.marketProductCategories?.map((cat) => cat.id)?.join(', '),
              item_variant: marketProduct?.marketProductCategories[0]?.title,
              item_list_name: marketProduct?.marketProductCategories[0]?.title,
              index: displayIndex,
            }
          }),
          userDetailsData?.currentUser?.id)
      }

      if (hasMyShopItems) {
        logProductListImpression(
          myShopAisleData?.myShopItems?.list?.filter(item => item.__typename === 'MarketProduct')?.map((item, displayIndex) => {
            const marketProduct = item as unknown as MarketProductListFragment
            return {
              item_name: marketProduct?.name,
              item_id: marketProduct?.id,
              price: marketProduct?.price,
              item_brand: marketProduct?.brand?.name,
              item_category:  marketProduct?.marketProductCategories?.map((cat) => cat.id)?.join(', '),
              item_variant: marketProduct?.marketProductCategories[0]?.title,
              item_list_name: 'My Shop Market Product',
              index: displayIndex,
            }
          }),
          userDetailsData?.currentUser?.id)
      }
      setState((prevState) => update(prevState, {
        hasLoggedImpression: { $set: true },
      }))
    }
  }, [data])

  useEffect(() => {
    if (!!onDataLoaded && !loading) {
      onDataLoaded(hasMyShopItems, marketProductCategories as MarketProductCategoryAislesFragment[])
    }
  }, [loading])

  return (
    <Choose>
      <When condition={loading}>
        <LoadingContainer>
          <Loader noShadow />
        </LoadingContainer>
      </When>
      <Otherwise>
        <AislesContainer>
          <If condition={hasMyShopItems}>
            <Aisles
              title='Bought Before'
              viewAllUrl={`/market/store/bought-before${urlSuffix}`}
              className='aisles'
              isMiniCard={isMiniCard}
              slidesToScroll={slidesToScroll}
              aislesMaxWidth={aislesMaxWidth}
              displayFade={displayFade(myShopMarketList)}>
              <Choose >
                <When condition={isMiniCard}>
                  {renderMiniProductGrids(myShopMarketList as MarketProductListFragment[])}
                </When>
                <Otherwise>
                  <For each='marketProduct' of={myShopMarketList || []}>
                    <MarketProductCard
                      className='product-card'
                      key={marketProduct.id}
                      marketProduct={marketProduct}
                      loading={loading} />
                  </For>
                </Otherwise>
              </Choose>
            </Aisles>
          </If>
          <For each='marketProductCategory' of={marketProductCategories}>
            <Aisles
              title={marketProductCategory.title}
              viewAllUrl={`/market/store/${marketProductCategory.slug}${urlSuffix}`}
              pivotId={marketProductCategory.id}
              className='aisles'
              isMiniCard={isMiniCard}
              slidesToScroll={slidesToScroll}
              aislesMaxWidth={aislesMaxWidth}
              displayFade={displayFade(marketProductCategory.marketProducts)}>
              <Choose >
                <When condition={isMiniCard}>
                  {renderMiniProductGrids(marketProductCategory.marketProducts)}
                </When>
                <Otherwise>
                  <For each='marketProduct' of={marketProductCategory.marketProducts || []}>
                    <MarketProductCard
                      className='product-card'
                      key={marketProduct.id}
                      marketProduct={marketProduct}
                      loading={loading} />
                  </For>
                </Otherwise>
              </Choose>
            </Aisles>
            <Spacer universal='16px' />
          </For>
        </AislesContainer>
      </Otherwise>
    </Choose>
  )
})

MarketAislesCarousel.displayName = 'MarketAislesCarousel'
