import React from 'react'

import { useToasts } from 'react-toast-notifications'
import styled, { useTheme } from 'styled-components'

import { Paragraph } from '@atoms/index'
import { DeviceContainer, SmallLoader } from '@client/components'
import { Icon, IconEnum, ResponsiveImage } from '@client/components/atoms'
import { ResponsivePXValue } from '@client/components/Theme'
import { SiteHelper } from '@client/lib/SiteHelper'
import { CartItemFragment, useRemoveItemFromCartMutation, useGetApiEnumQuery } from '@hooks/api'

const Container = styled.div`
  display: flex;
  justify-content: center;

  ${ResponsivePXValue('padding', '8px 0')}
`

const ImageContainer = styled.div`
  flex-shrink: 0;
  overflow: hidden;
  ${ResponsivePXValue('width', { mobile: '48px', tablet: '68px', desktop: '88px' })}
  ${ResponsivePXValue('height', { mobile: '48px', tablet: '68px', desktop: '88px' })}
  ${ResponsivePXValue('border-radius', '8px')}
`

const DetailsContiner = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;

  ${ResponsivePXValue('align-items', { mobile: 'flex-start', tablet: 'flex-start', desktop: 'center' })}
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsivePXValue('margin-left', { mobile: '8px', tablet: '10px', desktop: '12px' })}


  .name-text {
    flex-grow: 1;
    padding: 0;
    ${ResponsivePXValue('margin', '4px 0')}
    ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '35%' })}
  }

  .toggle {
    flex-shrink: 0;
    ${ResponsivePXValue('padding', { mobile: '0 20px', tablet: '0 15px', desktop: '0 10px' })}
    ${ResponsivePXValue('width', { mobile: '80px', tablet: '70px', desktop: '60px' })}
  }

  .detail {
    flex-shrink: 0;
    padding: 0;
    ${ResponsivePXValue('margin', '4px 0')}
    ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '20%' })}
    ${ResponsivePXValue('text-align', { mobile: 'left', tablet: 'center', desktop: 'center' })}
  }

  .remove {
    width: 5%;
  }
`

const TitleContainer = styled.div`

  display: flex;
  justify-content: space-between;

  ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '50%' })}

  .text {
    padding: 0;
    flex-grow: 1;
    ${ResponsivePXValue('margin', '4px 0')}
  }

  .icon {
    flex-shrink: 0;
    ${ResponsivePXValue('margin', '4px 0')}
  }
`

const IconContainer = styled.div`
  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}
`

export interface OnDemandItemProps {
  cartItem: CartItemFragment
}

export function OnDemandItem({ cartItem }: OnDemandItemProps): JSX.Element {

  const [removeItemFromCart, { loading: removeLoading }] = useRemoveItemFromCartMutation({
    refetchQueries: SiteHelper.getUserRefetchQueries(),
    awaitRefetchQueries: true,
  })

  const { data: portionsData } = useGetApiEnumQuery({ variables: { enum: 'FrozenPortionSizeEnum' } })
  const theme = useTheme()
  const { addToast } = useToasts()

  const _handleRemove = async (): Promise<void> => {
    try {
      await removeItemFromCart({
        variables: {
          productId: cartItem.product.id,
        },
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const product = cartItem.product
  const name = product.name.replace(/\s-\sServes\s.*$/, '')
  let extra = ''
  switch (product.__typename) {
    case 'Wine':
      extra = product.vineyard.name
      break
    case 'Gift':
      extra = 'Gift'
      break
    case 'FrozenMeal':
      extra = portionsData?.enum?.values?.find((item) => item.value === product?.frozenPortionSize).title ?? ''
      break
  }

  const loading = removeLoading

  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage image={product.coverImage} lazy={false} />
      </ImageContainer>
      <DetailsContiner>
        <TitleContainer>
          <Paragraph
            variant='p2'
            align='left'
            className='text'>
            {name}
          </Paragraph>
          <DeviceContainer $mobile>
            <IconContainer className='icon' onClick={_handleRemove}>
              <Choose>
                <When condition={loading}>
                  <SmallLoader />
                </When>
                <Otherwise>
                  <Icon color={theme.colors.greys.darkCodGrey} icon={IconEnum.CLOSE_OUTLINE} />
                </Otherwise>
              </Choose>
            </IconContainer>
          </DeviceContainer>
        </TitleContainer>
        <Paragraph
          variant='p1'
          className='detail'
        >
          Qty&nbsp;{cartItem.quantity}
        </Paragraph>
        <Paragraph
          variant='p1'
          className='detail'>
          {extra}
        </Paragraph>
        <DeviceContainer $mobile>
          <Paragraph
            variant='p2'
            className='detail'>
            R{cartItem.price.toFixed(2)}
          </Paragraph>
        </DeviceContainer>
        <DeviceContainer $tablet $desktop>
          <Paragraph
            variant='p1'
            className='detail'>
            R{cartItem.price.toFixed(2)}
          </Paragraph>
        </DeviceContainer>

        <DeviceContainer $mobile>
          <IconContainer onClick={_handleRemove}>
            <Choose>
              <When condition={loading}>
                <SmallLoader />
              </When>
              <Otherwise>
                <Icon color={theme.colors.greys.darkCodGrey} icon={IconEnum.CLOSE_OUTLINE} />
              </Otherwise>
            </Choose>
          </IconContainer>
        </DeviceContainer>
      </DetailsContiner>
    </Container>
  )

}
