import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled, { CSS } from 'styled-components'

import { FieldData, Paragraph, Spacer, Button, Link } from '@client/components'
import { Form, TextInput, useForm } from '@client/components/molecules'
import { ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { useRedeemUserPointsForCartMutation, useUserCartQuery } from '@hooks/api'

const WalletButtonContainer = styled.div<{ $checkout?: boolean }>`

  ${(props): CSS => {
    if (!props.$checkout) {
      return ResponsivePXValue('padding', '16px')
    }
  }}
  
  .button {
    text-decoration: underline;
    text-decoration-color: ${theme.colors.oranges.coral};
  }
  .header {
    ${ResponsivePXValue('margin-bottom', '16px')}
  }
`

const WalletContainer = styled.div<{ $checkout?: boolean }>`
  display: flex;
  flex-direction: column;
  
  ${(props): CSS => {
    if (!props.$checkout) {
      return ResponsivePXValue('padding', '16px')
    }
  }}

  .wallet-text-input {
    ${ZeroSpace}
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .header-text {
    ${ZeroSpace}
    line-height: 1;
  }

  .sub-header-text {
    ${ZeroSpace}
    line-height: 1;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  .action-button {
    flex: 1;
    text-transform: lowercase;
    ${ResponsivePXValue('height', '32px')}
  }
`

export interface CartWalletProps {
  checkout?: boolean
  onChange?: (showing: boolean) => void
}

interface CartWalletFormData {
  amount: number
}

interface CartWalletState {
  walletOpen: boolean
  loading: boolean
  amount: number
}

const DEFAULT_STATE: CartWalletState = {
  walletOpen: false,
  loading: false,
  amount: 0,
}

export function CartWallet({ checkout = false, onChange }: CartWalletProps): JSX.Element {

  const config = useConfig()
  const { data: cartData, loading: cartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [redeemUserPointsForCart] = useRedeemUserPointsForCartMutation()
  const [state, setState] = useState<CartWalletState>({ ...DEFAULT_STATE })
  const [form] = useForm()
  const cart = cartData?.currentUser?.activeCart
  const { addToast } = useToasts()

  const _handleOnSubmit = async (data: CartWalletFormData) => {
    setState((prevState) => update(prevState, {
      loading: { $set: true },
    }))

    try {
      await redeemUserPointsForCart({
        variables: {
          id: cart?.id,
          points: data.amount,
        },
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })
      setState((prevState) => update(prevState, {
        loading: { $set: false },
        walletOpen: { $set: false },
      }))
      addToast('User points successfully applied!', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      setState((prevState) => update(prevState, {
        loading: { $set: false },
      }))
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'amount') {
          setState((prevState) => update(prevState, {
            amount: { $set: field.value },
          }))
        }
      })
    })
  }

  const _toggleWallet = (): void => {
    const walletOpen = !state.walletOpen
    setState((prevState) => update(prevState, {
      walletOpen: { $set: walletOpen },
    }))
  }

  useEffect(() => {
    onChange?.(state.walletOpen)
  }, [state.walletOpen])

  return (
    <Choose>
      <When condition={state.walletOpen}>
        <Form form={form} onFinish={_handleOnSubmit} onFieldsChange={_handleChange} autoComplete='off'>
          <WalletContainer $checkout={checkout}>
            <HeaderContainer>
              <Paragraph variant='p2' className='header-text'>{'Wallet'}</Paragraph>
              <Paragraph variant='p3' className='sub-header-text'>{`You have ${cart?.availableUserPoints} points`}</Paragraph>
            </HeaderContainer>
            <Spacer variant='horizontal' universal='8px' />
            <TextInput
              variant='integer'
              name='amount'
              label='Amount'
              showLabel={false}
              placeholder='0'
              value={`R${state.amount}`}
              className='wallet-text-input'
              disabled={cartLoading || state.loading}
              rules={[{ required: true, message: 'Please input an amount' }]} />
            <Spacer variant='horizontal' universal='8px' />
            <ButtonsContainer>
              <Button variant='secondary'
                className='action-button'
                color='grey'
                title='cancel'
                disabled={cartLoading || state.loading}
                onClick={_toggleWallet} />
              <Spacer universal='16px' />
              <Button
                className='action-button'
                title='apply'
                color='orange'
                fluid
                disabled={cartLoading || state.loading}
                loading={state.loading}
                onClick={() => form.submit()} />
            </ButtonsContainer>
          </WalletContainer>
        </Form>
      </When>
      <Otherwise>
        <WalletButtonContainer $checkout={checkout}>
          <If condition={checkout}>
            <HeaderContainer className='header'>
              <Paragraph variant='p2' className='header-text'>Wallet</Paragraph>
              <Paragraph variant='p2' className='sub-header-text'>R{cartData?.currentUser?.activeCart?.assignedUserPoints.toFixed(2)}</Paragraph>
            </HeaderContainer>
          </If>
          <Link
            bold
            className='button'
            color={theme.colors.oranges.coral}
            onClick={_toggleWallet}
            disabled={cartLoading}>
              USE WALLET POINTS
          </Link>
        </WalletButtonContainer>
      </Otherwise>
    </Choose>
  )

}
