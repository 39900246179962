import React, { useState, useEffect } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import update from 'react-addons-update'
import styled from 'styled-components'

import { CheckoutPlugin } from '@api/local'
import { CheckoutPromoCodes } from '@client/components'
import { Rule } from '@client/components/atoms'
import { ButtonGroup, SectionLoading } from '@client/components/molecules'
import { CategoryCard } from '@client/components/molecules/stores/CategoryCard'
import { ContentBlock } from '@client/components/molecules/stores/ContentBlock'
import { ResponsivePXValue } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useAnalytics } from '@client/hooks/UseAnalytics'
import { SiteHelper } from '@client/lib/SiteHelper'
import { MealKitCategoryFragment, useGetAllMealKitCategoriesQuery, useUpdateUserSubscription, useUserCartQuery } from '@hooks/api'
import { NumberOfPortionsEnum, MealKitCategoryStatusEnum, MealKitCategoryOrderEnum, OrderDirectionEnum } from '@uctypes/api/globalTypes'

import { CheckoutContainer } from '../CheckoutContainer'
import { CheckoutPlanSummary } from '../CheckoutPlanSummary'

const ContentContainer = styled.div`
  
  ${ResponsivePXValue('width', { mobile: 'CALC(100% - 32px)', tablet: 'CALC(100% - 32px)', desktop: 'CALC(100% - 128px)' })}
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px 16px', desktop: '32px 64px' })}

  .seperator {
    ${ResponsivePXValue('margin', { mobile: '20px 0', tablet: '25px 0', desktop: '30px 0' })}
  }
`

const ButtonGroupsContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('gap', '32px')}
`

const ButtonGroupContainer = styled.div`
  width: 100%;
`

const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${ResponsivePXValue('justify-content', { mobile: 'flex-start', tablet: 'center', desktop: 'flex-start' })}
  ${ResponsivePXValue('gap', '32px')}
`

interface CheckoutPlanState {
  numberOfPortions: NumberOfPortionsEnum
  numberOfMeals: number
  defaultNumberOfMealsSet: boolean
}

const DEFAULT_STATE: CheckoutPlanState = {
  numberOfPortions: NumberOfPortionsEnum.SERVES_2,
  numberOfMeals: 3,
  defaultNumberOfMealsSet: false,
}

export function CheckoutPlan(): JSX.Element {

  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const { data: userCartData, loading: userCartLoading } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: categoryData, loading: categoryLoading } = useGetAllMealKitCategoriesQuery({
    variables: {
      filters: {
        canSubscribe: true,
        status: [MealKitCategoryStatusEnum.ACTIVE],
      },
      order: {
        field: MealKitCategoryOrderEnum.DISPLAY_INDEX,
        direction: OrderDirectionEnum.ASC,
      },
    },
  })
  const [updateSubscription, { loading: updateLoading }] = useUpdateUserSubscription()
  const [state, setState] = useState<CheckoutPlanState>({ ...DEFAULT_STATE })
  const { logCheckoutStep } = useAnalytics()

  const _handleNumberOfMealsChange = (newValue: string): void => {
    setState((prevState) => update(prevState, {
      numberOfMeals: { $set: newValue },
    }))
  }

  const _handleNumberOfPortionsChange = (newValue: string): void => {
    setState((prevState) => update(prevState, {
      numberOfPortions: { $set: newValue },
    }))
  }

  const _handlePlanSelect = async (categoryId: string): Promise<void> => {
    await updateSubscription({
      variables: {
        id: userCartData?.currentUser?.activeMenu?.subscription?.id,
        input: {
          category: categoryId,
          numberOfMeals: state.numberOfMeals,
          numberOfPortions: state.numberOfPortions,
          updateMenu: true,
        },
      },
      refetchQueries: SiteHelper.getCheckoutRefetchQueries(),
      awaitRefetchQueries: true,
    })
  }

  const userHasUpdatedOptions = (): boolean => {
    if (userCartData.currentUser.activeMenu.subscription.numberOfPortions !== state.numberOfPortions) {
      return true
    }
    if (userCartData.currentUser.activeMenu.subscription.numberOfMeals !== state.numberOfMeals) {
      return true
    }
    return false
  }

  const _handleContinue = async (): Promise<void> => {
    if (userHasUpdatedOptions()) {
      await updateSubscription({
        variables: {
          id: userCartData?.currentUser?.activeMenu?.subscription?.id,
          input: {
            numberOfMeals: state.numberOfMeals,
            numberOfPortions: state.numberOfPortions,
            updateMenu: true,
          },
        },
        refetchQueries: SiteHelper.getCheckoutRefetchQueries(),
        awaitRefetchQueries: true,
      })
    }

    const discounts = userCartData?.currentUser?.activeCart?.reductions
    let discountTitles = ''
    let discountValue = 0
    for (let i = 0; i < discounts.length; i++) {
      discountTitles = discountTitles +discounts[i].title
      discountValue = discountValue + discounts[i].value
    }
    // Analytics
    logCheckoutStep('Plan', 'Subscription Select Plan', userCartData?.currentUser?.activeCart?.cartItems, userCartData?.currentUser?.activeCart?.grandTotal, discountTitles, discountValue, userCartData?.currentUser?.id)

    await CheckoutPlugin.shared().checkout(client)
  }

  useEffect(() => {
    if (userCartData?.currentUser?.activeMenu?.subscription && userCartData?.currentUser?.activeCart && !state.defaultNumberOfMealsSet) {
      const numberOfMeals = userCartData.currentUser.activeCart.cartItems.filter(cartItem => {
        return cartItem.product.__typename === 'MealKit'
      })
      let defaultNumberOfMeals = 3
      switch (numberOfMeals.length) {
        case 2:
          defaultNumberOfMeals = 2
          break
        case 4:
          defaultNumberOfMeals = 4
          break
      }
      setState((prevState) => update(prevState, {
        defaultNumberOfMealsSet: { $set: true },
        numberOfPortions: { $set: userCartData.currentUser.activeMenu.subscription.numberOfPortions },
        numberOfMeals: { $set: defaultNumberOfMeals },
      }))
    }
  }, [userCartData])

  let category: MealKitCategoryFragment
  const loading = userCartLoading || categoryLoading
  const selectedCategoryId = userCartData?.currentUser?.checkoutStatus?.hasSelectedPlan && userCartData?.currentUser?.activeMenu?.subscription?.category?.id
  const $sidebar = (
    <>
      <CheckoutPlanSummary
        category={categoryData?.mealKitCategories?.list?.find((category) => category.id === selectedCategoryId)}
        loading={loading || updateLoading}
        numberOfMeals={state.numberOfMeals}
        numberOfPortions={state.numberOfPortions}
        onCheckout={_handleContinue} />
      <CheckoutPromoCodes
        loading={loading}
        disabled={userCartLoading || categoryLoading} />
    </>
  )

  return (
    <CheckoutContainer sidebar={$sidebar}>
      <ContentBlock
        title='Your Meal Kit Plan'
        description='Choose a default category from up to ten categories. We Deliver weekly with everything you need from you chosen category. We are as flexible as you are.'>
        <ContentContainer>
          <Choose>
            <When condition={loading}>
              <SectionLoading />
            </When>
            <Otherwise>
              <ButtonGroupsContainer>
                <ButtonGroupContainer>
                  <ButtonGroup
                    title='No. of recipes'
                    value={String(state.numberOfMeals)}
                    onChange={_handleNumberOfMealsChange} />
                </ButtonGroupContainer>
                <ButtonGroupContainer>
                  <ButtonGroup
                    title='Cooking for'
                    apiEnum='NumberOfPortionsEnum'
                    value={state.numberOfPortions}
                    onChange={_handleNumberOfPortionsChange} />
                </ButtonGroupContainer>
              </ButtonGroupsContainer>
              <Rule className='seperator' color='slate' />
              <CategoriesContainer>
                <For each='category' of={categoryData?.mealKitCategories?.list || []}>
                  <CategoryCard
                    selected={category.id === selectedCategoryId}
                    category={category}
                    key={category.id}
                    numberOfMeals={state.numberOfMeals}
                    numberOfPortions={state.numberOfPortions}
                    onSelect={_handlePlanSelect} />
                </For>
              </CategoriesContainer>
            </Otherwise>
          </Choose>
        </ContentContainer>
      </ContentBlock>
    </CheckoutContainer>
  )

}
