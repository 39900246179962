import React, { Fragment, useEffect, useState } from 'react'

import { format } from 'date-fns'
import Cookies from 'js-cookie'
import qs from 'qs'
import update from 'react-addons-update'
import { useLocation, useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { AppPlugin, APP_DEFAULT_STATE, GlobalModalTypeEnum, ModalsPlugin } from '@api/local'
import { Heading, Link, Paragraph, Spacer } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useAnalytics } from '@client/hooks/UseAnalytics'
import { useLoadingData } from '@client/hooks/UseLoadingData'
import {
  MealKitDishDetailsFragment, useGetAppQuery, useUserCartQuery, useUserDetailsQuery, useActiveMenuDishesQuery,
  ProductAggregationSectionFragment, UserSubscriptionFragment, useSystemChangesSubscription, RegisteredUserDetailsFragment,
  UserDetailsFragment, OrderItemFragment, MealKitDishListFragment,
} from '@hooks/api'
import { Filter, FilterItemProps, FilterSectionProps, MealKitCard, SectionLoading, SelectedFilters, StoreStickyButton } from '@molecules/index'
import { SystemChangeTypeEnum, DeviceTypeEnum, UserSubscriptionStatusEnum, MenuStatusEnum, NumberOfPortionsEnum, PortionSizeEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { MiniProductGrid } from '../molecules/stores/MiniProductGrid'
import { ResponsivePXValue, ResponsiveProperty, LoadEffect } from '../Theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props): string => props.theme.colors.greys.cultured};
  // overflow-x: hidden;
  ${ResponsivePXValue('padding', { mobile: '16px 0', tablet: '16px', desktop: '32px 152px' })}
`
const PaddedContainer = styled.div`
  ${ResponsivePXValue('padding', { mobile: '0 16px' })}
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  `

const HeadingContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  ${ResponsiveProperty('justify-content', { mobile: 'flex-start', tablet: 'flex-start', desktop: 'space-between' })}
`
const MenuCloses = styled.div`
  display: flex;
  align-items: center;

  .menu-time {
    font-weight: 500;
  }
`
const Grid = styled.div<{ $isLoading: boolean }>`
  display: flex;

  ${(props): CSS => props.$isLoading ? LoadEffect : undefined};

  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}

  .mealkit-filter {
    ${ResponsivePXValue('min-width', { mobile: '100vw', desktop: '272px' })}
    ${ResponsivePXValue('margin', { mobile: '0 -16px', desktop: '0 16px 0 0 ' })}
    .filter-pill-container {
      ${ResponsivePXValue('left', { mobile: '36px' })}
    }
  }

  .showing-filter {
    ${ResponsivePXValue('margin', { mobile: '0' })}
  }
`
const DishContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px;
  height: fit-content;
  ${ResponsivePXValue('min-width', { desktop: '845px' })}
`

export interface AddonOrderRatingData {
  orderId: string
  orderItemData: OrderItemFragment
}

interface MealKitStoreState {
  dishes: MealKitDishDetailsFragment[]
  filterSections: FilterSectionProps[]
  filteredDishes: MealKitDishDetailsFragment[]
  categorySectionItems: FilterItemProps[]
  tagSectionItems: FilterItemProps[]
  cookWithinDaysSectionItems: FilterItemProps[]
  loading: boolean
  ratingModalOpen: boolean
  ratingOrder: OrderItemFragment | undefined
  onDemandOrder: OrderItemFragment
  hasPushNotificationsToggled: boolean
  hasLoggedImpressions: boolean
  addOnsRatingModal: boolean
  sourceIsPersonalizedHomepage: boolean
  addOnsNeedingRatings: { [k: string]: OrderItemFragment[] }
}

const DEFAULT_STATE: MealKitStoreState = {
  dishes: [],
  filterSections: [],
  filteredDishes: [],
  categorySectionItems: [],
  tagSectionItems: [],
  cookWithinDaysSectionItems: [],
  loading: false,
  ratingModalOpen: true,
  ratingOrder: {} as OrderItemFragment,
  onDemandOrder: {} as OrderItemFragment,
  hasPushNotificationsToggled: false,
  hasLoggedImpressions: false,
  addOnsRatingModal: true,
  sourceIsPersonalizedHomepage: false,
  addOnsNeedingRatings: {},
}

const seo = {
  name: 'UCOOK Meal Kit Menu',
  title: 'Meal Kits Delivery Service South Africa | UCOOK',
  meta: [{
    name: 'description',
    content: 'Choose from 20 new dishes every week and get step-by-step recipe cards with fresh, seasonal and sustainable ingredients delivered to your door.',
  }, {
    name: 'keywords',
    content: 'Easy Meal Kits,Healthy Meal Delivery Kits,Vegetarian Meal Delivery Kit',
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
}

export const lunchCategoryId = 'll6cb3be-8b4a-4de5-9eae-e5f7186e6666'
export const lunchSaverId = 'db6cbbbe-8b4a-4de5-9fdb-5d3423b6aded'
export const lunchPremiumId = 'de35a6c1-b45c-4a87-9eae-e5f7186e8070'

export function MealKitStore(): JSX.Element {

  const [state, setState] = useState<MealKitStoreState>({ ...DEFAULT_STATE })
  const config = useConfig()

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: systemChangesData } = useSystemChangesSubscription({ variables: { types: [SystemChangeTypeEnum.MENU] }, skip: !config.isBrowser() })

  const location = useLocation()
  const navigate = useNavigate()

  const params = qs.parse(location.search.replace('?', ''))
  const filters = params?.filters as { [k: string]: string[] } || {}
  const variables = {
    filters: {
      ...filters,
    },
  }

  const { data: dishesData, loading: dishesLoading, refetch } = useActiveMenuDishesQuery({ variables, skip: !userDetailsData?.currentUser })
  const loading = dishesLoading || userDetailsLoading
  const dishes = useLoadingData<Readonly<(MealKitDishListFragment)[]>>({
    data: dishesData?.activeMenu?.menuDishes?.list,
    defaultData: [],
    loading: dishesLoading,
  })
  const aggregations = useLoadingData<Readonly<ProductAggregationSectionFragment[]>>({
    data: dishesData?.activeMenu?.menuDishes?.aggregation?.sections,
    defaultData: [],
    loading: dishesLoading,
  })

  const { logProductListImpression } = useAnalytics()
  const renderMiniProductGrids = () => {
    const batchSize = 4
    const miniProductGrids = []

    for (let i = 0; i < dishes.length; i += batchSize) {
      const batch = dishes.slice(i, i + batchSize)
      miniProductGrids.push(
        <MiniProductGrid key={`batch${i / batchSize + 1}`} meals={batch} />,
      )
    }

    return miniProductGrids
  }
  const activeMenu = userCartData?.currentUser?.activeMenu
  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const userCurrentPlan = activeMenu?.subscription?.category?.title
  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const subscription: UserSubscriptionFragment = registeredUser?.subscriptions?.find((subscription) => subscription.id === userCartData?.currentUser?.activeMenu?.subscription?.id)
  const subscriptionStatus = subscription?.status
  const menuClosingDate = format(new Date(activeMenu?.billingDate || new Date()), 'ccc dd MMM').toUpperCase()
  const noDishesMessage = 'There are no dishes matching the selected. Broaden your search by clearing some filters to continue.'
  const defaultPortions = appData.app.defaultPortions ? appData.app.defaultPortions: NumberOfPortionsEnum.SERVES_2

  let message: string | React.ReactElement = <Fragment>See what&apos;s on the menu this week, or have a look at our  <Link href='/meal-kit/plans' variant='l1' decoration='underline'>Plans</Link> to get started.</Fragment>

  const slugSessionObject :{ [k: string]: NumberOfPortionsEnum } = {}
  const sessionStringMealkit = sessionStorage.getItem('SlugSessionString')
  const sessionObjectMealkit = JSON.parse(sessionStringMealkit)

  if (!sessionObjectMealkit || Object.keys(sessionObjectMealkit).length === 0) {

    for (let index = 0; index < dishes.length; index++) {
      const productSlug = dishes[index].slug
      slugSessionObject[productSlug] = defaultPortions

    }
    const slugSessionObjectString = JSON.stringify(slugSessionObject)
    sessionStorage.setItem('SlugSessionString', slugSessionObjectString)
  }

  switch (true) {
    case subscriptionStatus === UserSubscriptionStatusEnum.ACTIVE:
      message = `We add dishes from your ${userCurrentPlan} plan each week, but you can flex out of this, and choose a dish from any category.`
      break
    case subscriptionStatus === UserSubscriptionStatusEnum.PAUSED:
      message = <Fragment>We add dishes from your {userCurrentPlan} plan each week, but you can flex out of this, <Link href='/me/subscriptions' variant='l1' decoration='underline'>Activate</Link> and choose a dish from any category.</Fragment>
      break
  }

  const _handleFiltersChange = (selected: SelectedFilters): void => {

    setState((prevState) => update(prevState, {
      filters: { $set: selected },
      skip: { $set: 0 },
    }))
    const newQueryString = qs.stringify({ filters: selected })
    navigate(`${location.pathname}?${newQueryString}`)
  }

  const _handleFiltersClear = (): void => {
    setState((prevState) => update(prevState, {
      filters: { $set: {} },
      skip: { $set: 0 },
    }))
    navigate(location.pathname)
  }

  const sections: FilterSectionProps[] = aggregations.map((aggregation) => ({
    id: aggregation.filterKey,
    title: aggregation.title,
    filterKey: aggregation.filterKey,
    items: aggregation.items.map((item) => ({
      id: item.id,
      title: item.name,
      quantity: 0,
    })),
  }))

  const shouldDisplayLunchServingModal = () => {
    return Cookies.get('LUNCH_SERVING_SIZE') !== 'true'
  }

  const _displayLunchServingModal = () => {
    ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.PAGE_MODAL, 'LUNCH_SERVING_SIZE', null, true)
  }

  useEffect(() => {
    if (shouldDisplayLunchServingModal()) {
      _displayLunchServingModal()
    }
  }, [])

  useEffect(() => {
    if (location && location.search.includes('source=personalizedHome')) {
      setState((prevState) => update(prevState, {
        sourceIsPersonalizedHomepage: { $set: true },
      }))
    }
  }, [location.pathname])

  useEffect(() => {
    const change = systemChangesData?.systemChanges
    if (change?.__typename === 'MenuSystemChange') {
      if (change.menuStatus === MenuStatusEnum.OPENED || change.menuStatus === MenuStatusEnum.CLOSING) {
        refetch()
      }
    }
  }, [systemChangesData?.systemChanges])

  useEffect(() => {
    if (!loading && !state.hasLoggedImpressions && !!dishes.length) {
      logProductListImpression(
        dishes.map((dish, displayIndex) => {
          return {
            item_name: dish?.name,
            item_id: dish?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === defaultPortions)?.id,
            price: dish?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === defaultPortions)?.price,
            item_brand: 'UCOOK',
            item_category: dish?.mealKitCategories?.map((cat) => cat.id)?.join(', '),
            item_variant: dish?.mealKitCategories?.map((cat) => cat.title).join(', '),
            item_list_name: 'Meal Kit',
            index: displayIndex,
          }
        }),
        userDetailsData?.currentUser?.id)
      setState((prevState) => update(prevState, {
        hasLoggedImpressions: { $set: true },
      }))
    }
  }, [loading || userDetailsData?.currentUser?.addresses, dishes])

  useEffect(() => {
    const defaultPortions = subscription?.numberOfPortions
    const cookiedPortion = Cookies.get('defaultPortion') as unknown as NumberOfPortionsEnum

    if (defaultPortions) {
      AppPlugin.shared().setDefaultPortions(defaultPortions)
      Cookies.set('defaultPortion', String(defaultPortions), { expires: 365 })
    } else if (cookiedPortion) {
      AppPlugin.shared().setDefaultPortions(cookiedPortion)
    }
  }, [userDetailsData])
  let mealkit: MealKitDishDetailsFragment

  return (
    <Container>
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KQBXJTG" height="0" width="0"></iframe>
      <SearchEngineOptimization seo={seo} />
      <Choose>
        <When condition={loading && !dishes.length}>
          <SectionLoading height='100vh' />
        </When>
        <Otherwise>
          <ContentContainer>
            <PaddedContainer>
              <StoreStickyButton isMobile={isMobile} />
              <HeadingContainer>
                <Heading variant='h1'>Meal kit menu</Heading>
                <MenuCloses>
                  <Heading variant='h6'>MENU CLOSES</Heading>
                  <Spacer variant='horizontal' mobile='4px' desktop='8px' />
                  <Heading variant='h6' className='menu-time'> {menuClosingDate} AT 9AM</Heading>
                </MenuCloses>
              </HeadingContainer>
              <If condition={isDesktop}>
                <Paragraph>{message}</Paragraph>
                <Spacer desktop='24px' />
              </If>
            </PaddedContainer>
            <Grid $isLoading={loading}>
              <Spacer mobile='12px' />
              <PaddedContainer>
                <Filter
                  className='mealkit-filter'
                  filters={filters as unknown as { [k: string]: string[] }}
                  sections={sections}
                  onChange={_handleFiltersChange}
                  onClear={_handleFiltersClear} />
                <Spacer mobile='4px' />
                <Spacer variant='horizontal' desktop='32px' />
                <Spacer mobile='12px' />
              </PaddedContainer>
              <DishContainer id='productGrid'>
                <If condition={dishes.length < 1}>
                  <Paragraph> {noDishesMessage} </Paragraph>
                </If>
                <Choose>
                  <When condition = {isDesktop}>
                    <For each='mealkit' of={dishes}>
                      <MealKitCard key={mealkit.id} mealKit={mealkit} />
                    </For>
                  </When>
                  <Otherwise>
                    {renderMiniProductGrids()}
                  </Otherwise>
                </Choose>
              </DishContainer>
            </Grid>
          </ContentContainer>
        </Otherwise>
      </Choose>
    </Container >
  )
}
