import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { GlobalModalTypeEnum, ModalsPlugin } from '@api/local/ModalsPlugin'
import { ResponsiveImage, Button, Pill, Spacer, Heading, Paragraph } from '@atoms/index'
import { LowStockContainer } from '@client/components/organisms'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useAnalytics } from '@client/hooks/UseAnalytics'
import { SiteHelper } from '@client/lib/SiteHelper'
import { ProductCardContainer, ProductCardImageContainer, ProductCardContentContainer, ServingSizeEnum } from '@components/Theme'
import {
  useUserDetailsQuery, UserCartDocument, useRemoveItemFromCartMutation, useChangeCartItemQuantityMutation,
  useAddItemToCartMutation, FrozenMealDishListFragment, FrozenMealListFragment,
} from '@hooks/api'
import { DiscoveryVitality, FieldData, Form, getCategoryBackground, RadioButton, RadioButtonProps, useForm } from '@molecules/index'
import { FrozenPortionSizeEnum, ProductAvailabilityEnum, ProductStockStatusEnum } from '@uctypes/api/globalTypes'

import { FloatingUpSell } from './FloatingUpSell'

const Container = styled.div`
  ${ProductCardContainer}

  .flex-form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`

const ImageContainer = styled.div`
  ${ProductCardImageContainer}
`

const LinkContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
`

const ContentContainer = styled.div`
  ${ProductCardContentContainer}
  .serving-size {
    display: flex;
    flex: 0;
    margin: 0;
  }
`
const DescriptionContainer = styled.div`
  height: 6em;
`
export interface CraftMealCardProps {
  craftMeal: FrozenMealDishListFragment
  className?: string
  loading?: boolean
}

interface CraftMealCardState {
  addToCartInterrupted: boolean
  displayingPriceRange: boolean
  price: number | string
  loading: boolean
  servingSize: FrozenPortionSizeEnum
  isAvailable?: boolean

}

const DEFAULT_STATE: CraftMealCardState = {
  addToCartInterrupted: false,
  displayingPriceRange: false,
  price: 0,
  loading: false,
  servingSize: FrozenPortionSizeEnum.SERVES_ONE,
  isAvailable: true,
}

export const getCraftMealPriceRange = (craftMeal: FrozenMealDishListFragment): { lower: number, upper: number } => {

  const getPortionPrice = (portionSize: FrozenPortionSizeEnum): number => {
    return craftMeal?.products?.find(product => product.frozenPortionSize === portionSize)?.price || 0
  }

  const lower = getPortionPrice(FrozenPortionSizeEnum.SERVES_ONE)
  const upper = getPortionPrice(FrozenPortionSizeEnum.SERVES_FOUR)
  return {
    lower,
    upper,
  }
}

export function CraftMealCard({ craftMeal, className, loading = false }: CraftMealCardProps): JSX.Element {

  const config = useConfig()
  const [form] = useForm()
  const [state, setState] = useState<CraftMealCardState>({ ...DEFAULT_STATE })
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [addItemToCart] = useAddItemToCartMutation()
  const [removeItemFromCart] = useRemoveItemFromCartMutation({
    refetchQueries: SiteHelper.getUserRefetchQueries(),
    awaitRefetchQueries: true,
  })
  const { addToast } = useToasts()
  const category = craftMeal.frozenCategories[0]
  const background = getCategoryBackground(category.background)
  const pdpLink = `/frozen/craft-meal/${craftMeal?.slug}`
  const navigate = useNavigate()
  const { logAddToCart, logRemoveFromCart } = useAnalytics()

  const isAvailable = (): boolean => {
    let available = true
    for (let i = 0; i < craftMeal.products.length; i++) {
      if (craftMeal.products[i].availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY) {
        available = false
        break
      }
    }
    return available
  }

  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation({
    refetchQueries: [{
      query: UserCartDocument,
    }],
    awaitRefetchQueries: true,
  })

  const _handleOnAdd = async (): Promise<void> => {

    const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

    setState((prevState) => update(prevState, { addToCartInterrupted: { $set: noDefaultAddress || !state.isAvailable } }))

    if (noDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
    } else if (state.isAvailable) {

      setState((prevState) => update(prevState, { loading: { $set: true } }))

      const servingSize = form.getFieldValue('servingSizeOptionsRadio')
      const price = craftMeal?.products?.find(product => product.id === servingSize)?.price || 0
      try {
        await addItemToCart({
          variables: { productId: servingSize, quantity: 1 },
          refetchQueries: [{ query: UserCartDocument }],
          awaitRefetchQueries: true,
        })

        // Analytics
        logAddToCart({
          item_name: craftMeal?.name,
          item_id: craftMeal?.id,
          price,
          item_brand: 'UCOOK',
          item_category: craftMeal?.frozenCategories?.map((cat) => cat.id)?.join(', '),
          item_variant: craftMeal?.frozenCategories?.map((cat) => cat.title)?.join(', '),
          item_list_name: 'Craft Meals',
          quantity: 1,
        })

        addToast('Item successfully added to cart', {
          appearance: 'success',
          autoDismiss: true,
        })
      } catch (e) {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      setState((prevState) => update(prevState, { loading: { $set: false } }))
    } else {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
    }
  }

  const getCraftMealPriceRange = (craftMeal: FrozenMealDishListFragment): string => {

    const getPortionPrice = (portionSize: FrozenPortionSizeEnum): number => {
      return craftMeal?.products?.find(product => product.frozenPortionSize === portionSize)?.price || 0
    }

    const lower = getPortionPrice(FrozenPortionSizeEnum.SERVES_ONE)
    const upper = getPortionPrice(FrozenPortionSizeEnum.SERVES_FOUR)

    if (lower === upper) return `${lower}`

    return `${lower} - R${upper}`
  }

  const _handleOnRemove = async (): Promise<void> => {

    const product = craftMeal?.products?.find((product) => { return product.frozenPortionSize === state.servingSize })
    setState((prevState) => update(prevState, { loading: { $set: true } }))

    try {

      if (product.quantityInCart === 1) {
        await removeItemFromCart({
          variables: {
            productId: product.id,
          },
          refetchQueries: SiteHelper.getUserRefetchQueries(),
          awaitRefetchQueries: true,
        })
      } else {
        await changeCartItemQuantity({
          variables: {
            productId: product.id,
            quantity: product.quantityInCart - 1,
          },
        })
      }
      // Analytics
      logRemoveFromCart({
        item_name: craftMeal?.name,
        item_id: product?.id,
        price: product?.price,
        item_brand: 'UCOOK',
        item_category: craftMeal?.frozenCategories?.map((cat) => cat.id)?.join(', '),
        item_variant: craftMeal?.frozenCategories?.map((cat) => cat.title)?.join(', '),
        item_list_name: 'Craft Meals',
        quantity: 1,
      })

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setState((prevState) => update(prevState, { loading: { $set: false } }))

  }
  const _handleOnRightIconClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    _handleOnAdd()
  }
  const _handleOnLeftIconClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    _handleOnRemove()
  }
  const _handleOnCenterClick = async (): Promise<void> => {
    if (product.quantityInCart === 0) {
      _handleOnAdd()
    }
  }
  const getServingSizeTitle = (product: FrozenMealListFragment): string => ServingSizeEnum[product.frozenPortionSize].toLowerCase() as string

  const getServingSizeOptions = () => {
    const options = craftMeal?.products?.map((product: FrozenMealListFragment) => ({
      title: getServingSizeTitle(product),
      disabled: product.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK,
      value: product.id,
      quantity: product.quantityInCart,
    })) || []
    return options.sort((a, b) => a.title > b.title ? 1 : -1)
  }

  const getAvailableServing = (): string => {
    const servingSizeOptions = getServingSizeOptions()
    let availableProduct = servingSizeOptions?.find(option => !option.disabled)

    if (!availableProduct) {
      availableProduct = servingSizeOptions[0]
    }

    return availableProduct?.value
  }

  const servingSizeOptions = () => {
    const options = getServingSizeOptions()
    return options.map(option => {
      return {
        className: 'size-option',
        value: option.value,
        disabled: option.disabled,
        element: <Paragraph bold variant='p3'>{option.title}</Paragraph>,
        count: option.quantity,
      }
    })
  }

  const servingSizeOptionsRadioProps: RadioButtonProps = {
    className: 'serving-size',
    outline: false,
    name: 'servingSizeOptionsRadio',
    options: [...servingSizeOptions()],
    error: '',
    rules: [{ required: true, message: 'Please select a serving size' }],
  }

  const _setPrice = (value: string) => {

    const selectedMeal = craftMeal?.products?.find(product => product.id === value)
    let price: number | string = selectedMeal?.price ? selectedMeal.price : craftMeal?.products[0]?.price || 0

    const shouldDisplayPriceRange = price === 0 || selectedMeal?.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK || selectedMeal?.stockCount === 0

    if (shouldDisplayPriceRange) {
      price = getCraftMealPriceRange(craftMeal)
    }

    const isMealAvailable = selectedMeal?.stockCount > 0 && isAvailable()

    setState((prevState) => update(prevState, {
      servingSize: { $set: selectedMeal?.frozenPortionSize },
      price: { $set: price },
      displayingPriceRange: { $set: shouldDisplayPriceRange },
      isAvailable: { $set: isMealAvailable },
    }))

  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'servingSizeOptionsRadio') {
          _setPrice(field.value)
        }
      })
    })
  }

  const _setCraftMealServingSize = (value: string): void => {
    if (config.isBrowser()) {
      window.sessionStorage.setItem('craftMealServingSize', value)
    }
  }

  const _handlePdpNavigation = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    _setCraftMealServingSize(form.getFieldValue('servingSizeOptionsRadio'))
    navigate(pdpLink)
  }

  const getAllSizesAmount = (): number => {
    let sum = 0
    craftMeal.products.forEach((prod) => (sum += prod.quantityInCart))
    return sum
  }

  const getSpecificServingSizeAmount = (): number => {
    const servingSize = form.getFieldValue('servingSizeOptionsRadio')
    return craftMeal?.products?.find(product => product.id === servingSize)?.quantityInCart || 0
  }

  useEffect(() => {
    if (state.addToCartInterrupted) {
      _handleOnAdd()
    }
  }, [userDetailsData?.currentUser?.addresses, state.isAvailable])

  useEffect(() => {
    const availableServing = getAvailableServing()
    form.setFieldsValue({
      servingSizeOptionsRadio: availableServing,
    })
    _setPrice(availableServing)
  }, [])

  const buttonText = state.isAvailable ? 'ADD TO CART' : 'OUT OF STOCK'
  const product = craftMeal?.products?.find((product) => { return product.frozenPortionSize === state?.servingSize })
  const displayAmount = getAllSizesAmount()
  const amountServingSizeSpecific = getSpecificServingSizeAmount() || 0
  return (
    <Container className={className}>
      <If condition={craftMeal?.upSellText?.length > 0}>
        <FloatingUpSell text={craftMeal.upSellText} />
      </If>
      <If condition={craftMeal.discoveryHealthyDiningItem}>
        <DiscoveryVitality />
      </If>
      <Form className='flex-form' form={form} onFieldsChange={_handleChange}>
        <LinkContainer onClick={_handlePdpNavigation}>
          <ImageContainer>
            <ResponsiveImage image={craftMeal.coverImage} lazy={false} />
          </ImageContainer>
        </LinkContainer>
        <ContentContainer className='fluid-grow'>
          <Pill title={category.title} backgroundColor={background} />
          <Spacer universal='12px' />
          <LinkContainer onClick={_handlePdpNavigation}>
            <Heading variant='h6'> {craftMeal.name} </Heading>
          </LinkContainer>
          <Spacer universal='4px' />
          <DescriptionContainer>
            <Paragraph variant='p2'>{craftMeal.subTitle} </Paragraph>
          </DescriptionContainer>
          <Spacer universal='4px' />
          <RadioButton {...servingSizeOptionsRadioProps} />
          <Spacer universal='12px' />
          <LowStockContainer product={product}>
            <Heading variant='h5'> {`R${state.price}`} </Heading>
          </LowStockContainer>
          <Spacer universal='16px' className='fluid-grow' />
          <Button
            className='add-button'
            title={buttonText}
            color='black'
            fullWidth
            loading={loading || state.loading}
            disabled={loading || !state.isAvailable}
            amount={displayAmount}
            amountServingSizeSpecific={amountServingSizeSpecific}
            onClick={_handleOnCenterClick}
            onLeftIconClick={_handleOnLeftIconClick}
            onRightIconClick={_handleOnRightIconClick} />
        </ContentContainer>
      </Form>
    </Container>
  )
}
