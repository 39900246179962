import React from 'react'

import styled, { useTheme } from 'styled-components'

import { ContentSeperator, Heading, SectionLoading, Paragraph, Spacer, ZeroSpace } from '@client/components'
import { ResponsivePXValue } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useUserDetailsQuery } from '@hooks/api'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '16px')}

  .account-tile-text {
    ${ZeroSpace}
    line-height: 1;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props): string => props.theme.colors.oranges.coral};
  ${ResponsivePXValue('width', '48px')}
  ${ResponsivePXValue('height', '48px')}
  ${ResponsivePXValue('border-radius', '8px')}
`

export interface MobileMenuUserAccountProps {
  onClick?: () => void
}

export function MobileMenuUserAccount({ onClick }: MobileMenuUserAccountProps): JSX.Element {

  const config = useConfig()
  const theme = useTheme()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  const _handleOnClick = (): void => {
    onClick?.()
  }

  return (
    <>
      <Choose>
        <When condition={userDetailsLoading}>
          <SectionLoading height='56px' />
        </When>
        <Otherwise>
          <Container onClick={_handleOnClick}>
            <ContentContainer>
              <Paragraph variant='p2' className='account-tile-text'>{userDetailsData?.currentUser?.name}</Paragraph>
              <Spacer variant='horizontal' universal='12px' />
              <Paragraph variant='p3' color={theme.colors.slates.ash} className='account-tile-text'>View Your Account</Paragraph>
            </ContentContainer>
            <AvatarContainer>
              <Heading variant='h6' color={theme.colors.whites.pureWhite} className='account-tile-text'>{`${userDetailsData?.currentUser?.firstName?.[0]}${userDetailsData?.currentUser?.lastName?.[0]}`}</Heading>
            </AvatarContainer>
          </Container>
          <ContentSeperator margin='0 16px' />
        </Otherwise>
      </Choose>
    </>
  )

}
