import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { IconEnum, Button } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useAnalytics } from '@client/hooks/UseAnalytics'
import { PaddedSectionContainer, ResponsivePXValue, ServingSizeEnum, SidePagePadding, theme } from '@components/Theme'
import { useUserDetailsQuery, AllergenFragment, FrozenInstructionFragment, ServingSuggestionFragment, FrozenMealDishDetailsFragment, FrozenMealListFragment } from '@hooks/api'
import { SelectOption, TextListItem, TextListProps } from '@molecules/index'
import { MoreAboutMeal, MoreAboutMealProps, CraftMealYouMightAlsoLike, CraftPizzaDishDetails, CraftPizzaDishDetailsProps } from '@organisms/index'
import { ProductStockStatusEnum } from '@uctypes/api/globalTypes'

const BackButtonContainer = styled.div`
 display: flex;
 ${ResponsivePXValue('margin-bottom', { mobile: '24px', tablet: '28px', desktop: '36px' })}
 ${SidePagePadding}
`
const GhostButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  ${ResponsivePXValue('padding-bottom', { mobile: '16px' })}
`
const SectionContainer = styled.div<{ $backgroundColor?: string, $customBackground?: string, $padded: boolean }>`
  ${(props): CSS => PaddedSectionContainer(props.$backgroundColor, props.$customBackground, props.$padded)};
`

enum TabEnum {
  ABOUT_THE_DISH = 'ABOUT_THE_DISH',
  INGREDIENTS = 'INGREDIENTS',
  COOKING_INSTRUCTIONS = 'COOKING_INSTRUCTIONS',
  SERVING_SUGGESTIONS = 'SERVING_SUGGESTIONS'
}

export interface CraftPizzaPDPProps {
  dish: FrozenMealDishDetailsFragment
  loading: boolean
}

interface CraftPizzaPDPState {
  activeTab: TabEnum
  loading: boolean
  disabled: boolean
  servingSize: string | null
  quantity: number
  displayDiscoveryTag: boolean
}

const DEFAULT_STATE: CraftPizzaPDPState = {
  activeTab: TabEnum.ABOUT_THE_DISH,
  loading: false,
  disabled: true,
  servingSize: null,
  quantity: 1,
  displayDiscoveryTag: false,
}

export function CraftPizzaPDP({ dish, loading }: CraftPizzaPDPProps): JSX.Element {

  const [state, setState] = useState<CraftPizzaPDPState>({ ...DEFAULT_STATE })

  const navigate = useNavigate()
  const { logMultipleProductDetailImpression, logMultipleProductDetailSelection } = useAnalytics()

  const dishMealsFilter = (meal: FrozenMealListFragment): boolean => dish?.products?.some(product => product.id === meal.id)
  const productList = dish?.products

  const dishMeals: (FrozenMealListFragment)[] = productList?.filter(dishMealsFilter)
  const servingSize = state?.servingSize
  const servingMeal = dishMeals?.find(meal => meal.id === servingSize)
  const config = useConfig()

  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const allergens: string[] = []

  dish?.allergens?.forEach((allergen: AllergenFragment) => { allergens.push(allergen.title) })

  const finalAllergens = `${allergens.join(', ')}. Packed in a facility that handles all major food allergens and gluten, including Milk, Eggs, Fish,
                          Shellfish, Tree Nuts, Peanuts, Wheat, Soybeans.`

  useEffect(() => {
    if (!loading) {
      const logArr = [] as GA4Product[]
      dish?.products?.forEach((meal, index) => {
        logArr.push({
          item_name: dish?.name + ' - ' + meal?.frozenPortionSize,
          item_id: meal?.id,
          price: meal?.price,
          item_brand: 'UCOOK',
          item_category: dish?.frozenCategories.map((cat) => cat.id).join(', '),
          item_variant: dish?.frozenCategories.map((cat) => cat.title).join(', '),
          item_list_name: 'Craft Meals',
          index,
        })
      })
      logMultipleProductDetailImpression('Craft Pizza', logArr, userDetailsData?.currentUser?.id)
      logMultipleProductDetailSelection('Craft Pizza', logArr, userDetailsData?.currentUser?.id)
    }
  }, [loading])

  const getServingSizeTitle = (product: FrozenMealListFragment): string => ServingSizeEnum[product.frozenPortionSize] as string

  const getServingSizeOptions = (): SelectOption[] => {
    const options: SelectOption[] = dish?.products?.map((product) => ({
      title: getServingSizeTitle(product),
      disabled: product.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK,
      value: product.id,
    })) || []
    return options.sort((a, b) => a.title > b.title ? 1 : -1)
  }

  const getCookingInstructions = (meal: FrozenMealListFragment): TextListItem[] => {
    return meal?.frozenInstructions?.map((instruction: FrozenInstructionFragment) => ({
      title: instruction.title,
      subTitle: instruction?.description || null,
      image: instruction?.image || null,
    }))
  }

  const getServingSuggestions = (dish: FrozenMealDishDetailsFragment): TextListItem[] => {
    return dish?.servingSuggestions?.map((servingSuggestion: ServingSuggestionFragment) => ({
      title: servingSuggestion.title,
      subTitle: servingSuggestion?.description || null,
      image: servingSuggestion?.image || null,
    }))
  }

  const servingSizeChangeHandler = (servingSize: string) => {
    setState({ ...state, servingSize })
  }

  const servingSizeOptions = getServingSizeOptions()

  const dishDetailsData: CraftPizzaDishDetailsProps = {
    dish,
    allergens: finalAllergens,
    servingSizeOptions,
  }

  const cookingInstructions: TextListProps = {
    items: getCookingInstructions(servingMeal),
  }

  const servingSuggestions: TextListProps = {
    items: getServingSuggestions(dish),
  }

  const moreAboutMealData: MoreAboutMealProps = {
    chef: {
      name: dish?.chef?.name,
      about: dish?.story,
      image: dish?.chef.profileImage,
    },
    ingredients: {
      ingredients: dish?.ingredients,
      nutritionalInfo: {
        nutrition: dish?.nutrition,
      },
    },
    isPizza: true,
    cookingInstructions,
    servingSuggestions,
    servingSizeOptions,
    servingSize,
    servingSizeChange: servingSizeChangeHandler,
  }

  const _handleBackClicked = (): void => {
    navigate(-1)
  }

  useEffect(() => {
    if (!loading) {
      setState({ ...state, servingSize: servingSizeOptions[0].value.toString() })
    }
  }, [dish?.products])

  return (
    <>
      <SectionContainer $padded={false}>
        <BackButtonContainer>
          <Button
            title='BACK'
            variant='secondary'
            displayBorder={false}
            color='grey'
            leftIcon={IconEnum.CHEVRON_BACK_OUTLINE}
            iconColor={theme.colors.oranges.coral}
            loading={loading}
            onClick={_handleBackClicked} />
        </BackButtonContainer>
        <CraftPizzaDishDetails {...dishDetailsData} />
      </SectionContainer>
      <SectionContainer $backgroundColor='grey' $padded>
        <MoreAboutMeal {...moreAboutMealData} />
      </SectionContainer>
      <SectionContainer $padded>
        <CraftMealYouMightAlsoLike dish={dish} isPizza={true} />
        <GhostButtonContainer>
          <Button variant='secondary' title='BACK TO THE MENU' color='grey' onClick={_handleBackClicked} />
        </GhostButtonContainer>
      </SectionContainer>
    </>
  )
}
