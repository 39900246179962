import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Heading, IconEnum, ResponsiveImage, Paragraph, Spacer, Button } from '@client/components/atoms'
import { ResponsivePXValue, theme } from '@client/components/Theme'
import { MenuDishWineForWineFragment } from '@hooks/api'

import { StickyHealthyDining, StickyHealthyDiningEnum } from '../content'

const Container = styled.div<{ $active: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  top: ${(props): string => props.$active ? '0' : '100%'};
  transition: 0.4s ease-in-out;
  background-color: ${(props): string => props.theme.colors.whites.pureWhite};
  display: flex;
  flex-direction: column;
  align-self: stretch;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '24px' })}
`

const ImageContainer = styled.a`
  position: relative;
  width: auto;
  height: 40%;
  cursor: pointer;
  background-color: ${theme.colors.whites.alabaster};
`

const IconContainer = styled.div<{ $active: boolean }>`
  display: ${(props): string => props.$active ? 'flex' : 'none'};
  position: absolute;
  top: 8px;
  right: 32px;
  z-index: 1;
`

export interface MealPairingCardProps {
  active?: boolean
  pairing: MenuDishWineForWineFragment
  onExit?: () => void
}

export function MealPairingCard({ active = false, pairing, onExit }: MealPairingCardProps): JSX.Element {

  const navigate = useNavigate()

  const _handleClick = (e?: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    e.stopPropagation()
    navigate(`/meal-kit/${pairing?.dish?.slug}`)
  }

  const _handleExit = () => {
    onExit?.()
  }

  return (
    <Container $active={active}>
      <IconContainer $active={active}>
        <Button
          color='black'
          icon={IconEnum.CLOSE_OUTLINE}
          iconColor={theme.colors.whites.pureWhite}
          size='small'
          onClick={_handleExit} />
      </IconContainer>
      <ImageContainer onClick={_handleClick.bind(this)} href={`/meal-kit/${pairing?.dish?.slug}}`}>
        <If condition={!!pairing?.dish?.discoveryHealthyDiningItem}>
          <StickyHealthyDining type={StickyHealthyDiningEnum.SMALL} />
        </If>
        <ResponsiveImage image={pairing?.dish?.coverImage} lazy={false} />
      </ImageContainer>
      <ContentContainer>
        <Heading variant='h5' className='title-header'>{pairing?.dish?.name ?? 'Name'}</Heading>
        <Spacer variant='horizontal' universal='8px' />
        <Paragraph variant='p2' className='description-text'>{pairing?.dish?.description ?? 'Description'}</Paragraph>
      </ContentContainer>
    </Container>
  )
}
