import React from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import styled from 'styled-components'

import { CheckoutPlugin } from '@api/local'
import { Button, DeviceContainer, Spacer } from '@client/components'
import { ContentBlock } from '@client/components/molecules/stores/ContentBlock'
import { ResponsivePXValue } from '@client/components/Theme'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useAnalytics } from '@client/hooks/UseAnalytics'
import { SiteHelper } from '@client/lib/SiteHelper'
import { RegisteredUserDetailsFragment, useActivateSubscription, UserDetailsFragment, useUserCartQuery, useUserDetailsQuery } from '@hooks/api'
import { CheckoutTypeEnum } from '@uctypes/api/globalTypes'

import { UserCardList } from '../../user'
import { CheckoutContainer } from '../CheckoutContainer'

const ContentContainer = styled.div<{$hasNoCards: boolean}>`
  
  ${ResponsivePXValue('width', { mobile: 'CALC(100% - 32px)', tablet: 'CALC(100% - 32px)', desktop: 'CALC(100% - 128px)' })}
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px 16px', desktop: '32px 64px' })}
  ${(props) => (props.$hasNoCards) ? ResponsivePXValue('margin-bottom', '16px') : ''};
  .seperator {
    ${ResponsivePXValue('margin', { mobile: '20px 0', tablet: '25px 0', desktop: '30px 0' })}
  }
`

const MobileButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100vw;

  .button {
    border-radius: 0;
  }
`
const InfoBlock = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    ${ResponsivePXValue('width', '300px')}
    ${ResponsivePXValue('margin-top', '0')}
  }

`
export interface CheckoutPaymentProps {
  checkoutType: CheckoutTypeEnum
}

export function CheckoutPayment({ checkoutType }: CheckoutPaymentProps): JSX.Element {

  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [activateSubscription] = useActivateSubscription()
  const { logPaymentInfoStep } = useAnalytics()

  const activateUserSubscription = async (id: string): Promise<void> => {
    await activateSubscription({
      variables: {
        id,
        reset: !userCartData?.currentUser?.checkoutStatus?.hasConfiguredBox,
      },
      refetchQueries: SiteHelper.getCheckoutRefetchQueries(),
      awaitRefetchQueries: true,
    })

  }

  const _handleContinue = async (defaultCardId?: string): Promise<void> => {
    const discounts = userCartData?.currentUser?.activeCart?.reductions
    let discountTitles = ''
    let discountValue = 0

    for (let i = 0; i < discounts.length; i++) {
      discountTitles = discountTitles + discounts[i].title
      discountValue = discountValue + discounts[i].value
    }

    if (checkoutType === CheckoutTypeEnum.SUBSCRIPTION) {
      await activateUserSubscription(userCartData?.currentUser?.activeMenu?.subscription?.id)

      // Analytics
      logPaymentInfoStep('PaymentMethod', 'Subscription Payment', userCartData?.currentUser?.activeCart?.cartItems, userCartData?.currentUser?.activeCart?.grandTotal, discountTitles, discountValue, userDetailsData?.currentUser?.id)

      await CheckoutPlugin.shared().checkout(client)
    } else {
      let defaultCreditCardId

      if (defaultCardId && typeof defaultCardId === 'string') {
        defaultCreditCardId = defaultCardId
      } else {
        defaultCreditCardId = (userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment)?.creditCards?.find((creditCard) => creditCard.isDefault)?.id
      }

      // Analytics
      logPaymentInfoStep('PaymentMethod', 'Normal Payment', userCartData?.currentUser?.activeCart?.cartItems, userCartData?.currentUser?.activeCart?.grandTotal, discountTitles, discountValue, userDetailsData?.currentUser?.id)

      await CheckoutPlugin.shared().setOnDemandCreditCard(client, defaultCreditCardId)
    }
  }

  const buttonText = 'CONTINUE'
  const cardCount = (userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment)?.creditCards?.filter((cc) => cc.isDefault && cc.isValidated)?.length

  return (
    <CheckoutContainer>
      <ContentBlock
        title='Payment Details'>
        <ContentContainer $hasNoCards={(cardCount === 0)}>
          <UserCardList didAddCard={_handleContinue} />
          <If condition={(cardCount !== 0)}>
            <DeviceContainer $tablet $desktop>
              <InfoBlock>
                <Spacer universal='16px' />
                <Button
                  className='button'
                  title={buttonText}
                  onClick={_handleContinue} />
              </InfoBlock>
            </DeviceContainer>
            <DeviceContainer $mobile>
              <MobileButtonContainer>
                <Spacer universal='24px' />
                <Button
                  fluid
                  className='button'
                  title={buttonText}
                  onClick={_handleContinue} />
              </MobileButtonContainer>
            </DeviceContainer>
          </If>
        </ContentContainer>
      </ContentBlock>
    </CheckoutContainer>
  )
}
