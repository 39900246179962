import React, { useEffect, useState } from 'react'

import Cookies from 'js-cookie'
import qs from 'qs'
import update from 'react-addons-update'
import { useLocation, useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Icon, IconSizeEnum, IconEnum, Spacer } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useAnalytics } from '@client/hooks/UseAnalytics'
import { useLoadingData } from '@client/hooks/UseLoadingData'
import { useUserDetailsQuery, useGetAppQuery, useGetAllFrozenMealDishesQuery, ProductAggregationSectionFragment, FrozenMealDishListFragment } from '@hooks/api'
import { CraftKidsCard, EducationalBanner, Filter, FilterSectionProps, MiniProductGrid, NavLinks, SectionLoading, SelectedFilters, StoreStickyButton } from '@molecules/index'
import { FrozenMealDishFilters, DeviceTypeEnum, FrozenMealDishOrderEnum, OrderDirectionEnum, FrozenPortionSizeEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { ResponsivePXValue, ResponsiveProperty, LoadEffect } from '../Theme'
import { DeviceContainer } from '../utility'

import { getFrozenNavLinks } from './FrozenAisles'

const seo = {
  name: 'UCOOK Craft Meal',
  title: 'Buy Craft Meal Online in South Africa | UCOOK Craft Meal',
  meta: [{
    name: 'description',
    content: 'Our markets are sourced from SA\'s best farms, and specially paired with each Meal Kit recipe so that you can enjoy a special dining experience at home.',
  }, {
    name: 'keywords',
    content: 'Buy Market Online,Market Online,Order Market Online',
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${ResponsivePXValue('padding-top', { mobile: '16px', desktop: '32px' })}
  ${ResponsivePXValue('width', { mobile: '100%', desktop: '1136px' }, { desktop: true })}

  .button-container {
    margin: 0;
  }
`
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;

 ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0 16px' })}
`
const HeadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'flex-start', desktop: 'flex-start' })}
  ${ResponsivePXValue('height', { mobile: '44px', desktop: '64px' })}
`
const Grid = styled.div <{ $isNativeApplication: boolean, $isLoading: boolean }>`
  display: flex;
  gap: 16px;

  ${(props): CSS => props.$isLoading ? LoadEffect : undefined};

  ${ResponsiveProperty('flex-direction', { mobile: 'column', desktop: 'row' })}

  .craft-meal-filter {
    ${ResponsivePXValue('padding', { mobile: '8px 0', tablet: '0 16px' })}
    ${ResponsivePXValue('width', { desktop: '272px' })}

    .filter-pill-container {
       ${ResponsivePXValue('left', { mobile: '36px' })}
    }
  }
`

const DishContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px;
  height: fit-content;
  ${ResponsivePXValue('justify-content', { mobile: 'center' })}

  .craft-meal-card {
    margin: 0;
  }
`

const IconContainer = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
interface CraftKidsStoreState {
  filters: FrozenMealDishFilters
  displayEducationalBanner: boolean
  hasLoggedImpression: boolean
}

const DEFAULT_STATE: CraftKidsStoreState = {
  filters: {},
  displayEducationalBanner: false,
  hasLoggedImpression: false,
}

export function CraftKidsStore(): JSX.Element {

  const [state, setState] = useState<CraftKidsStoreState>({ ...DEFAULT_STATE })
  const config = useConfig()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication
  const navigate = useNavigate()
  const location = useLocation()
  const { logProductListImpression } = useAnalytics()

  const params = qs.parse(location.search.replace('?', ''))
  const filters = params?.filters as { [k: string]: string[] } || {}
  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const hasFrozenBoughtBefore = appData.app.hasFrozenBoughtBefore

  const variables = {
    filters: {
      ...filters,
      frozenCategories: ['cf843967-672c-4bd2-9d46-f593759d5ad3'],
    },
    notifyOnNetworkStatusChange: true,
    order: [{
      field: FrozenMealDishOrderEnum.DISPLAY_INDEX,
      direction: OrderDirectionEnum.ASC,
    }],
  }
  const { data: productsData, loading: productsLoading } = useGetAllFrozenMealDishesQuery({ variables, skip: !userDetailsData?.currentUser })

  const loading = productsLoading || userDetailsLoading
  const products = useLoadingData<Readonly<(FrozenMealDishListFragment)[]>>({
    data: productsData?.dishes?.list,
    defaultData: [],
    loading: productsLoading,
  })

  const aggregations = useLoadingData<Readonly<ProductAggregationSectionFragment[]>>({
    data: productsData?.dishes?.aggregation?.sections,
    defaultData: [],
    loading: productsLoading,
  })

  const _handleFiltersChange = (selected: SelectedFilters): void => {
    setState((prevState) => update(prevState, {
      filters: { $set: selected },
      skip: { $set: 0 },
    }))
    const newQueryString = qs.stringify({ filters: selected })
    navigate(`${location.pathname}?${newQueryString}`)
  }

  const _handleFiltersClear = (): void => {
    setState((prevState) => update(prevState, {
      filters: { $set: {} },
      skip: { $set: 0 },
    }))
    navigate(location.pathname)
  }

  const _handleBannerToggle = (): void => {
    setState((prevState) => update(prevState, {
      displayEducationalBanner: { $set: !prevState.displayEducationalBanner },
    }))
  }

  const _handleOnClose = (): void => {
    setState((prevState) => update(prevState, {
      displayEducationalBanner: { $set: false },
    }))
  }
  const renderMiniProductCraftGrids = () => {

    const batchSize = 4
    const miniProductCraftGrids = []
    const craftMealsGridList = products as (FrozenMealDishListFragment)[]
    for (let i = 0; i < craftMealsGridList.length; i += batchSize) {
      const batch = craftMealsGridList.slice(i, i + batchSize)
      miniProductCraftGrids.push(
        <MiniProductGrid key={`batch${i / batchSize + 1}`} meals={batch} />,
      )
    }

    return miniProductCraftGrids
  }

  useEffect(() => {
    if (!Cookies.get('displayEducationalBanner')) {
      setState((prevState) => update(prevState, {
        displayEducationalBanner: { $set: true },
      }))
      Cookies.set('displayEducationalBanner', '0')
    }
  }, [])

  // Analytics
  useEffect(() => {
    if (!productsLoading && !!products.length && !state.hasLoggedImpression) {
      logProductListImpression(
        products.map((meal, displayIndex) => {
          return {
            item_name: meal?.name,
            item_id: meal?.id,
            price: meal?.products?.find((dish) => dish.frozenPortionSize === FrozenPortionSizeEnum.SERVES_FOUR)?.price,
            item_brand: 'UCOOK',
            item_category: meal?.frozenCategories?.map((cat) => cat.id)?.join(', '),
            item_variant: meal?.frozenCategories?.map((cat) => cat.title).join(', '),
            item_list_name: 'Craft Pizza',
            index: displayIndex,
          }
        }),
        userDetailsData?.currentUser?.id)

      setState((prevState) => update(prevState, {
        hasLoggedImpression: { $set: true },
      }))
    }
  }, [products])

  const sections: FilterSectionProps[] = aggregations.filter(aggregation => aggregation.filterKey !== 'tags' && aggregation.filterKey !== 'frozenCategories').map((aggregation) => ({
    id: aggregation.filterKey,
    title: aggregation.title,
    filterKey: aggregation.filterKey,
    items: aggregation.items.map((item) => ({
      id: item.id,
      title: item.name,
      quantity: 0,
    })),
  }))

  let navLinks = getFrozenNavLinks({ craftKidsActive: true })

  if (hasFrozenBoughtBefore) {
    const boughtBeforeLink = [{
      title: 'Bought Before',
      url: '/frozen/bought-before',
      isActive: false,
    }]
    navLinks = getFrozenNavLinks({
      craftKidsActive: true,
      additionalLinks: boughtBeforeLink,
    })
  }

  let craftMeal: FrozenMealDishListFragment

  return (
    <Container>
      <SearchEngineOptimization seo={seo} />
      <Choose>
        <When condition={loading && !products.length}>
          <SectionLoading height='100vh' />
        </When>
        <Otherwise>
          <StoreStickyButton className='button-container' isMobile={isMobile} />
          <TopContainer>
            <HeadingContainer>
              <Heading variant='h1'>Craft Kids</Heading>
              <Spacer desktop='8px' variant='horizontal' />
              <IconContainer onClick={_handleBannerToggle}>
                <Icon icon={IconEnum.INFORMATION_CIRCLE} size={IconSizeEnum.SMALL} />
              </IconContainer>
            </HeadingContainer>
            <DeviceContainer $desktop>
              <NavLinks navLinks={navLinks} outline />
            </DeviceContainer>
            <If condition={state.displayEducationalBanner}>
              <Spacer universal='16px' />
              <EducationalBanner
                title='Welcome to Craft Kids!'
                description={'Say goodbye to turned-up noses and battles over broccoli, and hello to yummy, scrummy, easy, nutritious dishes! UCOOK Kids Meals are here to put all the goodness back into mealtimes with a selection of flavourful dishes suited to little ones\' palates and with up to 74% hidden veg.'}
                onClose={_handleOnClose} />
              <Spacer mobile='16px' />
            </If>
            <Spacer desktop='24px' />
          </TopContainer>
          <Grid $isNativeApplication={isNativeApplication} $isLoading={loading}>
            <Filter
              className='craft-meal-filter'
              filters={filters as unknown as { [k: string]: string[] }}
              sections={sections}
              onChange={_handleFiltersChange}
              onClear={_handleFiltersClear}
              slides={<NavLinks navLinks={navLinks} outline />}
            />
            <Spacer mobile='4px' />
            <DishContainer id='productGrid'>
              <Choose>
                <When condition={isDesktop}>
                  <For each='craftMeal' of={products}>
                    <CraftKidsCard
                      className='craft-meal-card'
                      key={craftMeal.id}
                      loading={loading}
                      craftKidsMeal={craftMeal} />
                  </For>
                </When>
                <Otherwise>
                  {renderMiniProductCraftGrids()}
                </Otherwise>
              </Choose>

            </DishContainer>
          </Grid>
          <Spacer universal='24px' />
        </Otherwise>
      </Choose>
    </Container>
  )
}
